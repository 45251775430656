import React, { useEffect, useRef, useState } from "react";
import logo from "src/assests/images/appLogo.png";
import CustomButton from "src/components/CustomButton";
import {
  AddCircleOutline,
  DeleteOutline,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { debounce } from "@mui/material";
import Select from "react-select";
import { QRCodeGenerator } from "./CouponQR";
import {
  Box,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import backButton from "../../Images/backButton.svg";
import html2canvas from "html2canvas";
import { couponPageProps } from "./couponDetails";
import { ADD_COUPON, UPDATE_COUPON } from "src/graphql/mutations";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { uploadImage } from "src/components/Common/Utils";
import { useNavigate } from "react-router";
import RichTextEditor from "src/components/Common/TextEditor";
import { GET_BUSINESS, GET_COUPON_BY_ID, GET_EVENTS } from "src/graphql/query";

function removeQueryParams(url: any, params: any) {
  params.forEach((param: any) => {
    let regex = new RegExp(`[?&]${param}=[^&]*`, "g");
    url = url.replace(regex, "");
  });
  url = url.replace(/[?&]$/, "");
  return url;
}

interface BusinessOptions {
  value: string;
  label: string;
}
interface EventOptions {
  value: string;
  label: string;
}

const CouponModal: React.FC<couponPageProps> = ({
  refetchCoupon,
  handleClose,
  initialCouponObj = {
    name: "",
    qrCode: "",
    url: "",
    slug: "",
    quantity: "",
    description: "",
    termsAndConditions: "",
    bussinessId: "",
    eventId: "",
    code: "",
    status: "",
    expireDate: "",
    passCode: "",
    imageUrl: "",
    type: "",
    howToRedeem: "",
    title:"",
  },
  couponObj = {
    name: "",
    qrCode: "",
    url: "",
    slug: "",
    quantity: "",
    description: "",
    termsAndConditions: "",
    bussinessId: "",
    eventId: "",
    code: "",
    status: "",
    expireDate: "",
    passCode: "",
    imageUrl: "",
    type: "",
    howToRedeem: "",
    title:"",
    _id: "",
  },
  setCouponObj = () => { },
  isEditCoupon = false,
}) => {
  const [couponValue, setCouponValue] = useState("");
  const [qrUrl, setQrUrl] = useState<string | null | undefined>("");
  const [urlError, setUrlError] = useState<string | null>(null);
  const [addCoupon] = useMutation(ADD_COUPON);
  const [updateCoupon] = useMutation(UPDATE_COUPON);
  const [quantity, setQuantity] = useState(
    isEditCoupon ? "0" : couponObj?.quantity // Set to 0 in edit mode
  );
  const qrRef = useRef(null);
  const imageRef = useRef<HTMLInputElement | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [businessOptions, setBusinessOptions] = useState<BusinessOptions[]>([]);
  const [eventOptions, setEventOptions] = useState<EventOptions[]>([]);
  const [desktopUrl, setDesktopUrl] = useState<string>("");
  const { data: couponIdData } =useQuery(GET_COUPON_BY_ID, {variables: {_id: couponObj?._id,},});

  const [getBusinesses, { data: businessData, loading: businessloading }] =
    useLazyQuery(GET_BUSINESS, {
      variables: { search: searchTerm },
      fetchPolicy: "network-only",
    });
  const [getEvents, { data: eventData, loading: eventloading }] = useLazyQuery(
    GET_EVENTS,
    {
      variables: { search: searchTerm },
      fetchPolicy: "network-only",
    }
  );

  const handleSearchTermChange = debounce((term: string) => {
    if (term.length >= 3) {
      setSearchTerm(term);
      getBusinesses({ variables: { search: term } });
    } else {
      setSearchTerm("");
      setBusinessOptions([]);
    }
  }, 300);

  const handleEventSearchTermChange = debounce((term: string) => {
    if (term.length >= 3) {
      setSearchTerm(term);
      getEvents({ variables: { search: term } });
    } else {
      setSearchTerm("");
      setEventOptions([]);
    }
  }, 300);
  
  useEffect(() => {
    if (
      businessData &&
      businessData?.getBusinessProfilesAll &&
      businessData?.getBusinessProfilesAll?.data
    ) {
      setBusinessOptions(
        businessData?.getBusinessProfilesAll?.data?.map((business: any) => ({
          value: business._id,
          label: `${business?.businessName} (${business?.pinCodeDetails})`,
        }))
      );
    }
  }, [businessData]);

  useEffect(() => {
    if (eventData && eventData?.getEventsAll && eventData?.getEventsAll?.data) {
      setEventOptions(
        eventData?.getEventsAll?.data?.map((event: any) => ({
          value: event._id,
          label: `${event?.title}`,
        }))
      );
    }
  }, [eventData]);
   
  useEffect(() => {
    if (isEditCoupon && couponObj?.bussinessId) {
      if(couponIdData?.getCoupon?.data){
        const businessOption = {
          value: couponIdData?.getCoupon?.data?.bussinessId,
          label: `${couponIdData?.getCoupon?.data?.businessName} (${couponIdData?.getCoupon?.data?.pinCodeDetails})`,
        };
        setBusinessOptions([businessOption] as any);

        const eventOption = {
          value: couponIdData?.getCoupon?.data?.eventId,
          label: couponIdData?.getCoupon?.data?.eventName,
        };
        setEventOptions([eventOption] as any);
      }
    }
  }, [isEditCoupon,couponIdData]);

  const handleBusinessChange = (selectedOption: any | null) => {
    if (!selectedOption) {
      setCouponObj((prev: any) => ({
        ...prev,
        bussinessId: "",
      }));
      setSearchTerm(""); // Clear the search term
      setBusinessOptions([]); // Reset the business options
      return;
    }

    setCouponObj((prev: any) => ({
      ...prev,
      bussinessId: selectedOption?.value,
    }));

    setSearchTerm(selectedOption?.label);
  };
  const handleEventChange = (selectedOption: any | null) => {
    if (!selectedOption) {
      setCouponObj((prev: any) => ({
        ...prev,
        eventId: "",
      }));
      setSearchTerm(""); // Clear the search term
      setEventOptions([]); // Reset the business options
      return;
    }

    setCouponObj((prev: any) => ({
      ...prev,
      eventId: selectedOption?.value,
    }));

    setSearchTerm(selectedOption?.label);
  };
  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files?.[0];
      const fileUrl = URL.createObjectURL(file);

      setCouponObj((prev: any) => ({
        ...prev,
        [type]: file,
      }));
    }
  };

  const saveQRCode = async (e: any) => {
    e.preventDefault();
    const missingFields: { field: string; message: string }[] = [];

    if (!couponObj?.name)
      missingFields.push({ field: "name", message: "Coupon Name is required" });
    // if (!couponObj?.quantity)
    //   missingFields.push({
    //     field: "quantity",
    //     message: "Quantity is required",
    //   });
    if (!couponObj?.code)
      missingFields.push({ field: "code", message: "Coupon Code is required" });
    if (!couponObj?.passCode)
      missingFields.push({
        field: "passCode",
        message: "Passcode is required",
      });
    if (!couponObj?.slug)
      missingFields.push({ field: "slug", message: "Slug is required" });
    if (!couponObj?.status)
      missingFields.push({ field: "status", message: "Status is required" });
    // if (!couponObj?.expireDate)
    //   missingFields.push({
    //     field: "expireDate",
    //     message: "Valid No. of Days is required",
    //   });

    if (!couponObj?.imageUrl)
      missingFields.push({
        field: "imageUrl",
        message: "Brand Logo Image is required",
      });
    if (!couponObj?.type)
      missingFields.push({ field: "type", message: "Offer Type is required" });

    if (missingFields.length > 0) {
      missingFields.forEach((item, index) => {
        setTimeout(() => {
          toast.error(item.message);
        }); // Staggering the toasts by 300ms
      });
      return;
    }

    if (!qrRef.current) {
      toast.error("QR Code reference is not available");
      return;
    }

    try {
      // Capture the QR code element as a canvas
      const canvas = await html2canvas(qrRef.current);

      // Convert the canvas to a Blob
      const blob = await new Promise<Blob | null>((resolve) => {
        canvas.toBlob((blob) => resolve(blob), "image/png");
      });

      if (!blob) {
        toast.error("Failed to generate QR Code image");
        return;
      }

      // Create a File object from the Blob
      const fileName = (couponObj.name || "QRCode").replace(/\s+/g, "");
      const file = new File([blob], `${fileName}.png`, {
        type: "image/png",
      });

      // Upload the image using your existing uploadImage function
      const res = await uploadImage(file, "CouponQRCode", couponObj?.name);
      const qrImageUrl = res.uploadImage.url;

      if (!qrImageUrl) {
        toast.error("Failed to upload QR Code");
        return;
      }

      if (
        couponObj?.imageUrl &&
        typeof couponObj.imageUrl !== "string" &&
        couponObj.imageUrl instanceof File
      ) {
        const response = await uploadImage(
          couponObj.imageUrl,
          "Coupon",
          couponObj.name
        );

        if (response?.uploadImage?.url) {
          couponObj.imageUrl = response.uploadImage.url;
        }
      }

      // Update the coupon object with the new QR code URL
      const updatedCouponObj = {
        ...couponObj,
        quantity:quantity,
        qrCode: qrImageUrl,
        expireDate: couponObj.expireDate || "", // Ensure expireDate is a string, not null
      };
      // Determine whether to create a new coupon or update an existing one
      const { data } = isEditCoupon
        ? await updateCoupon({
          variables: {
            _id: updatedCouponObj?._id,
            input: {
              name: updatedCouponObj?.name,
              title: updatedCouponObj?.title,
              qrCode: updatedCouponObj?.qrCode,
              url: updatedCouponObj?.url,
              slug: updatedCouponObj?.slug,
              bussinessId: updatedCouponObj?.bussinessId,
              eventId: updatedCouponObj?.eventId,
              quantity: Number(updatedCouponObj?.quantity),
              status: updatedCouponObj?.status,
              code: updatedCouponObj?.code,
              description: updatedCouponObj?.description,
              termsAndConditions: updatedCouponObj?.termsAndConditions,
              expireDate: updatedCouponObj?.expireDate || "",
              passCode: updatedCouponObj?.passCode,
              imageUrl: updatedCouponObj?.imageUrl,
              type: updatedCouponObj?.type,
              howToRedeem: updatedCouponObj?.howToRedeem,
            },
          },
        })
        : await addCoupon({
          variables: {
            input: {
              name: updatedCouponObj?.name,
              title: updatedCouponObj?.title,
              qrCode: updatedCouponObj?.qrCode,
              url: updatedCouponObj?.url,
              slug: updatedCouponObj?.slug,
              bussinessId: updatedCouponObj?.bussinessId,
              eventId: updatedCouponObj?.eventId,
              quantity: Number(updatedCouponObj?.quantity),
              status: updatedCouponObj?.status,
              code: updatedCouponObj?.code,
              description: updatedCouponObj?.description,
              termsAndConditions: updatedCouponObj?.termsAndConditions,
              expireDate: updatedCouponObj?.expireDate || "",
              passCode: updatedCouponObj?.passCode,
              imageUrl: updatedCouponObj?.imageUrl,
              type: updatedCouponObj?.type,
              howToRedeem: updatedCouponObj?.howToRedeem,
            },
          },
        });

      // Refetch coupons to update the list
      refetchCoupon();

      // Provide feedback to the user
      if (
        data?.addCoupon?.statusCode === 200 ||
        data?.updateCoupon?.statusCode === 200
      ) {
        toast.success(
          data?.addCoupon?.message ||
          data?.updateCoupon?.message ||
          "QR Code saved successfully"
        );
        setCouponObj(initialCouponObj);
        handleClose();
      } else {
        toast.error(data?.addCoupon?.message || "Error saving QR Code");
      }
    } catch (error: any) {
      toast.error(error?.message || "An unexpected error occurred.");
    }
  };

  useEffect(() => {
    if (isEditCoupon && couponObj) {
      setCouponObj(couponObj);
      setCouponValue(couponObj?.name);
      setQrUrl(couponObj?.url);
      setQuantity("0");
    }
  }, [isEditCoupon, couponObj]);

  const urlRegex = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/;

  useEffect(() => {
    const url = couponObj?.url ?? "";

    if (url.trim() !== "") {
      if (urlRegex.test(url)) {
        setQrUrl(url);
        setUrlError(null);
      } else {
        setQrUrl("");
        setUrlError(
          "Please enter a valid URL (starting with http:// or https://)"
        );
      }
    } else {
      setQrUrl("");
      setUrlError(null);
    }
  }, [couponObj?.url]);

  const handleSlugChange = (e: any) => {
    if (!!couponObj?.url) {
      let updateQuery = couponObj?.url;
      let newUrl = removeQueryParams(updateQuery, ["url_slug"]);
      setCouponObj({
        ...couponObj,
        slug: e.target.value.replaceAll(" ", "-"),
        url: newUrl + "?url_slug=" + e.target.value.replaceAll(" ", "-"),
      });
    }
  };

  const handleDownloadQRCode = async () => {
    if (qrRef.current) {
      const canvas = await html2canvas(qrRef.current);
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `${couponObj.name || "QRCode"}.png`;
      link.click();
    }
  };

  return (
    <Box p={2}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Back">
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Tooltip>
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {isEditCoupon ? "Update Coupon QR Code" : "Create Coupon QR Code"}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        paddingRight={4}
      >
        <Grid item xs={6} paddingRight={4} width="60%" paddingTop="30px">
          <Box width={"100%"}>
            <TextField
              name="Coupon Name"
              margin="dense"
              label="Coupon Name"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={couponObj?.name || ""}
              onChange={(e) =>
                setCouponObj({ ...couponObj, name: e.target.value })
              }
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Title"
              margin="dense"
              label="Title"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={couponObj?.title || ""}
              onChange={(e) =>
                setCouponObj({ ...couponObj, title: e.target.value })
              }
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <Select
              isClearable
              placeholder={businessloading ? "Loading..." : "Select a Business"}
              value={
                businessOptions.find(
                  (option: any) => option?.value === couponObj?.bussinessId
                ) || null
              }
              onChange={handleBusinessChange}
              onInputChange={(value: any, actionMeta: any) => {
                if (actionMeta.action === "input-change") {
                  if (value === "") {
                    setSearchTerm(""); // Clear search and prevent API call
                    setBusinessOptions([]); // Clear dropdown
                  } else {
                    handleSearchTermChange(value);
                  }
                }
              }}
              options={businessOptions}
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <Select
              isClearable
              placeholder={eventloading ? "Loading..." : "Select a Event "}
              value={
                eventOptions.find(
                  (option: any) => option?.value === couponObj?.eventId
                ) || null
              }
              onChange={handleEventChange}
              onInputChange={(value: any, actionMeta: any) => {
                if (actionMeta.action === "input-change") {
                  if (value === "") {
                    setSearchTerm(""); // Clear search and prevent API call
                    setEventOptions([]); // Clear dropdown
                  } else {
                    handleEventSearchTermChange(value);
                  }
                }
              }}
              options={eventOptions}
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Quantity"
              margin="dense"
              label="Enter Your Quantity"
              type="number"
              InputLabelProps={{ shrink: true }}
              fullWidth
              // value={couponObj?.quantity || ""}
              // onChange={(e) =>
              //   setCouponObj({ ...couponObj, quantity: Number(e.target.value) })
              // }
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="CouponCode"
              margin="dense"
              label="Enter Coupon Code"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={couponObj?.code || ""}
              onChange={(e) =>
                setCouponObj({
                  ...couponObj,
                  code: e.target.value.toUpperCase(),
                })
              }
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <Select
              value={
                couponObj?.status
                  ? { value: couponObj?.status, label: couponObj?.status }
                  : null
              }
              placeholder="Select Status"
              onChange={(selectedOption) => {
                setCouponObj((prev: any) => ({
                  ...prev,
                  status: selectedOption?.value || "",
                }));
              }}
              options={[
                { value: "ACTIVE", label: "Active" },
                { value: "EXPIRED", label: "Expired" },
                { value: "DISABLED", label: "Disabled" },
              ]}
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Type"
              margin="dense"
              label="Enter Offer Type"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={couponObj?.type || ""}
              onChange={(e) =>
                setCouponObj({
                  ...couponObj,
                  type: e.target.value.toUpperCase(),
                })
              }
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Custom URL"
              margin="dense"
              label="Enter Your Own URL"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={couponObj?.url || ""}
              onChange={(e) =>
                setCouponObj({ ...couponObj, url: e.target.value })
              }
              error={!!urlError}
              helperText={urlError}
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Slug"
              margin="dense"
              label="Enter Your Slug"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={couponObj?.slug || ""}
              onChange={handleSlugChange}
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="expireDate"
              margin="dense"
              label="Enter Expiry Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={couponObj?.expireDate || ""}
              onChange={(e) =>
                setCouponObj({
                  ...couponObj,
                  expireDate: e.target.value, // Storing date as a string (YYYY-MM-DD format)
                })
              }
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="PassCode"
              margin="dense"
              label="Enter passCode"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={couponObj?.passCode || ""}
              onChange={(e) =>
                setCouponObj({ ...couponObj, passCode: e.target.value })
              }
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <Typography variant="body1">
              <strong style={{ color: "#333" }}>Description </strong>
            </Typography>
            <RichTextEditor
              value={couponObj?.description || ""}
              onChange={(value) =>
                setCouponObj({ ...couponObj, description: value })
              }
              placeholder="Enter coupon description here..."
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <Typography variant="body1">
              <strong style={{ color: "#333" }}>Terms & Conditions </strong>
            </Typography>
            <RichTextEditor
              value={couponObj?.termsAndConditions || ""}
              onChange={(value) =>
                setCouponObj({ ...couponObj, termsAndConditions: value })
              }
              placeholder="Enter coupon terms and conditions here..."
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <Typography variant="body1">
              <strong style={{ color: "#333" }}>How to Redeem </strong>
            </Typography>
            <RichTextEditor
              value={couponObj?.howToRedeem || ""}
              onChange={(value) =>
                setCouponObj({ ...couponObj, howToRedeem: value })
              }
              placeholder="Enter coupon terms and conditions here..."
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <Typography variant="h6" gutterBottom>
              Brand Logo Image
            </Typography>
            {couponObj?.imageUrl ? (
              <Box
                position="relative"
                width="100%"
                height="auto"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <img
                    src={
                      typeof couponObj?.imageUrl === "string"
                        ? couponObj?.imageUrl
                        : URL.createObjectURL(couponObj?.imageUrl)
                    }
                    alt="Brand Logo Upload"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "500px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <CustomButton
                  variant="outlined"
                  color="warning"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    zIndex: 1,
                    borderRadius: "50%",
                    width: "32px",
                    height: "32px",
                    minWidth: "0",
                    backgroundColor: "white",
                  }}
                  onClick={() => {
                    setCouponObj((prev: any) => ({
                      ...prev,
                      imageUrl: "",
                    }));
                  }}
                  aria-label="Remove image"
                >
                  <Tooltip title="Remove">
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        borderRadius: "50%",
                      }}
                    >
                      &times;
                    </Box>
                  </Tooltip>
                </CustomButton>
              </Box>
            ) : (
              <label>
                <input
                  ref={imageRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "imageUrl")}
                />
                <CustomButton
                  variant="outlined"
                  size="small"
                  onClick={() => imageRef.current?.click()}
                >
                  Upload Brand Logo Image
                </CustomButton>
                <TextField
                  label="Or Enter Image URL"
                  value={desktopUrl ?? (couponObj?.imageUrl || "")}
                  onBlurCapture={(e) => {
                    if (desktopUrl?.startsWith("https")) {
                      setCouponObj((prev: any) => ({
                        ...prev,
                        imageUrl: desktopUrl,
                      }));
                    } else {
                      setDesktopUrl("");
                      setCouponObj((prev: any) => ({
                        ...prev,
                        imageUrl: "",
                      }));
                    }
                  }}
                  onChange={(e) => setDesktopUrl(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </label>
            )}
          </Box>
          <Box marginTop={2} width={"100%"}>
            <CustomButton onClick={saveQRCode}>Save Coupon</CustomButton>
          </Box>
        </Grid>

        {/* QR Code Preview Section */}
        <Box>
          {qrUrl && (
            <>
              <div style={{ paddingTop: "20px" }}>
                <div ref={qrRef} style={{ padding: "10px" }}>
                  <QRCodeGenerator url={qrUrl} logoSrc={logo} />
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    {couponObj?.name}
                  </div>
                </div>
              </div>
              <CustomButton onClick={handleDownloadQRCode}>
                Download QR Code
              </CustomButton>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CouponModal;
