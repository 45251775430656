import React, { useEffect, useRef, useState } from "react";
import CustomButton from "src/components/CustomButton";
import { Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextareaAutosize } from "@mui/material";
import {
  Box,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import backButton from "../../Images/backButton.svg";
import { SEND_BULK_MESSAGE } from "src/graphql/mutations";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { sendMessagesPageProps } from "./sendBulkMessages";
import { uploadImage } from "src/components/Common/Utils";

const SendBulkMessagesModal: React.FC<sendMessagesPageProps> = ({
  refetchSendMessages,
  handleClose,
  initialSendMessagesObj = {
    type: "",
    userType: "",
    businessType: "",
    messageText: "",
    subject: "",
    imageUrl: "",
    businessClaimType: "",
    personalNumber: "",
  },
  sendMessagesObj = {
    type: "",
    userType: "",
    businessType: "",
    messageText: "",
    subject: "",
    imageUrl: "",
    businessClaimType: "",
    personalNumber: "",
    _id: "",
  },
  setSendMessagesObj = () => { },
}) => {
  const [sendBulkMessages] = useMutation(SEND_BULK_MESSAGE);
  const [error, setError] = useState(false);
  const imageRef = useRef<HTMLInputElement | null>(null);
  const [desktopUrl, setDesktopUrl] = useState<string>("");

  const userTypeOptions = ["USER", "CAMPAIGNUSER", "BUSINESS"];
  const businessStatusOptions = ["PENDING", "APPROVED", "REJECTED"];

  const formatLabel = (label: any) => {
    return label
      .toLowerCase()
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
      .replace(/\b\w/g, (char: any) => char.toUpperCase()); // Capitalize each word
  };


  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files?.[0];
      const fileUrl = URL.createObjectURL(file);

      setSendMessagesObj((prev: any) => ({
        ...prev,
        [type]: file,
      }));
    }
  };

  const handleNumberChange = (e: any) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    if (value.length > 10) {
      value = value.slice(0, 10); // Trim to 10 digits if exceeded
    }
    setSendMessagesObj({ ...sendMessagesObj, personalNumber: value });
    setError(value.length !== 10); // Show error if not exactly 10 digits
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!sendMessagesObj?.userType || sendMessagesObj?.userType.length === 0) {
      toast.error("Please select at least one User Type.");
      return;
    }

    // Validation: Check if messageText (description) is provided
    if (!sendMessagesObj?.messageText || sendMessagesObj?.messageText.trim() === "") {
      toast.error("Message Description is required.");
      return;
    }

    try {
      if (
        sendMessagesObj?.imageUrl &&
        typeof sendMessagesObj?.imageUrl !== "string" &&
        sendMessagesObj?.imageUrl instanceof File
      ) {
        const response = await uploadImage(
          sendMessagesObj.imageUrl,
          "Messages",
          sendMessagesObj?.userType ?? ""
        );

        if (response?.uploadImage?.url) {
          sendMessagesObj.imageUrl = response.uploadImage.url;
        }
      }
      const updatedSendBulkMessages: any = {
        type: sendMessagesObj?.type,
        userType: sendMessagesObj?.userType,
        messageText: sendMessagesObj?.messageText,
        subject: sendMessagesObj?.subject,
        imageUrl: sendMessagesObj?.imageUrl,
        businessClaimType: sendMessagesObj?.businessClaimType,
        personalNumber: sendMessagesObj?.personalNumber,
      };

      // Only include `businessType` if it has values
      if (sendMessagesObj?.userType.includes("BUSINESS") && (sendMessagesObj?.businessType?.length ?? 0) > 0) {
        updatedSendBulkMessages.businessType = sendMessagesObj.businessType;
      }

      const { data } = await sendBulkMessages({
        variables: { input: updatedSendBulkMessages },
      });

      // Refetch campaigns to update the list
      refetchSendMessages();

      // Provide feedback to the user
      if (data?.sendBulkMessages?.statusCode === 200) {
        toast.success(data?.sendBulkMessages?.message || "Messages Send successfully");
        setSendMessagesObj(initialSendMessagesObj);
        handleClose();
      } else {
        toast.error(data?.sendBulkMessages?.message || "Error Sending Messages");
      }
    } catch (error: any) {
      toast.error(error?.message || "An unexpected error occurred.");
      console.error(error || "An unexpected error occurred.");
    }
  };

  return (
    <Box p={2}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Back">
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Tooltip>
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          Send Bulk Messages
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        paddingRight={4}
      >
        <Grid item xs={6} paddingRight={4} width="100%" paddingTop="30px" display="flex" flexDirection="column" alignItems="flex-start">

          {/* radio button for select message type */}
          <FormControl>
            <Typography variant="h6">Select Message Type</Typography>
            <RadioGroup
              row
              value={sendMessagesObj?.type || "EMAIL"}
              onChange={(e) => setSendMessagesObj({
                ...sendMessagesObj,
                type: e.target.value,
                personalNumber: e.target.value === "WHATSAPP" ? sendMessagesObj.personalNumber : ""
              })}
            >
              <FormControlLabel value="EMAIL" control={<Radio />} label="Email" />
              <FormControlLabel value="WHATSAPP" control={<Radio />} label="WhatsApp" />
            </RadioGroup>
            {sendMessagesObj?.type === "WHATSAPP" && (
              <Box marginTop={2} width="100%">
                <TextField
                  name="personalNumber"
                  margin="dense"
                  label="Enter Mobile Number For Testing"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={sendMessagesObj.personalNumber || ""}
                  onChange={handleNumberChange}
                  error={error}
                  helperText={error ? "Mobile number must be exactly 10 digits" : ""}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 10,
                  }}
                />
              </Box>
            )}
          </FormControl>

          {/* checkbox for usertype and BUSINESS status Type */}
          <Typography variant="h6">Select User Type</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendMessagesObj?.userType?.length === userTypeOptions.length} // Check if all are selected
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setSendMessagesObj({
                      ...sendMessagesObj,
                      userType: isChecked ? [...userTypeOptions] : [],
                      businessType: isChecked && sendMessagesObj.userType?.includes("BUSINESS") ? [...businessStatusOptions] : [], // Reset if unchecked
                    });
                  }
                  }
                />
              }
              label="All"
            />
            {userTypeOptions.map((userType) => (
              <FormControlLabel
                key={userType}
                control={
                  <Checkbox
                    checked={sendMessagesObj.userType?.includes(userType) || false}
                    onChange={(e) => {
                      const selectedTypes: any = sendMessagesObj.userType || [];
                      let updatedTypes;

                      if (e.target.checked) {
                        updatedTypes = [...selectedTypes, userType];
                      } else {
                        updatedTypes = selectedTypes.filter((t: any) => t !== userType);
                      }

                      setSendMessagesObj({
                        ...sendMessagesObj,
                        userType: updatedTypes,
                        businessType: updatedTypes.includes("BUSINESS") ? sendMessagesObj?.businessType : [],
                      });
                    }}
                  />
                }
                label={formatLabel(userType)}
              />
            ))}
          </FormGroup>

          {/* BUSINESS Status Checkboxes (if "BUSINESS" is selected) */}
          {sendMessagesObj.userType?.includes("BUSINESS") && (
            <Box ml={3}>
              <Typography variant="h6">Select Business Status Type</Typography>
              <FormGroup sx={{ flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendMessagesObj?.businessType?.length === businessStatusOptions.length} // Check if all statuses are selected
                      onChange={(e) =>
                        setSendMessagesObj({
                          ...sendMessagesObj,
                          businessType: e.target.checked ? [...businessStatusOptions] : [],
                          businessClaimType: e.target.checked ? sendMessagesObj.businessClaimType : null,
                        })
                      } 
                    />
                  }
                  label="All"
                />
                {businessStatusOptions.map((status) => (
                  <FormControlLabel
                    key={status}
                    control={
                      <Checkbox
                        checked={sendMessagesObj?.businessType?.includes(status) || false}
                        onChange={(e) => {
                          const selectedStatuses: any = sendMessagesObj?.businessType || [];
                          let updatedStatuses = e.target.checked ? [...selectedStatuses, status] : selectedStatuses.filter((s: any) => s !== status);
                          setSendMessagesObj({
                            ...sendMessagesObj,
                            businessType: updatedStatuses,
                            businessClaimType: updatedStatuses.length > 0 ? sendMessagesObj.businessClaimType : null,
                          });
                        }}
                      />
                    }
                    label={formatLabel(status)}
                  />
                ))}
              </FormGroup>

              {/* radio button for select Business Climed Status */}
              {(sendMessagesObj?.businessType?.length ?? 0) > 0 && (
                <Box mt={2}>
                  <Typography variant="h6">Select Business Claimed Status</Typography>
                  <RadioGroup
                    row
                    value={
                      sendMessagesObj?.businessClaimType === true ? "CLAIMED"
                        : sendMessagesObj?.businessClaimType === false ? "NOT_CLAIMED" : ""
                    }
                    onChange={(e) =>
                      setSendMessagesObj({
                        ...sendMessagesObj,
                        businessClaimType: e.target.value === "CLAIMED"
                      })
                    }
                  >
                    <FormControlLabel value="CLAIMED" control={<Radio />} label="Claimed" />
                    <FormControlLabel value="NOT_CLAIMED" control={<Radio />} label="Not Claimed" />
                  </RadioGroup>

                </Box>
              )}
            </Box>
          )}

          {/* input box for add subject and message discriptions */}
          <Box marginTop={2} width="100%">
            <TextField
              name="Subject"
              margin="dense"
              label="Subject"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={sendMessagesObj?.subject || ""}
              onChange={(e) => setSendMessagesObj({ ...sendMessagesObj, subject: e.target.value })}
              disabled={sendMessagesObj?.type === "WHATSAPP"}
            />
          </Box>
          <Box marginTop={2} width="100%">
            <Typography variant="h6">Message Description</Typography>
            <TextareaAutosize
              minRows={4}
              style={{ width: "100%", padding: "8px", fontSize: "16px" }}
              value={sendMessagesObj?.messageText || ""}
              onChange={(e) => setSendMessagesObj({ ...sendMessagesObj, messageText: e.target.value })}
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <Typography variant="h6" gutterBottom>
              Image
            </Typography>
            {sendMessagesObj?.imageUrl ? (
              <Box
                position="relative"
                width="100%"
                height="auto"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <img
                    src={
                      typeof sendMessagesObj?.imageUrl === "string"
                        ? sendMessagesObj?.imageUrl
                        : URL.createObjectURL(sendMessagesObj?.imageUrl)
                    }
                    alt="Message Image Upload"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "500px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <CustomButton
                  variant="outlined"
                  color="warning"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    zIndex: 1,
                    borderRadius: "50%",
                    width: "32px",
                    height: "32px",
                    minWidth: "0",
                    backgroundColor: "white",
                  }}
                  onClick={() => {
                    setSendMessagesObj((prev: any) => ({
                      ...prev,
                      imageUrl: "",
                    }));
                  }}
                  aria-label="Remove image"
                >
                  <Tooltip title="Remove">
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      &times;
                    </Box>
                  </Tooltip>
                </CustomButton>
              </Box>
            ) : (
              <label>
                <input
                  ref={imageRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "imageUrl")}
                />
                <CustomButton
                  variant="outlined"
                  size="small"
                  onClick={() => imageRef.current?.click()}
                >
                  Upload  Image
                </CustomButton>
                <TextField
                  label="Or Enter Image URL"
                  value={desktopUrl ?? (sendMessagesObj?.imageUrl || "")}
                  onBlurCapture={(e) => {
                    if (desktopUrl?.startsWith("https")) {
                      setSendMessagesObj((prev: any) => ({
                        ...prev,
                        imageUrl: desktopUrl,
                      }));
                    } else {
                      setDesktopUrl("");
                      setSendMessagesObj((prev: any) => ({
                        ...prev,
                        imageUrl: "",
                      }));
                    }
                  }}
                  onChange={(e) => setDesktopUrl(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </label>
            )}
          </Box>
          <Box marginTop={2} width={"100%"}>
            <CustomButton onClick={handleSubmit}>Send Messages</CustomButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default SendBulkMessagesModal;