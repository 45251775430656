import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomButton from "src/components/CustomButton";
import { GET_ALL_MESSAGES } from "src/graphql/query";
import {
  Box,
  Checkbox,
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import ActionMenu from "../Business/ActionMenu";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import CustomTable from "src/components/CustomTable";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "src/components/Common/DeleteModel";
import { DELETE_SPIN_REWARD } from "src/graphql/mutations";
import { toast } from "react-toastify";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import SendBulkMessagesModal from "./SendBulkMessagesModal";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface SendBulkMessages {
  _id: string;
}


const SendBulkMessages: React.FC = () => {
  const qrRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<SendBulkMessages[]>([]);
  const [selectedSpinReward, setSelectedSpinReward] = useState<SendBulkMessages | null>(
    null
  );
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [DeleteSpinReward] = useMutation(DELETE_SPIN_REWARD);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const initialSendMessagesObj = {
    _id: "",
  };
  const [sendMessagesObj, setSendMessagesObj] = useState<any>(initialSendMessagesObj);
  const COLUMNS = [
    {
      headerName: "User",
      field: "userId",
      flex: 1,
    },
    {
      headerName: "Campaign",
      field: "campaignId",
      flex: 1,
    },

    {
      headerName: "Message Type",
      field: "type",
      width: 130,
      renderCell: (params: any) => {
        return params.value === "WHATSAPP" ? (
          <Tooltip title="WhatsApp">  
            <WhatsAppIcon style={{ color: "#197ba0", fontSize: 40 }} />
          </Tooltip>
        ) : (
          <Tooltip title="Email">
            <MailOutlineIcon style={{ color: "#197ba0", fontSize: 40 }} />
          </Tooltip>
        );
      },
    },
    {
      headerName: "Discription",
      field: "messageText",
      flex: 3,
    },
  ];


  const { loading, error, data, refetch } = useQuery(GET_ALL_MESSAGES, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
    },
  });

  useEffect(() => {
    if (data?.getSendBulkNotifications?.data) {
      const processedData = data?.getSendBulkNotifications?.data.map((spinReward: any) => ({
        ...spinReward,
      }));
      setList(processedData);
      setTotal(data?.getSendBulkNotifications?.count);
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term.length > 0 ? term : "");
      refetch({
        search: term,
        page: pagination.page,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );


  const handleCloseModal = () => {
    setSendMessagesObj(initialSendMessagesObj);
    setOpenModal(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
    debouncedRefetch(value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <>
      <Box p={2}>
        {!openModal && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              paddingRight={4}
            >
              <Typography variant="h4" style={{ color: "#00C5B9" }}>
                Messages
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Box width={200}>
                  <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: searchTerm && (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {/* {isSuperAdmin && (
                  <ActionMenu
                    isSuperAdmin={isSuperAdmin}
                    setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
                    setOpenDeleteAllModel={setOpenDeleteAllModel}
                  />
                )} */}
                {hasPermissionPage("SendBulkMessages", "add") && (
                  <Box>
                    <CustomButton
                      onClick={() => setOpenModal(true)}
                      variant="contained"
                      className="width: 200px"
                    >
                      Send
                    </CustomButton>
                  </Box>
                )}
              </Box>
            </Box>
            <Box mt={2}>
              <CustomTable
                columns={COLUMNS}
                data={list}
                paginationModel={{
                  page: pagination.page - 1,
                  pageSize: pagination.pageSize,
                }}
                totalCount={total}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
            </Box>
          </>
        )}
        {openModal && (
          <SendBulkMessagesModal
            handleClose={handleCloseModal}
            refetchSendMessages={() => refetch()}
            initialSendMessagesObj={initialSendMessagesObj}
            sendMessagesObj={sendMessagesObj}
            setSendMessagesObj={setSendMessagesObj}
          />
        )}
      </Box>
    </>
  );
}

export default SendBulkMessages;