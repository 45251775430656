import React from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import CustomButton from "src/components/CustomButton";
import { format } from "date-fns";

interface PrizeWon {
  couponId: string;
  couponCode: string;
  winDate: string;
  status: string;
  expireDate: string;
}

interface UserSpin {
  _id: string;
  userId: string;
  spinWheelSlug: string;
  deviceId: string;
  userSpinCount: number;
  lastWinDate: string;
  lastSpinDate: string;
  count: number;
  createdAt: string;
  updatedAt: string;
  prizeWon: PrizeWon[];
}

interface UserSpinDetailsProps {
  spinData: UserSpin | null;
  onClose: () => void;
}

const UserSpinDetails: React.FC<UserSpinDetailsProps> = ({
  spinData,
  onClose,
}) => {
  if (!spinData) {
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          margin: "auto",
          p: 4,
          textAlign: "center",
          bgcolor: "background.paper",
          boxShadow: 3,
        }}
      >
        <Typography variant="h5" color="error">
          No Spin Data Available
        </Typography>
        <Box mt={3} display="flex" justifyContent="center">
          <CustomButton onClick={onClose} variant="outlined" color="primary">
            Close
          </CustomButton>
        </Box>
      </Box>
    );
  }

  const {
    userId,
    spinWheelSlug,
    deviceId,
    userSpinCount,
    lastWinDate,
    lastSpinDate,
    count,
    createdAt,
    updatedAt,
    prizeWon,
  } = spinData;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1200px",
        margin: "auto",
        p: 4,
        borderRadius: 2,
        bgcolor: "background.paper",
        boxShadow: 3,
        maxHeight: "80vh",
        overflowY: "auto",
      }}
    >
      <Typography variant="h4" color="primary" textAlign="center" gutterBottom>
        User Spin Details
      </Typography>

      {/* Basic Information */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            <strong>User ID:</strong> {userId}
          </Typography>
          <Typography variant="body1">
            <strong>Spin Wheel Slug:</strong> {spinWheelSlug}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            <strong>Device ID:</strong> {deviceId}
          </Typography>
          <Typography variant="body1">
            <strong>User Spin Count:</strong> {userSpinCount}
          </Typography>
        </Grid>
      </Grid>

      {/* Additional Information */}
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Additional Information</Typography>
          <Typography variant="body1">
            <strong>Last Win Date:</strong>{" "}
            {format(new Date(lastWinDate), "dd-MM-yy")}
            <br />
            <strong>Time:</strong> {format(new Date(lastWinDate), "hh:mm a")}
          </Typography>

          <Typography variant="body1">
            <strong>Last Spin Date:</strong>{" "}
            {format(new Date(lastSpinDate), "dd-MM-yy")}
            <br />
            <strong>Time:</strong> {format(new Date(lastSpinDate), "hh:mm a")}
          </Typography>

          <Typography variant="body1">
            <strong>Count:</strong> {count}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Timestamps</Typography>
          <Typography variant="body1">
            <strong>Created At:</strong> {format(new Date(createdAt), "PP")}
          </Typography>
          <Typography variant="body1">
            <strong>Updated At:</strong> {format(new Date(updatedAt), "PP")}
          </Typography>
        </Grid>
      </Grid>

      {/* Prize Won Section */}
      <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
        Prizes Won
      </Typography>
      <Grid container spacing={2}>
        {prizeWon && prizeWon.length > 0 ? (
          prizeWon.map((prize, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card sx={{ display: "flex", height: "100%" }}>
                <CardContent sx={{ flex: 1 }}>
                  <Typography variant="subtitle1">
                    <strong>Coupon ID:</strong> {prize.couponId}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Coupon Code:</strong> {prize.couponCode}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Win Date:</strong>{" "}
                    {format(new Date(prize.winDate), "PP")}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Status:</strong> {prize.status}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Expire Date:</strong>{" "}
                    {format(new Date(prize.expireDate), "PP")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1">No prizes won.</Typography>
        )}
      </Grid>

      <Box mt={3} display="flex" justifyContent="center">
        <CustomButton onClick={onClose} variant="outlined" color="primary">
          Close
        </CustomButton>
      </Box>
    </Box>
  );
};

export default UserSpinDetails;
