import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import SpinWheelModal from "./../SpinAndWin/SpinWheelModal";
import { DELETE_SPIN_WHEEL } from "src/graphql/mutations";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";
import { ClearIcon } from "@mui/x-date-pickers";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import { GET_SPIN_WHEEL, GET_SPIN_WHEELS } from "src/graphql/query";
import SpinWheelDetails from "./SpinWheelDetails";

interface SpinnerItem {
  couponId: string;
  image: string;
  bgColor: string;
  textColor: string;
  probability: number;
  isOpps: boolean;
}

interface SpinWheels {
  _id: string;
  name: string;
  startDate: string;
  endDate: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  spinner: SpinnerItem[];
}

const SpinWheels: React.FC = () => {
  const [list, setList] = useState<SpinWheels[]>([]);
  const [selectedSpinWheel, setSelectedSpinWheel] = useState<SpinWheels | null>(
    null
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteSpinWheelId, setDeleteSpinWheelId] = useState<string | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [deleteSpinWheel] = useMutation(DELETE_SPIN_WHEEL);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSuperAdmin = getRole() === "SuperAdmin";

  const COLUMNS = [
    {
      headerName: "Name",
      field: "name",
      width: 200,
      renderCell: ({ row }: any) => (
        <Box sx={{ cursor: "pointer" }} onClick={() => handleView(row)}>
          {row?.name}
        </Box>
      ),
    },
    {
          headerName: "Start Date",
          field: "startDate",
          flex: 1,
          renderCell: ({ row }: any) => (
            <Box>
              {row?.startDate
                ? new Date(row?.startDate).toLocaleDateString()
                : "No Start Date"}
            </Box>
          ),
        },
        {
          headerName: "End Date",
          field: "endDate",
          flex: 1,
          renderCell: ({ row }: any) => (
            <Box>
              {row?.endDate
                ? new Date(row?.endDate).toLocaleDateString()
                : "No End Date"}
            </Box>
          ),
        },
    { headerName: "Slug", field: "slug", flex: 1 },
    {
      headerName: "Number of Items",
      field: "spinner",
      width: 150,
      renderCell: ({ row }: any) => <Box>{row?.spinner?.length || 0}</Box>,
    },
    {
      headerName: "Actions",
      width: 100,
      renderCell: ({ row }: { row: SpinWheels }) => (
        <Box>
          {/* {hasPermissionPage("SpinWheel", "update") && ( */}
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEdit(row)}
              aria-label="edit"
              style={{ marginRight: "8px", color: "#00C5B9" }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          {/* )} */}
          {/* {hasPermissionPage("SpinWheel", "delete") && ( */}
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setDeleteSpinWheelId(row._id);
                setOpenDeleteModel(true);
              }}
              aria-label="delete"
              style={{ color: "#00C5B9" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {/* )} */}
        </Box>
      ),
    },
  ];

  // Fixed query with proper search parameter
  const { loading, error, data, refetch } = useQuery(GET_SPIN_WHEELS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data) {
      setList(data.getSpinWheels.data);
      setTotal(data.getSpinWheels.count);
    }
  }, [data]);

  const [getSpinWheel] = useLazyQuery(GET_SPIN_WHEEL);
  const handleView = async (rowData: any) => {
    try {
      const { data } = await getSpinWheel({
        variables: {
          slug: rowData.slug, // Change from id to slug
        },
      });
      setSelectedSpinWheel(data?.getSpinWheel?.data);
      setOpenViewModal(true);
    } catch (error) {
      console.error("Error fetching spin wheel:", error);
    }
  };

  const handleEdit = async (rowData: SpinWheels) => {
    try {
      const { data } = await getSpinWheel({
        variables: {
          slug: rowData?.slug, // Changed `id` to `slug`
        },
        fetchPolicy: "no-cache",
      });

      setSelectedSpinWheel(data?.getSpinWheel?.data); // Ensure the correct data structure
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching spin wheel:", error);
    }
  };

  const handleDelete = async () => {
    if (!deleteSpinWheelId) return;
    try {
      await deleteSpinWheel({
        variables: { _id: deleteSpinWheelId },
      });
      toast.success("Spin wheel deleted successfully!");
      refetch();
      setOpenDeleteModel(false);
    } catch (error) {
      console.error("Error deleting spin wheel:", error);
      toast.error("Failed to delete spin wheel.");
    }
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteSpinWheelId(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedSpinWheel(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;
  if (openViewModal && selectedSpinWheel) {
    return (
      <SpinWheelDetails
        wheelData={selectedSpinWheel}
        onClose={() => setOpenViewModal(false)}
      />
    );
  }

  return (
    <Box p={2}>
      {!openModal && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            paddingRight={4}
          >
            <Typography variant="h4" style={{ color: "#00C5B9" }}>
              Spin Wheels
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <Box width={200}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {/* {hasPermissionPage("SpinAndWin ", "add") && ( */}
              <Box>
                <CustomButton
                  onClick={() => setOpenModal(true)}
                  variant="contained"
                >
                  Create
                </CustomButton>
              </Box>
              {/* )} */}
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <CustomTable
              columns={COLUMNS}
              data={list}
              paginationModel={{
                page: pagination.page,
                pageSize: pagination.pageSize,
              }}
              totalCount={total}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </Box>
        </>
      )}

      {openModal && (
        <SpinWheelModal
          open={openModal}
          handleClose={handleCloseModal}
          spinWheel={selectedSpinWheel}
          refetchSpinWheels={refetch}
        />
      )}

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this spin wheel?"
        />
      )}
    </Box>
  );
};

export default SpinWheels;
