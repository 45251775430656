// Enums
export enum DealCategory {
  AccountingServices = "Accounting Services",
  BabyAndKids = "Baby & Kids",
  BeautySpaAndSalons = "Beauty, Spa & Salons",
  BusinessServices = "Business Services",
  CarsAndBikes = "Cars & Bikes",
  CatalogDeals = "Catalog Deals",
  Events = "Events",
  CameraPhotoAndVideo = "Camera, Photo & Video",
  HomeAppliances = "Home Appliances",
  LaptopAndPC = "Laptop & PC",
  Television = "Television",
  BoysFashion = "Boys Fashion",
  FashionAccessories = "Fashion Accessories",
  Footwear = "Footwear",
  GirlsFashion = "Girls Fashion",
  MensFashion = "Men's Fashion",
  Watches = "Watches",
  WomenFashion = "Women's Fashion",
  FoodAndRestaurant = "Food & Restaurant",
  HealthAndWellness = "Health & Wellness",
  HomeAndKitchen = "Home & Kitchen",
  Jewelry = "Jewelry",
  MobileAccessories = "Mobile Accessories",
  Supermarket = "Supermarket",
}

// Display Name Mappings
const dealCategoryDisplayNames = {
  [DealCategory.AccountingServices]: "Accounting Services",
  [DealCategory.BabyAndKids]: "Baby & Kids",
  [DealCategory.BeautySpaAndSalons]: "Beauty, Spa & Salons",
  [DealCategory.BusinessServices]: "Business Services",
  [DealCategory.CarsAndBikes]: "Cars & Bikes",
  [DealCategory.CatalogDeals]: "Catalog Deals",
  [DealCategory.Events]: "Events",
  [DealCategory.CameraPhotoAndVideo]: "Camera, Photo & Video",
  [DealCategory.HomeAppliances]: "Home Appliances",
  [DealCategory.LaptopAndPC]: "Laptop & PC",
  [DealCategory.Television]: "Television",
  [DealCategory.BoysFashion]: "Boys' Fashion",
  [DealCategory.FashionAccessories]: "Fashion Accessories",
  [DealCategory.Footwear]: "Footwear",
  [DealCategory.GirlsFashion]: "Girls' Fashion",
  [DealCategory.MensFashion]: "Men's Fashion",
  [DealCategory.Watches]: "Watches",
  [DealCategory.WomenFashion]: "Women's Fashion",
  [DealCategory.FoodAndRestaurant]: "Food & Restaurant",
  [DealCategory.HealthAndWellness]: "Health & Wellness",
  [DealCategory.HomeAndKitchen]: "Home & Kitchen",
  [DealCategory.Jewelry]: "Jewelry",
  [DealCategory.MobileAccessories]: "Mobile Accessories",
  [DealCategory.Supermarket]: "Supermarket",
};

export enum FilterDeals {
  RELEVANCE = "RELEVANCE",
  UPCOMING = "UPCOMING",
  EXPIRING_SOON = "EXPIRING_SOON",
  HIGH_TO_LOW = "HIGH_TO_LOW",
  LOW_TO_HIGH = "LOW_TO_HIGH",
  ALL = "ALL",
}

export enum RepeatOption {
  DOES_NOT_REPEAT = "DOES_NOT_REPEAT",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum DiscountType {
  Percentage = "PERCENTAGE",
  Flat = "FLAT",
}

const filterDealsDisplayNames = {
  [FilterDeals.RELEVANCE]: "Relevance",
  [FilterDeals.UPCOMING]: "Upcoming",
  [FilterDeals.EXPIRING_SOON]: "Expiring Soon",
  [FilterDeals.HIGH_TO_LOW]: "High to Low",
  [FilterDeals.LOW_TO_HIGH]: "Low to High",
  [FilterDeals.ALL]: "All",
};

const repeatOptionDisplayNames = {
  [RepeatOption.DOES_NOT_REPEAT]: "Does Not Repeat",
  [RepeatOption.WEEKLY]: "Weekly",
  [RepeatOption.MONTHLY]: "Monthly",
  [RepeatOption.YEARLY]: "Yearly",
};

const offerTypeDisplayNames = {
  [DiscountType.Percentage]: "Percentage",
  [DiscountType.Flat]: "Flat",
};

// Options Generation
export const dealCategoryOptions = Object.keys(DealCategory).map((key) => ({
  value: DealCategory[key as keyof typeof DealCategory],
  label:
    dealCategoryDisplayNames[DealCategory[key as keyof typeof DealCategory]],
}));

export const dealFilterOptions = Object.keys(FilterDeals).map((key) => ({
  value: FilterDeals[key as keyof typeof FilterDeals],
  label: filterDealsDisplayNames[FilterDeals[key as keyof typeof FilterDeals]],
}));

export const repeatOptions = Object.keys(RepeatOption).map((key) => ({
  value: RepeatOption[key as keyof typeof RepeatOption],
  label:
    repeatOptionDisplayNames[RepeatOption[key as keyof typeof RepeatOption]],
}));

export const offerTypeOptions = Object.keys(DiscountType).map((key) => ({
  value: DiscountType[key as keyof typeof DiscountType],
  label: offerTypeDisplayNames[DiscountType[key as keyof typeof DiscountType]],
}));

// Weekdays Array
export const Days = [
  {value: "ALL", label:"All"},
  { value: "SUNDAY", label: "Sunday" },
  { value: "MONDAY", label: "Monday" },
  { value: "TUESDAY", label: "Tuesday" },
  { value: "WEDNESDAY", label: "Wednesday" },
  { value: "THURSDAY", label: "Thursday" },
  { value: "FRIDAY", label: "Friday" },
  { value: "SATURDAY", label: "Saturday" },
];

// Interface
export interface DealTypes {
  _id: string;
  title: string;
  category: string;
  area: string;
  offer: string;
  offerType: DiscountType;
}
