import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Typography,
  FormControl,
  Box,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GET_APPROVED_BUSINESS,
  GET_BUSINESS,
  GET_DEALS_ENUM_ALL,
} from "src/graphql/query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import BusinessSelectDropDown from "src/components/Common/BusinessSelectDropDown";
import CustomButton from "src/components/CustomButton";
import Select from "react-select";
import {
  DealCategory,
  dealCategoryOptions,
  DiscountType,
  offerTypeOptions,
  RepeatOption,
  repeatOptions,
  Days,
} from "./dealCategory";
import { CREATE_DEAL, UPDATE_DEAL } from "src/graphql/mutations";
import { toast } from "react-toastify";
import moment from "moment";
import backButton from "../../Images/backButton.svg";
import { debounce } from "src/utils/debounce";
import RichTextEditor from "src/components/Common/TextEditor";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "src/constant";

interface BusinessOptions {
  value: string;
  label: string;
}

const CreateDeal = ({ reload, dealData, onClose, isDuplicating }: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [businessOptions, setBusinessOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState<BusinessOptions[]>([]);
  const [dealCategories, setDealCategories] = useState<any>([]);
  const [errors, setErrors] = useState({ startTime: "", endTime: "" });
  const [AddDeals] = useMutation(CREATE_DEAL);
  const [UpdateDeals] = useMutation(UPDATE_DEAL);
  const navigate = useNavigate();
  const location = useLocation();



  const formic = useFormik({
    initialValues: {
      businessProfileId: "",
      title: "",
      validFor: "",
      // isPromoted: false,
      category: "",
      area: "",
      discountPrice: "",
      discountType: "",
      currentPrice: "",
      discountPercentage: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      days: [],
      description: "",
      daysNote: "",
      // bookingLinks: "",
      // contactNo: "",
      // timing: [{ name: "", time: "", price: "" }],
    },
    validationSchema: Yup.object({
      businessProfileId: Yup.string().required("Business Profile Required"),
      title: Yup.string().required("Title is required"),
      validFor: Yup.string().notRequired(),
      category: Yup.string().required("Category is required"),
      area: Yup.string().required("Area is required"),
      discountPrice: Yup.number()
        .required("Discount Price is Required")
        .typeError("Must be a valid number")
        .integer("Must be a whole number"),
      discountType: Yup.string().required("Discount is required"),
      days: Yup.array()
        .of(Yup.string().required("Invalid day")) // Ensures each day is a valid string
        .min(1, "At least one day must be selected"),
      currentPrice: Yup.number()
        .nullable()
        .when("discountType", {
          is: DiscountType.Percentage,
          then: (schema) =>
            schema
              .required("Current Price is required")
              .typeError("Must be a number"),
        }),
      discountPercentage: Yup.number()
        .nullable()
        .when("discountType", {
          is: DiscountType.Percentage,
          then: (schema) =>
            schema
              .required("Discount Percentage is required")
              .typeError("Must be a number"),
        }),
      otherwise: Yup.object().nullable(),
      startDate: Yup.date().required("Start date is required"),  // <-- Updated
      endDate: Yup.date().required("End date is required"),
      startTime: Yup.string()
        .matches(
          /^(0[0-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/,
          "Enter a valid Start time (e.g., 09:00 AM)"
        )
        .required("Start time is required"),

      endTime: Yup.string()
        .matches(
          /^(0[0-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/,
          "Enter a valid End time (e.g., 06:00 PM)"
        )
        .required("End time is required"),
      description: Yup.string().required("Description required"),
      daysNote: Yup.string().notRequired(),
      // contactNo: Yup.string()
      //   .nullable()
      //   .matches(/^[0-9]+$/, "Please Enter numbers")
      //   .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits")
      //   .notRequired(),
      // bookingLinks: Yup.string()
      //   .nullable()
      //   .matches(
      //     /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
      //     "Enter a valid URL"
      //   )
      //   .notRequired(),
      // timing: Yup.array().of(
      //   Yup.object().shape({
      //     name: Yup.string().nullable().notRequired(),
      //     time: Yup.string()
      //       .nullable()
      //       .matches(
      //         /^([0-9]{1,2}):([0-9]{2}) (AM|PM) - ([0-9]{1,2}):([0-9]{2}) (AM|PM)$/,
      //         "Enter a valid time range (e.g., 09:00 AM - 06:00 PM)"
      //       )
      //       .notRequired(),
      //     price: Yup.number()
      //       .nullable()
      //       .typeError("Price must be a number")
      //       .positive("Price must be a positive number")
      //       .notRequired(),
      //   })
      // ),
    }),
    onSubmit: () => handleSubmit(),
  });

  const { data: dealCategory, error } = useQuery(GET_DEALS_ENUM_ALL, {
    onError: (err) => {
      toast.error("Facing issue in fetching categories");
      console.error("Query error:", err);
    },
    onCompleted: (data) => {
      const dealCategories = data?.getAllDealsEnum?.data ?? [];
      const optionObj = dealCategories?.map((item: any) => ({
        value: item?.name,
        label: item?.name,
      }));
      setDealCategories(optionObj);
    },
  });

  const handleClose = () => {
    formic.resetForm();
    setSearchTerm("");
    onClose();
    navigate(ROUTES.DEALS)
    window.location.reload();
  };

  // Lazy query to fetch businesses based on search
  const [getBusinesses, { data, loading }] = useLazyQuery(GET_BUSINESS, {
    variables: { search: searchTerm },
    fetchPolicy: "network-only",
  });

  const handleSearchTermChange = debounce((term: string) => {
    if (term.length >= 3) {
      setSearchTerm(term);
      getBusinesses({ variables: { search: term } });
    } else {
      setSearchTerm("");
      setBusinessOptions([]);
      setAreaOptions([]);
    }
  }, 300);

  useEffect(() => {
    if (
      data &&
      data?.getBusinessProfilesAll &&
      data?.getBusinessProfilesAll?.data
    ) {
      setBusinessOptions(
        data?.getBusinessProfilesAll?.data?.map((business: any) => ({
          value: business._id,
          label: `${business?.businessName} (${business?.pinCodeDetails})`,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (
      formic?.values?.businessProfileId &&
      data?.getBusinessProfilesAll?.data
    ) {
      const selectedBusiness = data?.getBusinessProfilesAll?.data?.find(
        (business: any) => business?._id === formic?.values?.businessProfileId
      );

      if (selectedBusiness) {
        const updatedAreaOptions =
          selectedBusiness?.pinCodeDetails?.map((pinCode: string) => ({
            value: pinCode,
            label: pinCode,
          })) || [];
        setAreaOptions(updatedAreaOptions);

        // Set default area if not already set
        if (!formic?.values?.area && updatedAreaOptions?.length > 0) {
          formic.setFieldValue("area", updatedAreaOptions[0]?.value);
        }
      }
    }
  }, [formic?.values?.businessProfileId, data]);

  const handleSubmit = async () => {
    const payload: any = {
      ...formic?.values,
    };

    if (formic?.values?.discountType === DiscountType.Flat) {
      delete payload.currentPrice;
    }
    if (formic?.values?.discountType === DiscountType.Flat) {
      delete payload.discountPercentage;
    }

    try {
      const response = isDuplicating
        ? await AddDeals({
          variables: {
            input: payload,
          },
        })
        : dealData
          ? await UpdateDeals({
            variables: {
              _id: dealData?._id,
              input: payload,
            },
          })
          : await AddDeals({
            variables: {
              input: payload,
            },
          });

      if (response?.errors) {
        throw new Error(response?.errors as any);
      } else {
        toast.success(
          response?.data?.addDeals?.message ||
          response?.data?.updateDeals?.message ||
          "Deals"
        );
        reload();
        formic.handleReset;
        handleClose();

      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const offerText = formic?.values?.discountPrice || "";

  useEffect(() => {
    formic.setValues((prev: any) => ({
      ...prev,
      discountPrice: offerText,
    }));
  }, [offerText]);
  useEffect(() => {
    if (dealData) {
      const initialValues = {
        businessProfileId: isDuplicating
          ? ""
          : dealData?.businessProfileId || "",
        title: dealData?.title || "",
        validFor: dealData?.validFor || "",
        // isPromoted: dealData?.isPromoted || false,
        category: dealData?.category || "",
        area: isDuplicating ? "" : dealData?.area || "",
        discountPrice: dealData?.discountPrice || "",
        discountType: dealData?.discountType || "",
        currentPrice: dealData?.currentPrice || "",
        discountPercentage: dealData?.discountPercentage || "",
        // duration: dealData?.duration
        //   ? {
        //       startDate: moment(dealData?.duration?.startDate).format(
        //         "YYYY-MM-DD"
        //       ),
        //       endDate: moment(dealData?.duration?.endDate).format("YYYY-MM-DD"),
        //     }
        //   : { startDate: "", endDate: "" },
        startDate: dealData?.startDate
          ? moment(dealData?.startDate).format("YYYY-MM-DD")
          : "",
        endDate: dealData?.endDate
          ? moment(dealData?.endDate).format("YYYY-MM-DD")
          : "",
        startTime: dealData?.startTime || "",
        endTime: dealData?.endTime || "",
        // days: dealData?.days?.length ? dealData?.days : [],
        days: dealData?.days || [],

        description: dealData?.description || "",
        daysNote: dealData?.daysNote || "",
        // bookingLinks: dealData?.bookingLinks || "",
        // contactNo: dealData?.contactNo || "",
        // timing: dealData?.timing?.length
        //   ? dealData?.timing?.map((item: any) => ({
        //     name: item?.name || "",
        //     time: item?.time || "",
        //     price: item?.price || "",
        //   }))
        //   : [{ name: "", time: "", price: "" }],
      };

      formic.setValues(initialValues);
      if (dealData?.businessProfile && !isDuplicating) {
        const businessOption = {
          value: dealData?.businessProfileId,
          label: `${dealData?.businessProfile?.businessName} (${dealData?.businessProfile?.pinCodeDetails})`,
        };
        setBusinessOptions([businessOption] as any);

        if (dealData?.businessProfile?.pinCodeDetails?.length > 0) {
          const updatedAreaOptions =
            dealData?.businessProfile?.pinCodeDetails?.map(
              (pinCode: string) => ({
                value: pinCode,
                label: pinCode,
              })
            );
          setAreaOptions(updatedAreaOptions);

          // Set area field value to the first pincode if not set
          if (!formic?.values?.area) {
            formic.setFieldValue("area", updatedAreaOptions[0]?.value);
          }
        }
      }

    }
  }, [dealData]);

  const handleBusinessChange = (selectedOption: any | null) => {
    if (!selectedOption) {
      formic.setFieldValue("businessProfileId", "");
      setSearchTerm(""); // Clear the search term
      setBusinessOptions([]); // Reset the business options
      formic.setFieldValue("area", "");
      return;
    }

    formic.setFieldValue("businessProfileId", selectedOption?.value);
    setSearchTerm(selectedOption?.label);

    const selectedBusiness = data?.getBusinessProfilesAll?.data?.find(
      (business: any) => business?._id === selectedOption?.value
    );

    if (selectedBusiness && selectedBusiness?.pinCodeDetails?.length > 0) {
      formic.setFieldValue("area", selectedBusiness?.pinCodeDetails[0]); // Default to the first available area
    } else {
      formic.setFieldValue("area", "");
    }
  };

  const generateSlug = (label: string) => {
    const cleanedLabel = label?.replace(/\(.*\)/g, "").trim();
    return cleanedLabel
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w-]/g, "") // Remove special characters
      .replace(/^-+|-+$/g, ""); // Remove leading/trailing hyphens
  };

  const selectedBusiness = businessOptions?.find(
    (option: { value: string; label: string }) =>
      option?.value === formic?.values?.businessProfileId
  ) as { value: string; label: string } | undefined;

  const businessSlug = selectedBusiness
    ? generateSlug(selectedBusiness.label)
    : null;

  const handleViewBusiness = () => {
    if (selectedBusiness && businessSlug) {
      window.open(`/business/${businessSlug}`, "_blank");
    } else {
      console.error("No business found or slug could not be generated.");
    }
  };

  const handleEditViewBusiness = () => {
    // Check if dealData exists, and use the businessProfile from it if so
    const businessToView = dealData
      ? dealData.businessProfile
      : selectedBusiness; // This will ensure we always have the business to view

    if (businessToView) {
      const businessSlug = generateSlug(businessToView?.businessName);
      window.open(`/business/${businessSlug}`, "_blank");
    } else {
      console.error("No business found or slug could not be generated.");
    }
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedStartDate = new Date(e.target.value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (selectedStartDate < today) {
      formic.setFieldValue("startDate", "");
    } else {
      formic.setFieldValue("startDate", e.target.value);
      if (
        formic?.values?.endDate &&
        new Date(formic?.values?.endDate) < selectedStartDate
      ) {
        formic.setFieldValue("endDate", "");
      }
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedEndDate = new Date(e.target.value);
    const startDate = new Date(formic?.values?.startDate);

    if (startDate && selectedEndDate < startDate) {
      formic.setFieldValue("endDate", "");
    } else {
      formic.setFieldValue("endDate", e.target.value);
    }
  };


  const handleTimeChange = (field: "startTime" | "endTime", value: string) => {
    // Allow the input to be updated first
    formic.setFieldValue(field, value);

    // Regular expression to validate 12-hour time format (HH:MM AM/PM)
    const timeFormat = /^(0?[1-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/;

    if (value && !timeFormat.test(value)) {
      formic.setFieldError(field, "Invalid time format (e.g., 09:00 AM)");
      return;
    }

    // If startTime is set, validate endTime
    if (field === "startTime" && formic.values.endTime) {
      const startTime = moment(value, "hh:mm A");
      const endTime = moment(formic.values.endTime, "hh:mm A");

      if (endTime.isBefore(startTime)) {
        formic.setFieldValue("endTime", "");
        formic.setFieldError("endTime", "End time must be after start time");
      }
    }

    // If endTime is set, validate it against startTime
    if (field === "endTime" && formic.values.startTime) {
      const startTime = moment(formic.values.startTime, "hh:mm A");
      const endTime = moment(value, "hh:mm A");

      if (endTime.isBefore(startTime)) {
        formic.setFieldError("endTime", "End time must be after start time");
      }
    }
  };



  // const handleAddTiming = () => {
  //   const newTiming = [
  //     ...formic?.values?.timing,
  //     { name: "", time: "", price: "" },
  //   ];
  //   formic.setFieldValue("timing", newTiming);
  // };

  // const handleRemoveTiming = (index: number) => {
  //   const newTiming = formic?.values?.timing.filter(
  //     (_: any, i: number) => i !== index
  //   );
  //   formic.setFieldValue("timing", newTiming);
  // };

  return (
    <Box p={2}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={handleClose}
        />
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {dealData && !isDuplicating ? "Update Deal" : "Create Deal"}
        </Typography>
      </Box>
      <form onSubmit={formic.handleSubmit}>
        <Grid container spacing={2}>
          {/* Existing form fields */}
          <Grid item xs={12} sm={6}>
            <Select
              isClearable
              placeholder={loading ? "Loading..." : "Select a Business"}
              value={
                businessOptions.find(
                  (option: any) =>
                    option?.value === formic?.values?.businessProfileId
                ) || null
              }
              onChange={handleBusinessChange}
              onInputChange={(value, actionMeta) => {
                if (actionMeta.action === "input-change") {
                  if (value === "") {
                    setSearchTerm(""); // Clear search and prevent API call
                    setBusinessOptions([]); // Clear dropdown
                  } else {
                    handleSearchTermChange(value);
                  }
                }
              }}
              options={businessOptions}
            />

            {formic?.touched?.businessProfileId &&
              formic?.errors?.businessProfileId && (
                <Typography variant="body2" color="error">
                  {formic?.errors?.businessProfileId}
                </Typography>
              )}
            {selectedBusiness && (
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={handleViewBusiness}
              >
                <Box display={"flex"} gap={1} alignItems={"center"} mb={1}>
                  <Typography variant="body2" color="textSecondary">
                    View this business : {selectedBusiness?.label}
                  </Typography>
                </Box>
              </Box>
            )}
            {dealData && !isDuplicating && !selectedBusiness && (
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={handleEditViewBusiness}
              >
                <Box display={"flex"} gap={1} alignItems={"center"} mb={1}>
                  <Typography variant="body2" color="textSecondary">
                    View this business :{" "}
                    {dealData?.businessProfile?.businessName} (
                    {dealData?.businessProfile?.pinCodeDetails})
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>

          {/* Category and other fields */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={
                formic?.touched?.category && Boolean(formic?.errors?.category)
              }
              sx={{ zIndex: 99999 }}
            >
              <Select
                options={dealCategories}
                value={dealCategories?.find(
                  (option: any) => option?.value === formic?.values?.category
                )}
                isClearable
                onChange={(selectedOption: any) => {
                  const value = selectedOption ? selectedOption?.value : "";
                  formic.setFieldValue("category", value as DealCategory);
                }}
                placeholder="Select Category"
              />
              {formic?.touched?.category && formic?.errors?.category && (
                <Typography variant="body2" color="error">
                  {formic?.errors?.category}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Other fields */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Title"
              name="title"
              value={formic?.values?.title}
              onChange={formic.handleChange}
              onBlur={formic.handleBlur}
              error={formic?.touched?.title && Boolean(formic?.errors?.title)}
              helperText={
                formic?.touched?.title && formic?.errors?.title
                  ? formic?.errors?.title
                  : ""
              }
            />
          </Grid>

          {/* Area */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formic?.touched?.area && Boolean(formic?.errors?.area)}
            >
              <Select
                options={areaOptions}
                onChange={(option) => {
                  formic.setFieldValue("area", option?.value);
                }}
                placeholder="Select Area"
                value={areaOptions?.find(
                  (option) => option?.value === formic?.values?.area
                )}
                isClearable
              />
              {formic?.touched?.area && formic?.errors?.area && (
                <div style={{ color: "red" }}>{formic?.errors?.area}</div>
              )}
            </FormControl>
          </Grid>

          {/* Start Date Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Start Date"
              name="startDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formic?.values?.startDate || ""}
              onChange={handleStartDateChange}
              onBlur={formic.handleBlur}
              error={
                formic?.touched?.startDate &&
                Boolean(formic?.errors?.startDate)
              }
              helperText={
                formic?.touched?.startDate &&
                formic?.errors?.startDate
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* End Date Field */}
            <TextField
              fullWidth
              label="End Date"
              name="endDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formic?.values?.endDate || ""}
              inputProps={{
                min:
                  formic?.values?.startDate ||
                  new Date().toISOString().split("T")[0],
              }}
              onChange={handleEndDateChange}
              onBlur={formic.handleBlur}
              error={
                formic?.touched?.endDate &&
                Boolean(formic?.errors?.endDate)
              }
              helperText={
                formic?.touched?.endDate &&
                formic?.errors?.endDate
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Time"
              name="startTime"
              value={formic?.values?.startTime}
              onChange={(e) => handleTimeChange("startTime", e.target.value)}
              placeholder="Enter Start Time (e.g., 09:00 AM)"
              fullWidth
              variant="outlined"
              error={
                formic?.touched?.startTime &&
                Boolean(formic?.errors?.startTime)
              }
              helperText={
                formic?.touched?.startTime &&
                formic?.errors?.startTime
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="End Time"
              name="endTime"
              value={formic?.values?.endTime}
              onChange={(e) => handleTimeChange("endTime", e.target.value)}
              placeholder="Enter End Time (e.g., 06:00 PM)"
              fullWidth
              variant="outlined"
              error={
                formic?.touched?.endTime &&
                Boolean(formic?.errors?.endTime)
              }
              helperText={
                formic?.touched?.endTime &&
                formic?.errors?.endTime
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Valid For"
              name="validFor"
              value={formic?.values?.validFor}
              onChange={formic.handleChange}
              onBlur={formic.handleBlur}
              error={formic?.touched?.validFor && Boolean(formic?.errors?.validFor)}
              helperText={
                formic?.touched?.validFor && formic?.errors?.validFor
                  ? formic?.errors?.validFor
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Select Week Days:</Typography>
            <FormControl
              sx={{ zIndex: "999" }}
              error={
                formic?.touched?.days &&
                Boolean(formic?.errors?.days)
              }
            >
              <Select
                options={Days}
                isMulti
                value={Days.filter((day) =>
                  formic.values.days.includes(day?.value as never)
                )}
                onChange={(selectedOptions: any) => {
                  let selectedValues = selectedOptions
                    ? selectedOptions.map((option: any) => option.value)
                    : [];

                  if (selectedValues.includes("ALL")) {
                    selectedValues = ["ALL"]; // If "ALL" is selected, clear other selections
                  } else if (
                    selectedValues.length === Days.length - 1 // If all 7 days are selected
                  ) {
                    selectedValues = ["ALL"]; // Replace them with "ALL"
                  }

                  formic.setFieldValue("days", selectedValues);
                }}
                onBlur={() => formic.setFieldTouched("days", true)}
                placeholder="Select Days"
              />

              {formic.touched.days && formic.errors.days && (
                <Typography color="error">{formic.errors.days}</Typography>
              )}

            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Offer : {offerText}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={formic?.touched?.discountType && Boolean(formic?.errors?.discountType)}
            >
              <Select
                options={offerTypeOptions}
                value={offerTypeOptions.find((option) => option?.value === formic?.values?.discountType)}
                isClearable
                onChange={(selectedOption: any) => {
                  const value = selectedOption ? selectedOption?.value : "";
                  formic.setFieldValue("discountType", value as DiscountType);

                  if (value === DiscountType.Percentage) {
                    formic.setFieldValue("currentPrice", "");
                    formic.setFieldValue("discountPercentage", "");
                    formic.setFieldValue("discountPrice", "");
                  } else if (value === DiscountType.Flat) {
                    formic.setFieldValue("discountPrice", "");
                  }
                }}
                placeholder="Select Offer Type"
              />
              {formic?.touched?.discountType && formic?.errors?.discountType && (
                <Typography variant="body2" color="error">
                  {formic?.errors?.discountType}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {formic?.values?.discountType === DiscountType.Percentage && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Current Price"
                  name="currentPrice"
                  type="number"
                  value={formic?.values?.currentPrice}
                  inputProps={{ min: 0 }}
                  onChange={(event) => {
                    const currentPrice = Number(event.target.value);
                    formic.setFieldValue("currentPrice", currentPrice);

                    const discountPercentage = Number(formic.values.discountPercentage) || 0;
                    const discountAmount = (currentPrice * discountPercentage) / 100;
                    formic.setFieldValue("discountPrice", currentPrice - discountAmount);
                  }}
                  onBlur={formic.handleBlur}
                  error={formic?.touched?.currentPrice && Boolean(formic?.errors?.currentPrice)}
                  helperText={formic?.touched?.currentPrice && formic?.errors?.currentPrice}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Discount Percentage"
                  name="discountPercentage"
                  type="number"
                  value={formic?.values?.discountPercentage}
                  inputProps={{ min: 1, max: 99 }}
                  onChange={(event) => {
                    let discountPercentage = Number(event.target.value);
                    if (discountPercentage < 1) discountPercentage = 1;
                    if (discountPercentage > 99) discountPercentage = 99;
                    formic.setFieldValue("discountPercentage", discountPercentage);

                    const currentPrice = Number(formic.values.currentPrice) || 0;
                    const discountAmount = (currentPrice * discountPercentage) / 100;
                    formic.setFieldValue("discountPrice", currentPrice - discountAmount);
                  }}
                  onBlur={formic.handleBlur}
                  error={formic?.touched?.discountPercentage && Boolean(formic?.errors?.discountPercentage)}
                  helperText={formic?.touched?.discountPercentage && formic?.errors?.discountPercentage}
                />
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Discount Price"
              name="discountPrice"
              type="number"
              inputProps={{ min: 0 }}
              value={formic?.values?.discountPrice}
              disabled={formic?.values?.discountType === DiscountType.Percentage} // Disable when percentage selected
              onChange={formic.handleChange}
              onBlur={formic.handleBlur}
              error={formic?.touched?.discountPrice && Boolean(formic?.errors?.discountPrice)}
              helperText={formic?.touched?.discountPrice && formic?.errors?.discountPrice}
            />
          </Grid>



          {/* <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormControlLabel
                control={
                  <Checkbox
                    name="isPromoted"
                    checked={formic?.values?.isPromoted}
                    onChange={formic?.handleChange}
                  />
                }
                label="Is Promoted"
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="h6">Description:</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={formic?.values?.description}
              onChange={(event) =>
                formic.setFieldValue("description", event.target.value)
              }
              onBlur={formic.handleBlur}
              error={formic?.touched?.description && Boolean(formic?.errors?.description)}
              helperText={formic?.touched?.description && formic?.errors?.description}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Days Note:</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={formic?.values?.daysNote}
              onChange={(event) =>
                formic.setFieldValue("daysNote", event.target.value)
              }
              onBlur={formic.handleBlur}
              error={formic?.touched?.daysNote && Boolean(formic?.errors?.daysNote)}
              helperText={formic?.touched?.daysNote && formic?.errors?.daysNote}
            />
          </Grid>

          {/* <Box marginTop={2} marginLeft={2}>
            <Typography variant="h6" gutterBottom>
              Timing Details
            </Typography>
            <Grid>
              {formic?.values?.timing.map((timingField: any, index: number) => (
                <Grid
                  container
                  item
                  xs={12}
                  key={index}
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <TextField
                      name={`timing.${index}.name`}
                      margin="dense"
                      label="Name"
                      fullWidth
                      value={timingField?.name}
                      onChange={formic.handleChange}
                      error={
                        formic?.touched?.timing?.[index]?.name &&
                        Boolean((formic?.errors?.timing as any)?.[index]?.name)
                      }
                      helperText={
                        formic?.touched?.timing?.[index]?.name &&
                        ((formic?.errors?.timing as any)?.[index]
                          ?.name as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name={`timing.${index}.time`}
                      margin="dense"
                      label="Time"
                      fullWidth
                      value={timingField?.time}
                      onChange={formic.handleChange}
                      error={
                        formic?.touched?.timing?.[index]?.time &&
                        Boolean((formic?.errors?.timing as any)?.[index]?.time)
                      }
                      helperText={
                        formic?.touched?.timing?.[index]?.time &&
                        ((formic?.errors?.timing as any)?.[index]
                          ?.time as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name={`timing.${index}.price`}
                      margin="dense"
                      label="Price"
                      fullWidth
                      value={timingField?.price}
                      onChange={formic.handleChange}
                      error={
                        formic?.touched?.timing?.[index]?.price &&
                        Boolean((formic?.errors?.timing as any)?.[index]?.price)
                      }
                      helperText={
                        formic?.touched?.timing?.[index]?.price &&
                        ((formic?.errors?.timing as any)?.[index]
                          ?.price as string)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Tooltip title="Add">
                      <IconButton color="primary" onClick={handleAddTiming}>
                        <AddCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {formic?.values?.timing.length > 1 && (
                      <Tooltip title="Remove">
                        <IconButton
                          color="secondary"
                          onClick={() => handleRemoveTiming(index)}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box> */}

          {/* <Grid item xs={12}>
            <TextField
              label="Booking Link"
              name="bookingLinks"
              value={formic?.values?.bookingLinks}
              onChange={formic.handleChange}
              fullWidth
              variant="outlined"
              error={
                formic?.touched?.bookingLinks &&
                Boolean(formic?.errors?.bookingLinks)
              }
              helperText={
                formic?.touched?.bookingLinks &&
                (formic?.errors?.bookingLinks as string)
              }
            />
          </Grid> */}
          {/* <Grid item xs={12}>
            <TextField
              label="Contact Number"
              name="contactNo"
              value={formic?.values?.contactNo}
              onChange={formic.handleChange}
              fullWidth
              variant="outlined"
              error={
                formic?.touched?.contactNo && Boolean(formic?.errors?.contactNo)
              }
              helperText={
                formic?.touched?.contactNo &&
                (formic?.errors?.contactNo as string)
              }
            />
          </Grid> */}
          {/* Submit button */}
          <Grid item xs={12}>
            <CustomButton type="submit" variant="contained" color="primary">
              Submit
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreateDeal;
