import React from "react";
import {
  Box,
  Typography,
  Modal,
  Grid,
  Link,
  Divider,
  Avatar,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";

interface PopularLocationModalProps {
  onClose: () => void;
  locationData: any;
}

const PopularLocationDetails: React.FC<PopularLocationModalProps> = ({
  onClose,
  locationData,
}) => {
  if (!locationData) {
    return (
      <Box
        sx={{
          // top: "50%",
          maxWidth: "1500px",
          margin: "auto",
          // mt: "10%",
          p: 3,
          textAlign: "center",
          borderRadius: 2,
          boxShadow: 3,
          overflowY: "auto",
          bgcolor: "background.paper",
          maxHeight: "80vh",
        }}
      >
        <Typography
          variant="h5"
          color="#00C5B9"
          textAlign="center"
          fontWeight="bold"
          mb={2}
        >
          Popular Location Details
        </Typography>
        <Typography>No location data available.</Typography>
        <Box mt={3} display="flex" justifyContent="center">
          <CustomButton onClick={onClose} variant="outlined" color="primary">
            Close
          </CustomButton>
        </Box>
      </Box>
    );
  }

  const {
    title,
    UserName,
    images,
    address,
    google_location_link,
    about,
    category,
    timings,
    pincodeDetails,
    rank,
    slug,
    metaTitle,
    metaDescription,
    isPromoted,
  } = locationData;

  return (
    <Box
      sx={{
        // top: "50%",
        overflowY: "auto",
        width: "100%",
        maxWidth: "1500px",
        margin: "auto",
        // mt: 5,
        p: 4,
        borderRadius: 2,
        bgcolor: "background.paper",
        boxShadow: 3,
        maxHeight: "80vh",
      }}
    >
      <Typography
        variant="h5"
        color="#00C5B9"
        textAlign="center"
        fontWeight="bold"
        mb={3}
      >
        Popular Location Details
      </Typography>

      <Grid container spacing={3}>
        {/* Title and User */}
        <Grid item xs={12}>
          <Typography variant="h6">{title}</Typography>
        </Grid>

        {/* Address */}
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" gutterBottom>
            <strong> Address: </strong> {address}
          </Typography>
          <Typography variant="body2">
            <strong> Area Name: </strong> {pincodeDetails?.areaName}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Category:</strong> {category}
          </Typography>

          {/* {pincodeDetails?.pinCode?.map((data: any, index: number) => (
            <Typography key={index} variant="body2">
              {data?.postoffice} - {data?.pincode}
            </Typography>
          ))} */}
          {Array.isArray(pincodeDetails?.pinCode) ? (
            pincodeDetails.pinCode.map((data: any, index: number) => (
              <Typography key={index} variant="body2">
                {data?.postoffice} - {data?.pincode}
              </Typography>
            ))
          ) : (
            <Typography>No Pincode details available.</Typography>
          )}


          {google_location_link && (
            <Typography variant="body2">
              Google Location:{" "}
              <Link
                href={google_location_link}
                target="_blank"
                rel="noopener"
                color="primary"
              >
                View on Google Maps
              </Link>
            </Typography>
          )}
          <Typography variant="body2">
            <strong>Promoted:</strong> {isPromoted ? "True" : "False"}
          </Typography>
        </Grid>

        {/* Category */}
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" gutterBottom>
            <strong>Slug:</strong> {slug}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Rank:</strong> {rank}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>MetaTitle:</strong> {metaTitle}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>MetaDescription:</strong> {metaDescription}
          </Typography>
        </Grid>

        {/* Timings */}
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            Timings:
          </Typography>
          {timings && timings.length > 0 ? (
            timings.map((timing: any, index: number) => (
              <Typography key={index} variant="body2">
                {timing.day}: {timing.startTime} - {timing.endTime} (
                {timing.other})
              </Typography>
            ))
          ) : (
            <Typography>No timings available.</Typography>
          )}
        </Grid>

        {/* About */}
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            About:
          </Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: about }}
          />
        </Grid>

        {/* Images and Videos */}
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            Media:
          </Typography>
          {images && images.length > 0 ? (
            <Grid container spacing={2}>
              {images.map((mediaItem: any, index: any) => {
                // Handle different possible formats of the media item
                const mediaUrl = typeof mediaItem === 'string' ? mediaItem : mediaItem.imageUrl;

                // Check if it's a video
                const isVideo = mediaUrl.endsWith('.mp4') ||
                  mediaUrl.endsWith('.mov') ||
                  mediaUrl.endsWith('.webm') ||
                  (mediaItem.fileType === 'video');

                return (
                  <Grid item xs={12} sm={4} key={index}>
                    {isVideo ? (
                      <video
                        controls
                        style={{ width: "100%", height: "auto", borderRadius: 8 }}
                      >
                        <source src={mediaUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={mediaUrl}
                        alt={`Location media ${index + 1}`}
                        style={{ width: "100%", height: "auto", borderRadius: 8 }}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Typography>No media available.</Typography>
          )}
        </Grid>
      </Grid>

      {/* Close Button */}
      <Box mt={3} display="flex" justifyContent="center">
        <CustomButton onClick={onClose} variant="outlined" color="primary">
          Close
        </CustomButton>
      </Box>
    </Box>
  );
};

export default PopularLocationDetails;
