import React, { useState } from "react";
import {
  Box,
  CardMedia,
  Grid,
  Modal,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DisplayVideo from "src/components/video/DisplayVideo";

interface ImageData {
  imageUrl: string;
  fileType?: string;
}

interface ImageGalleryProps {
  galleries: ImageData[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ galleries }) => {
  const [open, setOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<string | null>(null);
  const [isVideo, setIsVideo] = useState(false);

  const handleMediaClick = (media: ImageData) => {
    setSelectedMedia(media.imageUrl);
    setIsVideo(
      media.fileType === "video" ||
      media.imageUrl.endsWith(".mp4") ||
      media.imageUrl.endsWith(".mov") ||
      media.imageUrl.endsWith(".webm")
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMedia(null);
    setIsVideo(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        {galleries?.map((gallery, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            {gallery.fileType === "video" || gallery.imageUrl.endsWith(".mp4") || gallery.imageUrl.endsWith(".mov") || gallery.imageUrl.endsWith(".webm") ? (
              <video
                onClick={() => handleMediaClick(gallery)}
                style={{ width: "100%", borderRadius: 8, cursor: "pointer" }}
                controls
                controlsList="nodownload"
              >
                <source src={gallery.imageUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <CardMedia
                component="img"
                height="250"
                image={gallery.imageUrl}
                alt={`Gallery image ${index + 1}`}
                sx={{
                  borderRadius: 2,
                  boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                  transition: "transform 0.3s",
                  cursor: "pointer",
                  "&:hover": { transform: "scale(1.03)" },
                }}
                onClick={() => handleMediaClick(gallery)}
              />
            )}
          </Grid>
        ))}
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            outline: 0,
            borderRadius: 3,
            width: "90%",
            maxWidth: 800,
            maxHeight: "90vh",
            overflowY: "auto",
            p: 2,
          }}
        >
          <Box sx={{ position: "absolute", top: 10, right: 10, zIndex:"9999" , }}>
            <IconButton
              onClick={handleClose}
              sx={{ color: "#fff", backgroundColor: "#1976d2", "&:hover": { backgroundColor: "#155b9f" } }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedMedia && (
            isVideo ? (    <Box
              sx={{
                width: "100%",
                height: "auto",
                maxHeight: "700px",
                borderRadius: 2,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DisplayVideo item={selectedMedia} style={{ width: "100%", height: "auto", maxHeight: "700px" }} />
            </Box>
            ) : (
              <CardMedia
                component="img"
                image={selectedMedia}
                alt="Selected gallery media"
                sx={{ width: "100%", height: "auto", maxHeight: "700px", borderRadius: 2 }}
              />
            )
          )}
          <Typography variant="body2" sx={{ mt: 2, textAlign: "center", color: "#555" }}>
            Media {galleries?.findIndex((g) => g.imageUrl === selectedMedia) + 1} of {galleries?.length}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ImageGallery;
