
export const defaultTiming = {
  startTime: "",
  endTime: "",
  others: "",
};

export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
