export const ROUTES = {
  HOME: "/home",
  LOGIN: "/login",
  USERS: "/users",
  USERS_DETAILS: "/users/:id",
  PINCODES: "/pincode",
  DEFAULT: "/events",
  EVENTS: "/events",
  EVENTS_DETAILS: "/events/:id",
  NEWS: "/news",
  NEWS_DETAILS: "/news/:id",
  BUSINESS: "/business",
  USERSPIN: "/UserSpin",
  SPINANDWIN : "/SpinAndWin",
  BUSSINESS_DETAILS: "/business/:id",
  DELETEBUSSINESS_DETAILS: "/DeleteBusiness/:id",
  EXTRABUSSINESS_DETAILS: "/ExtraBusiness/:id",
  COMPAIGN_DETAILS:"/campaign/:id",
  BUSSINESS_CREATE: "/business/create",
  BUSSINESS_EDIT: "/business/edit/:id",
  DELETEBUSINESS_EDIT: "/DeleteBusiness/edit/:id",
  EXTRABUSINESS_EDIT: "/ExtraBusiness/edit/:id",
  BUSSINESS_SUPER_DETAILS: "/business/:id/moredetails",
  DEALS: "/deals",
  DEALS_DETAILS: "/deals/:id",
  CATEGORY: "/category",
  CATEGORY_DETAILS: "/category/:id",
  SUB_CATEGORY: "/subcategory/:id",
  SUB_CATEGORY_DETAILS: "/subcategory/:id",
  BLOGS: "/blogs",
  BLOGS_ADD: "/blogs/add",
  BLOGS_UPDATE: "/blogs/update/:id",
  BLOGS_DETAILS: "/blogs/:id",
  FAQ: "/faqs",
  ADVERTISE: "/advertise",
  CONTACT_US: "/contact-us",
  TAGS: "/tags",
  POPULAR_LOCATION: "/popularlocation",
  CATEGORY_LIST: "/category/:id",
  HOME_PAGE_SETTINGS: "/homepagesettings",
  TRACKING_DATA: "/tracking-data",
  REGION: "/region",
  CLAIM_BUSINESS: "/claimbusiness",
  DEAL_ENUM: "/deal-enum",
  EVENT_ENUM: "/event-enum",
  CONFIG: "/configdata",
  ENUM: "/enum",
  PERMISSION: "/permission",
  ADD_PERMISSION: "/permissions/add",
  UPDATE_PERMISSION: "/permissions/edit/:userId",
  HISTORY: "/history",
  HISTORY_ADD: "/history/add",
  HISTORY_UPDATE: "/history/update/:id",
  ABOUT_SURAT: "/aboutsurat",
  ABOUT_SURAT_ADD: "/aboutsurat/add",
  ABOUT_SURAT_UPDATE: "/aboutsurat/update/:id",
  SUB_CATEGORY_RANK: "/subcategoryrank",
  ADSLINK: "/adslink",
  ADSLINK_ADD: "/adslink/add",
  ADSLINK_UPDATE: "/adslink/update/:id",
  BUSINESSBYDATE : "/BusinessByDate",
  CAMPAIGNUSER:"/CampaignUser",
  CAMPAIGN: "/campaingn",
  DELETEBUSINESS :"/DeleteBusiness",
  EXTRABUSINESS : '/ExtraBusiness',
  COUPON:"/CouponDetails",
  COUPON_DETAILS:"/CouponDetails/:id",
  SPIN_REWARD:"/SpinReward",
  SPIN_REWARD_DETAIL:"/SpinReward/:id",
  SEND_BULK_MESSAGES:"/SendBulkMessages",
};
  
export const PERMISSION_ROUTES_LIST: any = {
  HOME: "/home",
  LOGIN: "/login",
  USER: "/users",
  USERS_DETAILS: "/users/:id",
  PINCODE: "/pincode",
  DEFAULT: "/events",
  EVENT: "/events",
  EVENTS_DETAILS: "/events/:id",
  NEWS: "/news",
  NEWS_DETAILS: "/news/:id",
  BUSINESS: "/business",
  USERSPIN: "/UserSpin",
  BUSSINESS_DETAILS: "/business/:id",
  DELETEBUSSINESS_DETAILS: "/DeleteBusiness/:id",
  EXTRABUSSINESS_DETAILS: "/ExtraBusiness/:id",
  COMPAIGN_DETAILS:"/campaign/:id",
  BUSSINESS_CREATE: "/business/create",
  BUSSINESS_EDIT: "/business/edit/:id",
  DELETEBUSINESS_EDIT: "/DeleteBusiness/edit/:id",
  EXTRABUSINESS_EDIT: "/ExtraBusiness/edit/:id",
  BUSSINESS_SUPER_DETAILS: "/business/:id/moredetails",
  DEAL: "/deals",
  DEALS_DETAILS: "/deals/:id",
  CATEGORY: "/category",
  CATEGORY_DETAILS: "/category/:id",
  SUBCATEGORY: "/subcategory/:id",
  SUB_CATEGORY_DETAILS: "/subcategory/:id",
  BLOG: "/blogs",
  BLOGS_ADD: "/blogs/add",
  BLOGS_UPDATE: "/blogs/update/:id",
  BLOGS_DETAILS: "/blogs/:id",
  FAQ: "/faqs",
  ADSWITHUS: "/advertise",
  CONTACUTS: "/contact-us",
  TAG: "/tags",
  POPULARLOCATION: "/popularlocation",
  CATEGORY_LIST: "/category/:id",
  HOMEPAGESETTING: "/homepagesettings",
  TRACKINGDATA: "/tracking-data",
  REGION: "/region",
  CLAIMBUSINESS: "/claimbusiness",
  DEAL_ENUM: "/deal-enum",
  EVENT_ENUM: "/event-enum",
  CONFIGDATA: "/configdata",
  ENUM: "/enum",
  PRIVACY: "/permission",
  ADD_PERMISSION: "/permissions/add",
  UPDATE_PERMISSION: "/permissions/edit/:userId",
  DEALSENUM: "/enum",
  EVENTSENUM: "/enum",
  POPULARLOCATIONENUM: "/enum",
  ADSLINK: "/adslink",
  ADSLINK_ADD: "/adslink/add",
  ADSLINK_UPDATE: "/adslink/update/:id",
  BUSINESSBYDATE : "/BusinessByDate",
  CAMPAIGNUSER:"/CampaignUser",
  CAMPAIGN: "/campaingn",
  COUPON:"/CouponDetails",
  COUPON_DETAILS:"/CouponDetails/:id",
  SPIN_REWARD:"/SpinReward",
  SPIN_REWARD_DETAIL:"/SpinReward/:id",
  SEND_BULK_MESSAGES:"/SendBulkMessages",
};


export const videoExtensions = [
  ".mp4",
  ".avi",
  ".mkv",
  ".mov",
  ".wmv",
  ".flv",
  ".webm",
  ".mpeg",
  ".mpg",
  ".3gp",
  ".m4v",
  ".vob",
  ".ts",
  ".rm",
  ".rmvb",
  ".ogv",
  ".divx",
  ".asf",
  ".m2ts",
  ".swf",
  ".amv",
  ".drc",
  ".f4v",
  ".hevc",
  ".mts",
  ".ogm",
  ".svi",
  ".trp",
  ".yuv",
  ".gif",
];

export const getMimeType = (extension: string): string => {
  const mimeTypes: Record<string, string> = {
    ".mp4": "video/mp4",
    ".avi": "video/x-msvideo",
    ".mkv": "video/x-matroska",
    ".mov": "video/quicktime",
    ".wmv": "video/x-ms-wmv",
    ".flv": "video/x-flv",
    ".webm": "video/webm",
    ".mpeg": "video/mpeg",
    ".mpg": "video/mpeg",
    ".3gp": "video/3gpp",
    ".m4v": "video/x-m4v",
    ".vob": "video/dvd",
    ".ts": "video/mp2t",
    ".rm": "application/vnd.rn-realmedia",
    ".rmvb": "application/vnd.rn-realmedia",
    ".ogv": "video/ogg",
    ".divx": "video/divx",
    ".asf": "video/x-ms-asf",
    ".m2ts": "video/mp2t",
    ".swf": "application/x-shockwave-flash",
    ".amv": "video/x-amv",
    ".drc": "video/x-drc",
    ".f4v": "video/x-f4v",
    ".hevc": "video/hevc",
    ".mts": "video/mp2t",
    ".ogm": "video/ogg",
    ".svi": "video/x-svi",
    ".trp": "video/mp2t",
    ".yuv": "video/x-yuv",
    ".gif": "image/gif",
    ".ogg": "video/ogg",
  };

  return mimeTypes[extension] || "video/mp4"; // Default to MP4
};
