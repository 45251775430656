import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Tooltip,
} from "@mui/material";

import CustomButton from "src/components/CustomButton";
import AdditionalSubCategories from "./AdditionalSubCategories";
import SubCategoryDropDown from "./SubCategoryDropDown";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import PincodeSelectDropDown from "src/components/Common/PincodeSelectDropDown";
import RichTextEditor from "src/components/Common/TextEditor";
import { FieldArray } from "formik";
import deleteIcon from "src/Images/delete.svg";
import addIcon from "src/Images/Plus.svg";
const platforms = [
  { label: "Instagram", value: "INSTAGRAM" },
  { label: "Facebook", value: "FACEBOOK" },
  { label: "LinkedIn", value: "LINKEDIN" },
  { label: "WhatsApp", value: "WHATSAPP" },
  { label: "Snapchat", value: "SNAPCHAT" },
  { label: "Twitter", value: "TWITTER" },
  { label: "Swiggy", value: "SWIGGY" },
  { label: "Zomato", value: "ZOMATO" },
];
interface SocialMedia {
  platform: string;
  url: string;
  handle: string;
  // add other relevant fields
}
function BasicInfo({
  values,
  errors,
  setFieldValue,
  handleChange,
  touched,
  onClose,
  handleNext,
  // showOwnerDetails,
  // setShowOwnerDetails,
  subCategoryData,
  setCategoryId,
  slug,
  isUpdateMode,
}: any) {
  const isBrandNameUpdated = useRef(false);

  useEffect(() => {
    if (!isBrandNameUpdated.current) {
      setFieldValue("brandName", values.businessName);
    }
  }, [values.businessName, setFieldValue]);

  const handleBrandNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    isBrandNameUpdated.current = true;
    setFieldValue("brandName", e.target.value);
  };
  const validateMobileNumber = () => {
    if (values.mobileNumber && values.mobileNumber.length !== 10) {
      return "Mobile number must be exactly 10 digits.";
    }
    return "";
  };

  const validateOwnerMobileNumber = () => {
    if (
      values.owners.mobileNumber &&
      values.owners.mobileNumber.length !== 10
    ) {
      return "Owner's mobile number must be exactly 10 digits.";
    }
    return "";
  };
  const handleNextClick = () => {
    const mobileNumberError = validateMobileNumber();
    const ownerMobileNumberError = validateOwnerMobileNumber();

    if (mobileNumberError) {
      toast.error(mobileNumberError);
    }

    if (ownerMobileNumberError) {
      toast.error(ownerMobileNumberError);
    }

    if (!mobileNumberError && !ownerMobileNumberError) {
      handleNext();
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          fontSize={24}
          fontWeight={600}
          sx={{ alignSelf: "start", display: "flex" }}
          marginX={1}
        >
          Basic Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              label="Business Name"
              fullWidth
              name="businessName"
              value={values.businessName}
              onChange={handleChange}
              error={Boolean(touched.businessName && errors.businessName)}
              helperText={touched.businessName && errors.businessName}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              label="Brand Name"
              fullWidth
              name="brandName"
              value={values.brandName}
              onChange={handleBrandNameChange}
              error={Boolean(touched.brandName && errors.brandName)}
              helperText={touched.brandName && errors.brandName}
            />
          </Grid>
          {isUpdateMode && (
            <Grid item xs={12} sm={4}>
              <TextField
                margin="dense"
                label="Slug"
                fullWidth
                name="slug"
                value={slug || ""}
                disabled
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.isPromoted}
                    onChange={(e) =>
                      setFieldValue("isPromoted", e.target.checked)
                    }
                  />
                }
                label="Is Promoted"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Typography
                gutterBottom
                fontWeight={500}
                alignSelf={"start"}
                fontSize={17}
                marginX={1}
              >
                Primary Sub Category
              </Typography>
              <SubCategoryDropDown
                handleSubCategoryChange={(value) =>
                  setFieldValue("primarySubCategory", value?.value)
                }
                selectedSubCategory={values.primarySubCategory}
                disabledOptions={[values.primarySubCategory]}
              />
              {touched.primarySubCategory && errors.primarySubCategory && (
                <Typography color="error">
                  {errors.primarySubCategory}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <AdditionalSubCategories
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                disabledOption={values.primarySubCategory}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              label="Website"
              fullWidth
              name="website"
              value={values.website}
              onChange={handleChange}
              error={Boolean(touched.website && errors.website)}
              helperText={touched.website && errors.website}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              label="Business Email"
              fullWidth
              name="businessEmail"
              value={values.businessEmail}
              onChange={handleChange}
              error={Boolean(touched.businessEmail && errors.businessEmail)}
              helperText={touched.businessEmail && errors.businessEmail}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              label="Managed By"
              fullWidth
              name="managedBy"
              value={values.managedBy}
              onChange={handleChange}
              error={Boolean(touched.managedBy && errors.managedBy)}
              helperText={touched.managedBy && errors.managedBy}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              label="Mobile Number"
              fullWidth
              name="mobileNumber"
              value={values.mobileNumber}
              onChange={handleChange}
              error={Boolean(touched.mobileNumber && errors.mobileNumber)}
              helperText={touched.mobileNumber && errors.mobileNumber}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Meta Title"
              name="metaTitle"
              value={values?.metaTitle}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={touched.metaTitle && Boolean(errors.metaTitle)}
              helperText={touched.metaTitle && (errors.metaTitle as string)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              type="text-area"
              label="Meta Description"
              name="metaDescription"
              value={values?.metaDescription}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={touched.metaDescription && Boolean(errors.metaDescription)}
              helperText={
                touched.metaDescription && (errors.metaDescription as string)
              }
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              type="text-area"
              label="Focus Keywords"
              name="focus"
              value={values?.focus}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={touched.focus && Boolean(errors.focus)}
              helperText={touched.focus && (errors.focus as string)}
            />
          </Grid>

          <Grid item xs={12}>
            <Box mt={1}>
              <Typography
                variant="h6"
                gutterBottom
                fontSize={24}
                fontWeight={600}
                sx={{ alignSelf: "start", display: "flex" }}
                marginX={1}
              >
                Owners Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    margin="dense"
                    label="First Name"
                    fullWidth
                    name="owners.firstName"
                    value={values.owners.firstName || ""}
                    onChange={handleChange}
                    error={Boolean(
                      touched.owners?.firstName && errors.owners?.firstName
                    )}
                    helperText={
                      touched.owners?.firstName && errors.owners?.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    margin="dense"
                    label="Last Name"
                    fullWidth
                    name="owners.lastName"
                    value={values.owners.lastName || ""}
                    onChange={handleChange}
                    error={Boolean(
                      touched.owners?.lastName && errors.owners?.lastName
                    )}
                    helperText={
                      touched.owners?.lastName && errors.owners?.lastName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    margin="dense"
                    label="Mobile Number"
                    fullWidth
                    name="owners.mobileNumber"
                    value={values.owners.mobileNumber || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && value.length <= 10) {
                        setFieldValue("owners.mobileNumber", value);
                      }
                    }}
                    onBlur={validateOwnerMobileNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Email"
                    fullWidth
                    name="owners.email"
                    value={values.owners.email || ""}
                    onChange={handleChange}
                    error={Boolean(
                      touched.owners?.email && errors.owners?.email
                    )}
                    helperText={touched.owners?.email && errors.owners?.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Designation"
                    fullWidth
                    name="owners.designation"
                    value={values.owners.designation || ""}
                    onChange={handleChange}
                    error={Boolean(
                      touched.owners?.designation &&
                      errors.owners?.designation
                    )}
                    helperText={
                      touched.owners?.designation &&
                      errors.owners?.designation
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>


        {/* Address Details Code */}
        <Box mt={1}>
          <Typography
            variant="h6"
            gutterBottom
            fontSize={24}
            fontWeight={600}
            sx={{ alignSelf: "start", display: "flex" }}
            marginX={1}
          >
            Address Details
          </Typography>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Business Address"
                fullWidth
                name="businessAddress"
                value={values.businessAddress || ""}
                onChange={handleChange}
                error={Boolean(touched.businessAddress && errors.businessAddress)}
                helperText={touched.businessAddress && errors.businessAddress}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <PincodeSelectDropDown
                handlePincodeChange={(data) => {
                  setFieldValue("pinCode", data?.value);
                }}
                selectedPincode={values.pinCode}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Google Map Link"
                fullWidth
                name="googleMapLink"
                value={values.googleMapLink || ""}
                onChange={handleChange}
                error={Boolean(touched.googleMapLink && errors.googleMapLink)}
                helperText={touched.googleMapLink && errors.googleMapLink}
                InputProps={{
                  endAdornment: values.googleMapLink ? (
                    <Box
                      component="a"
                      href={values.googleMapLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ cursor: "pointer", color: "primary.main", ml: 1 }}
                    >
                      View
                    </Box>
                  ) : null,
                }}
              />
            </Grid>
          </Grid>
          <Typography
            variant="h6"
            gutterBottom
            fontSize={24}
            fontWeight={600}
            sx={{ alignSelf: "start", display: "flex" }}
            marginX={1}
          >
            About Your Business
          </Typography>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              <RichTextEditor
                value={values.about || ""}
                onChange={(content) => handleChange({ target: { name: "about", value: content } })}
                placeholder="About Your Business...."
              />
            </Grid>
          </Grid>

          {/* social media handle  */}
          {/* Social Media Section */}
          <Typography
            variant="h6"
            gutterBottom
            fontSize={24}
            fontWeight={600}
            sx={{ alignSelf: "start", display: "flex" }}
            marginX={1}
          >
            Social Media
          </Typography>
          <FieldArray name="socialMedia">
            {({ remove, push }) => (
              <div>
                {values?.socialMedia?.length > 0 &&
                  values?.socialMedia?.map((social: SocialMedia, index: number) => (
                    <Grid
                      container
                      spacing={2}
                      key={index}
                      mb={2}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Grid item xs={12} sm={5}>
                        <TextField
                          select
                          margin="dense"
                          label="Platform"
                          fullWidth
                          name={`socialMedia.${index}.platform`}
                          value={social.platform.toUpperCase() || ""}
                          onChange={handleChange}
                          error={Boolean(
                            touched.socialMedia?.[index]?.platform &&
                            errors.socialMedia?.[index]?.platform
                          )}
                          helperText={
                            touched.socialMedia?.[index]?.platform &&
                            errors.socialMedia?.[index]?.platform
                          }
                        >
                          {platforms.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Handle URL"
                          fullWidth
                          name={`socialMedia.${index}.handle`}
                          value={social.handle || ""}
                          onChange={handleChange}
                          error={Boolean(
                            touched.socialMedia?.[index]?.handle &&
                            errors.socialMedia?.[index]?.handle
                          )}
                          helperText={
                            touched.socialMedia?.[index]?.handle &&
                            errors.socialMedia?.[index]?.handle
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={1}>
                        <CustomButton
                          type="button"
                          variant="outlined"
                          onClick={() => remove(index)}
                          style={{
                            border: "0px",
                          }}
                        >
                          <Tooltip title="Remove">
                            <Box gap={1} display={"flex"} alignItems={"center"}>
                              <img
                                src={deleteIcon}
                                alt="deleteIcon"
                                height={20}
                                width={20}
                              />
                            </Box>
                          </Tooltip>
                        </CustomButton>
                      </Grid>
                    </Grid>
                  ))}

                <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
                  <CustomButton
                    type="button"
                    variant="outlined"
                    onClick={() => push({ platform: "", handle: "" })}
                  >
                    <Box gap={1} display={"flex"} alignItems={"center"}>
                      <img src={addIcon} alt="add" height={20} width={20} />
                      <span>Add Social Media</span>
                    </Box>
                  </CustomButton>
                </Box>
              </div>
            )}
          </FieldArray>
        </Box>

        <Box display="flex" justifyContent="end" mt={2}>
          <Box marginX={2}>
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
          </Box>
          <Box marginX={2}>
            <CustomButton variant="contained" onClick={handleNextClick}>
              Next
            </CustomButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BasicInfo;
