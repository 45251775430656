import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Checkbox, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import CustomButton from "src/components/CustomButton";
import TimingSelect from "src/components/Common/TimingSelect";
import addIcon from "src/Images/Plus.svg";
import styles from "./Business.module.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DynamicForm from "./DynamicForm";
import { defaultTiming, daysOfWeek } from "../../utils/constant";
import { videoExtensions } from "src/constant";
import CloseIcon from "@mui/icons-material/Close";
import DisplayVideo from "src/components/video/DisplayVideo";

function BusinessTiming({
  values,
  errors,
  addNewTimingField,
  timings = [],
  handleTimingChange,
  removeTimingField,
  usedDays,
  services,
  setServices,
  specialServices,
  setSpecialServices,
  overviewJson,
  business,
  categoryId,
  setFieldValue,
  handleBack,
  handleNext,
  setTimings,
  gallaryImages,
  handleDragStart,
  handleDragOver,
  handleBannerImageChange,
  handleDrop,
  handleRemoveBannerImage,
  handleAddBannerImage,
  loading,
  mainImageUrl,
  handleMainImageChange,
  setMainImageUrl,
  setMainImageFile,
  documents,
  handleAddDocuments,
  handleRemoveDocuments,
  handleDocumentsChange,
}: any) {
  const [applySameTime, setApplySameTime] = useState<boolean>(false);


  useEffect(() => {
    if (timings.length === 0) {
      setTimings([
        {
          day: "Monday",
          startTime: "09:00 AM",
          endTime: "06:00 PM",
          others: "",
        },
      ]);
    }
  }, [timings, setTimings]);

  const handleSelectForAll = (index: number) => {
    if (!timings[index]) return;

    const selectedTiming = timings[index];
    setTimings(
      timings.map((timing: any) => ({
        ...timing,
        startTime: selectedTiming.startTime,
        endTime: selectedTiming.endTime,
        others: selectedTiming.others,
      }))
    );
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApplySameTime(e.target.checked);

    if (e.target.checked) {
      const firstTiming = timings.length > 0 ? timings[0] : defaultTiming;

      const newTimings = daysOfWeek.map((day) => ({
        day,
        startTime: firstTiming.startTime || "",
        endTime: firstTiming.endTime || "",
        others: firstTiming.others || "",
      }));

      setTimings(newTimings);
    }
  };

  const isVideoUrl = (url: string | File): boolean => {
    if (typeof url === "string") {
      return videoExtensions.some(ext => url.endsWith(ext));
    } else if (url instanceof File) {
      return url.type.startsWith("video/");
    }
    return false;
  };


  const handleAddField = (setField: React.Dispatch<React.SetStateAction<{ value: string }[]>>, field: { value: string }[]) => {
    setField([...field, { value: "" }]);
  };

  const handleRemoveField = (index: number, setField: React.Dispatch<React.SetStateAction<{ value: string }[]>>, field: { value: string }[]) => {
    setField(field.filter((_, i) => i !== index));
  };

  const handleFieldChange = (index: number, value: string, setField: React.Dispatch<React.SetStateAction<{ value: string }[]>>, field: { value: string }[]) => {
    const updated = [...field];
    updated[index] = { value };
    setField(updated);
  };

  const handleAddSpecialField = (setField: React.Dispatch<React.SetStateAction<{ value: string }[]>>, field: { value: string }[]) => {
    setField([...field, { value: "" }]);
  };

  const handleRemoveSpecialField = (index: number, setField: React.Dispatch<React.SetStateAction<{ value: string }[]>>, field: { value: string }[]) => {
    setField(field.filter((_, i) => i !== index));
  };

  const handleSpecialFieldChange = (index: number, value: string, setField: React.Dispatch<React.SetStateAction<{ value: string }[]>>, field: { value: string }[]) => {
    const updated = [...field];
    updated[index] = { value };
    setField(updated);
  };



  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          fontSize={26}
          fontWeight={600}
          marginX={"40px"}
          className={styles.timing}
        >
          Business Timings
        </Typography>
        <Box className={styles.timingBox}>
          <Box display="flex" justifyContent="flex-end" alignSelf="self-end">
            <CustomButton
              type="button"
              variant="outlined"
              style={{ display: "flex", width: 200 }}
              onClick={addNewTimingField}
            >
              <Box gap={1} display={"flex"} alignItems={"center"}>
                <img src={addIcon} alt="add" height={20} width={20} />
                <span className={styles.addTiming}>Add Timing</span>
              </Box>
            </CustomButton>
          </Box>

          {/* Timings Management */}
          <Box sx={{ marginTop: "20px" }}>
            <Typography variant="h6">Timings:</Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <Checkbox
                checked={applySameTime}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "Apply same time to all days" }}
              />
              <Typography variant="body2" ml={1}>
                Apply same timing for all days
              </Typography>
            </Box>

            {Array.isArray(timings) &&
              timings.map((timing: any, index: number) => (
                <Box key={index} mt={2}>
                  <TimingSelect
                    key={index}
                    timings={timings}
                    onTimingChange={handleTimingChange}
                    onRemove={removeTimingField}
                    availableDays={usedDays}
                    index={index}
                    other={true}
                    isBusiness={true}
                  />
                  <Box sx={{ width: "300px" }} my={1}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => handleSelectForAll(index)}
                    >
                      Apply Timing to All Days
                    </CustomButton>
                  </Box>
                  {errors.timings && (
                    <Typography variant="caption" color="error" mx={1.5}>
                      {errors.timings}
                    </Typography>
                  )}
                </Box>
              ))}
          </Box>
        </Box>

        <Grid container spacing={3} mt={3}>
          {/* Services Column */}
          <Grid item xs={6}>
            <Typography variant="h6">Services:</Typography>
            {services.map((service: { value: string }, index: number) => (
              <Box key={index} display="flex" alignItems="center" mt={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter service"
                  value={service.value}
                  onChange={(e) =>
                    handleFieldChange(index, e.target.value, setServices, services)
                  }
                />
                <IconButton
                  onClick={() => handleRemoveField(index, setServices, services)}
                  color="secondary"
                  sx={{ ml: 1 }}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            ))}

            {/* Add Service Button */}
            <Box mt={2}>
              <CustomButton
                variant="outlined"
                onClick={() => handleAddField(setServices, services)}
              >
                <AddIcon /> Add Service
              </CustomButton>
            </Box>
          </Grid>

          {/* Special Services Column */}
          <Grid item xs={6}>
            <Typography variant="h6">Special Services:</Typography>
            {specialServices.map((specialService: { value: string }, index: number) => (
              <Box key={index} display="flex" alignItems="center" mt={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter special service"
                  value={specialService.value}
                  onChange={(e) =>
                    handleSpecialFieldChange(index, e.target.value, setSpecialServices, specialServices)
                  }
                />
                <IconButton
                  onClick={() => handleRemoveSpecialField(index, setSpecialServices, specialServices)}
                  color="secondary"
                  sx={{ ml: 1 }}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            ))}

            {/* Add Special Service Button */}
            <Box mt={2}>
              <CustomButton
                variant="outlined"
                onClick={() => handleAddSpecialField(setSpecialServices, specialServices)}
              >
                <AddIcon /> Add Special Service
              </CustomButton>
            </Box>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: "20px" }} />
        <Grid container spacing={2} mt={"40px"}>
          {/* Brand Logo Section */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom fontSize={24} fontWeight={600}>
              Brand Logo
            </Typography>
            <Box className={styles.imageContainer}>
              <input
                type="file"
                onChange={handleMainImageChange}
                style={{ display: "none" }}
                id="brand-logo-upload"
              />
              <label htmlFor="brand-logo-upload">
                {mainImageUrl ? (
                  <>
                    <img
                      src={mainImageUrl}
                      alt="Business"
                      height={300}
                      width={200}
                      className={styles.imagePreview}
                    />
                    <IconButton
                      className={styles.closeIcon}
                      onClick={() => {
                        setMainImageUrl(null);
                        setMainImageFile(null);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                ) : (
                  <Typography color="primary">Upload Brand Logo</Typography>
                )}
              </label>
            </Box>
          </Grid>


        </Grid>

        {/* Gallery Images Section */}
        <Divider style={{ margin: "20px 0" }} />
        <Typography variant="h6" gutterBottom fontSize={24} fontWeight={600}>
          Gallery Images / Videos
        </Typography>
        <Grid container spacing={2}>
          {gallaryImages?.map((banner: string | File, index: number) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              // key={index}
              key={banner instanceof File ? banner.name : banner}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index)}
            >
              <input
                type="file"
                multiple
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    handleBannerImageChange(
                      index,
                      Array.from(e.target.files),
                      null,
                      setFieldValue,
                      values?.businessName
                    );
                  }
                }}
                style={{ display: "none" }}
                id={`banner-upload-${index}`}
              />

              <label htmlFor={`banner-upload-${index}`}>
                <Box className={styles.gallerybanner}>
                  {banner ? (
                    isVideoUrl(banner) ? (
                      <DisplayVideo
                        item={banner}
                      />
                    ) : (
                      <img
                        height={200}
                        width={200}
                        // src={banner}
                        src={
                          typeof banner === "string"
                            ? banner
                            : URL.createObjectURL(banner)
                        }
                        alt={`banner-${index}`}
                        className={styles.gallerybannerindividual}
                      />
                    )
                  ) : (
                    <Typography color="primary">Upload Images / Videos</Typography>
                  )}
                </Box>
              </label>

              {/* TextField for Image URL input */}
              <TextField
                label="Or Enter Image URL"
                name={`gallery.image[${index}]`}
                value={values.gallery?.image || ""}
                onChange={(e) => {
                  const imageUrl = e.target.value;
                  handleBannerImageChange(
                    index,
                    null,
                    imageUrl,
                    setFieldValue,
                    values?.businessName
                  );
                }}
                fullWidth
                margin="normal"
              />

              <Box mt={1}>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleRemoveBannerImage(index)}
                  style={{ width: "100%" }}
                >
                  Remove Image / Video
                </CustomButton>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box mt={4}>
          <CustomButton
            variant="outlined" onClick={handleAddBannerImage}>
            Add Gallery Image / Video
          </CustomButton>
        </Box>

        <Divider style={{ margin: "20px 0" }} />
        <Typography variant="h6" gutterBottom fontSize={24} fontWeight={600}>
          Documents
        </Typography>
        <Grid container spacing={2}>
          {documents?.map((document: string | File, index: number) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              // key={index}
              key={document instanceof File ? document.name : document}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index)}
            >
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.pdf"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    handleDocumentsChange(
                      index,
                      Array.from(e.target.files),
                      null,
                      setFieldValue,
                      values?.businessName
                    );
                  }
                }}
                style={{ display: "none" }}
                id={`document-upload-${index}`}
              />

              <label htmlFor={`document-upload-${index}`}>
                <Box className={styles.gallerybanner}>
                  {document ? (
                    <img
                      height={200}
                      width={200}
                      // src={document}
                      src={
                        typeof document === "string"
                          ? document
                          : URL.createObjectURL(document)
                      }
                      alt={`document-${index}`}
                      className={styles.gallerybannerindividual}
                    />
                  ) : (
                    <Typography color="primary">Upload Documents</Typography>
                  )}
                </Box>
              </label>

              <Box mt={1}>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleRemoveDocuments(index)}
                  style={{ width: "100%" }}
                >
                  Remove Documets
                </CustomButton>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box mt={4}>
          <CustomButton
            variant="outlined" onClick={handleAddDocuments}>
            Add Documents
          </CustomButton>
        </Box>

        <Box display="flex" justifyContent="end" mt={2}>
          <Box marginX={2}>
            <CustomButton onClick={handleBack} variant="outlined">
              Back
            </CustomButton>
          </Box>
          <Box marginX={2}>
            <CustomButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Submit
            </CustomButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BusinessTiming;
