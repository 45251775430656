import React, { useEffect, useRef, useState } from "react";
import logo from "src/assests/images/appLogo.png";
import CustomButton from "src/components/CustomButton";
import {
  AddCircleOutline,
  DeleteOutline,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import {
  Box,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import backButton from "../../Images/backButton.svg";
import { ADD_SPIN_REWARD, UPDATE_SPIN_REWARD } from "src/graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { spinRewardPageProps } from "./rewardDetails";
import { GET_COUPON } from "src/graphql/query";

interface winItems {
  couponId: string;
  quantity: string;
}

const SpinRewardModal: React.FC<spinRewardPageProps> = ({
  refetchSpinReward,
  handleClose,
  initialSpinRewardObj = {
    assumptionUser: "",
    shiftValue: "",
    date: "",
    winQuantity: "",
    slug: "",
    winItems: [
      {
        couponId: "",
        quantity: "",
      },
    ],
  },
  spinRewardObj = {
    assumptionUser: "",
    shiftValue: "",
    date: "",
    winQuantity: "",
    slug: "",
    winItems: [
      {
        couponId: "",
        quantity: "",
      },
    ],
    _id: "",
  },
  setSpinRewardObj = () => { },
  isEditSpinReward = false,
}) => {
  const [addSpinReward] = useMutation(ADD_SPIN_REWARD);
  const [updateSpinReward] = useMutation(UPDATE_SPIN_REWARD);
  const [assumptionUser, setAssumptionUser] = useState(
    isEditSpinReward ? "0" : spinRewardObj?.assumptionUser // Set to 0 in edit mode
  );
  const [allCoupons, setAllCoupons] = useState<any[]>([]);
  const [winItems, setWinItems] = useState<winItems[]>([
    {
      couponId: "",
      quantity: "",
    },
  ]);


  // const { data, loading, error } = useQuery(GET_COUPON);
  const { data, loading, error, fetchMore } = useQuery(GET_COUPON, {
    variables: { page: 1, limit: 500 }, // Start with a larger limit
  });
  useEffect(() => {
    const fetchAllCoupons = async () => {
      let page = 1;
      let allCouponsList: any[] = [];
      let hasMore = true;

      while (hasMore) {
        const { data } = await fetchMore({
          variables: { page, limit: 500 },
        });

        if (data?.getCoupons?.data.length) {
          const couponsWithPendingCount = data.getCoupons.data.map((coupon: any) => ({
            ...coupon,
            pendingCount: coupon.coupons?.filter((c: any) => c.couponStatus === "PENDING").length || 0
          }));

          allCouponsList = [...allCouponsList, ...couponsWithPendingCount];
          page++;
        } else {
          hasMore = false;
        }
      }

      setAllCoupons(allCouponsList);
    };

    fetchAllCoupons();
  }, [fetchMore]);

  

  useEffect(() => {
    if (isEditSpinReward) {
      if (spinRewardObj.winItems?.length) {
        // If pricing exists, use it
        const updatedWinItems = spinRewardObj.winItems.map((item) => ({
          couponId: item.couponId || "",
          quantity: "0",
        }));
        setWinItems(updatedWinItems);
      } else {
        setWinItems([
          {
            couponId: "",
            quantity: "",
          },
        ]);
      }
    }
  }, [isEditSpinReward, spinRewardObj]);

  const handleWinItemChange = (
    index: number,
    field: keyof winItems,
    value: string
  ) => {
    const updatedWinItems = [...winItems];
    updatedWinItems[index] = { ...updatedWinItems[index], [field]: value }; // Update the specific field
    setWinItems(updatedWinItems);
  };


  const handleAddWinItem = () => {
    setWinItems([
      ...winItems,
      {
        couponId: "",
        quantity: "",
      },
    ]);
  };

  const handleRemoveWinItem = (index: number) => {
    if (winItems.length > 1) {
      const updatedWinItems = winItems.filter((_, i) => i !== index);
      setWinItems(updatedWinItems);
    }
  };


  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      // Capture the QR code element as a canvas

      // Update the campaign object with the new QR code URL
      const updatedspinRewardObj = {
        ...spinRewardObj,
        assumptionUser: assumptionUser,
        winItems: winItems.map((win) => ({
          couponId: win.couponId,
          quantity: parseFloat(win.quantity), // You might want to handle this accordingly based on your backend structure
        })),
      };

      // Determine whether to create a new campaign or update an existing one
      const { data } = isEditSpinReward
        ? await updateSpinReward({
          variables: {
            _id: updatedspinRewardObj._id,
            input: {
              assumptionUser: Number(updatedspinRewardObj.assumptionUser),
              shiftValue: Number(updatedspinRewardObj.shiftValue),
              date: updatedspinRewardObj.date,
              winQuantity: Number(updatedspinRewardObj.winQuantity),
              slug: updatedspinRewardObj.slug,
              winItems: updatedspinRewardObj.winItems,
            },
          },
        })
        : await addSpinReward({
          variables: {
            input: {
              assumptionUser: Number(updatedspinRewardObj.assumptionUser),
              shiftValue: Number(updatedspinRewardObj.shiftValue),
              date: updatedspinRewardObj.date,
              winQuantity: Number(updatedspinRewardObj.winQuantity),
              slug: updatedspinRewardObj.slug,
              winItems: updatedspinRewardObj.winItems,
            },
          },
        });

      // Refetch campaigns to update the list
      refetchSpinReward();

      // Provide feedback to the user
      if (
        data?.addSpinReward?.statusCode === 200 ||
        data?.updateSpinReward?.statusCode === 200
      ) {
        toast.success(
          data?.addSpinReward?.message ||
          data?.updateSpinReward?.message ||
          "Spin Rewards saved successfully"
        );
        setSpinRewardObj(initialSpinRewardObj);
        handleClose();
      } else {
        toast.error(data?.addSpinReward?.message || "Error saving Spin Rewards");
      }
    } catch (error: any) {
      toast.error(error?.message || "An unexpected error occurred.");
    }
  };

  useEffect(() => {
    if (isEditSpinReward && spinRewardObj) {
      setSpinRewardObj(spinRewardObj);
      setAssumptionUser("0");
    }
  }, [isEditSpinReward]);

  return (
    <Box p={2}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Back">
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Tooltip>
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {isEditSpinReward
            ? "Update Spin Rewards"
            : "Create Spin Rewards"}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        paddingRight={4}
      >
        <Grid item xs={6} paddingRight={4} width="60%" paddingTop="30px">
          <Box width={"100%"}>
            <TextField
              name="Assumption User"
              margin="dense"
              label="Assumption User"
              type="number"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={assumptionUser}
              onChange={(e) => setAssumptionUser(e.target.value)}
            />
          </Box>

          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Shift Value"
              margin="dense"
              label="Enter Shift Value"
              type="number"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={spinRewardObj?.shiftValue || ""}
              onChange={(e) =>
                setSpinRewardObj({ ...spinRewardObj, shiftValue: e.target.value })
              }
            />
          </Box>

          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Win Quantity"
              margin="dense"
              label="Enter Win Quantity"
              type="number"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={spinRewardObj?.winQuantity || ""}
              onChange={(e) =>
                setSpinRewardObj({ ...spinRewardObj, winQuantity: e.target.value })
              }
            />

          </Box>

          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Slug"
              margin="dense"
              label="Enter Slug"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={spinRewardObj?.slug || ""}
              onChange={(e) =>
                setSpinRewardObj({ ...spinRewardObj, slug: e.target.value })
              }
            />
          </Box>

          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Date"
              margin="dense"
              label="Enter Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={spinRewardObj?.date || ""}
              onChange={(e) =>
                setSpinRewardObj({ ...spinRewardObj, date: e.target.value })
              }
            />
          </Box>

          <Box marginTop={2} width={"100%"}>
            <Typography variant="h6" gutterBottom sx={{ py: 3 }}>
              Win Items
            </Typography>
            {winItems.map((winItemsField, index) => (
              <Grid container spacing={2} key={index}>
                <Grid container item xs={12} spacing={2} alignItems="center">
                  <Grid item xs={5}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Coupon</InputLabel>
                      <Select
                        value={winItemsField?.couponId || ""}
                        onChange={(e) => handleWinItemChange(index, "couponId", e.target.value)}
                        label="Coupon"
                      >
                        {loading ? (
                          <MenuItem disabled>
                            <CircularProgress size={20} />
                          </MenuItem>
                        ) : error ? (
                          <MenuItem disabled>Error loading coupons</MenuItem>
                        ) : (
                          allCoupons
                            .filter((coupon: any) => {
                              const selectedCouponIds = new Set(winItems.map((item) => item.couponId));
                              return (
                                coupon._id === winItemsField.couponId || !selectedCouponIds.has(coupon._id)
                              );
                            })
                            .map((coupon: any) => (
                              <MenuItem key={coupon._id} value={coupon._id}>
                                {coupon.name} ({coupon.slug})
                              </MenuItem>
                            ))
                        )}
                      </Select>

                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      name={`winItems.${index}.quantity`}
                      margin="dense"
                      label="Quantity"
                      type="number"
                      fullWidth
                      value={winItemsField?.quantity}
                      onChange={(e) =>
                        handleWinItemChange(index, "quantity", e.target.value)
                      }
                    />
                    <Typography variant="caption" color="textSecondary">
                      Remaining:{" "}
                      {allCoupons.find((coupon) => coupon._id === winItemsField?.couponId)?.pendingCount || 0}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Add">
                      <IconButton color="primary" onClick={handleAddWinItem}>
                        <AddCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    {winItems.length > 1 && (
                      <Tooltip title="Remove">
                        <IconButton
                          color="secondary"
                          onClick={() => handleRemoveWinItem(index)}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Box>
          <Box marginTop={2} width={"100%"}>
            <CustomButton onClick={handleSubmit}>Save Spin Reward</CustomButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default SpinRewardModal;
