import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomButton from "src/components/CustomButton";
import { GET_CAMPAIGNS, GET_CAMPAIGN_BY_ID } from "src/graphql/query";
import {
  Box,
  Checkbox,
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import ActionMenu from "../Business/ActionMenu";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import CampaignModal from "./CampaignModal";
import CustomTable from "src/components/CustomTable";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "src/components/Common/DeleteModel";
import { DELETE_CAMPAIGN } from "src/graphql/mutations";
import { toast } from "react-toastify";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import html2canvas from "html2canvas";
import { QRCodeGenerator } from "./campaignQR";
import logo from "src/assests/images/appLogo.png";
import ReactDOM from "react-dom";

interface Campaign {
  _id: string;
  name: string;
  qrCode: string;
  url: string;
}

const Campaign: React.FC = () => {
  const qrRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );
  const [isEditCampaign, setIsEditCampaign] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [DeleteCampaign] = useMutation(DELETE_CAMPAIGN);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const initialCampaignObj = {
    name: "",
    qrCode: "",
    url: "https://www.hisurat.com/campaign-user",
  };
  const [campaignObj, setCampaignObj] = useState<any>(initialCampaignObj);
  const COLUMNS = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (window) {
              window.open(`/campaign/${row?._id}`, "_blank");
            }
          }}
        >
          <Typography variant="body2" color="textSecondary">
            {row?.name}
          </Typography>
        </Box>
      ),
    },

    {
      headerName: "User",
      field: "count",
    },

    {
      headerName: "Stock Summary",
      field: "stockSummary",
      flex: 1,
      renderCell: ({ row }: any) => {
        const total = row?.stock?.[0]?.total;

        return (
          <Typography variant="body2" color="textPrimary">
            In Stock:{" "}
            {total?.inStockCount > 0 ? total?.inStockCount : "No Stock"} | Used:{" "}
            {total?.usedStockCount > 0 ? total?.usedStockCount : "No Stock"} |
            Remaining:{" "}
            {total?.remainingStockCount > 0
              ? total?.remainingStockCount
              : "No Stock"}
          </Typography>
        );
      },
    },
    { headerName: "Total Price", field: "totalPricing", width: 150 },

    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: Campaign }) => (
        <div>
          {hasPermissionPage("Campaign", "update") && (
            <Tooltip title="Edit">
              <IconButton
                onClick={() => handleEdit(row)}
                aria-label="edit"
                style={{ marginRight: "8px", color: "#00C5B9" }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {hasPermissionPage("Campaign", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => handleDelete(row)}
                aria-label="delete"
                style={{ color: "#00C5B9" }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {row.url && (
            <Tooltip title="Download QR Code">
              <IconButton
                onClick={() => handleDownload(row.url, row.name)}
                aria-label="download"
                style={{ color: "#00C5B9" }}
              >
                <FileDownloadIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];
  const handleDownload = async (qrCodeUrl: string, campaignName: string) => {
    if (!qrCodeUrl) {
      toast.error("QR Code URL is missing");
      return;
    }

    const qrContainer = document.createElement("div");
    document.body.appendChild(qrContainer);

    qrContainer.style.position = "absolute";
    qrContainer.style.left = "-9999px";

    const url = (
      <Box
        sx={{
          p: 2,
          display: "inline-block",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          textAlign: "center",
        }}
      >
        <QRCodeGenerator url={qrCodeUrl} logoSrc={logo} />
        <div
          style={{
            color: "black",
            fontSize: "20px",
            fontWeight: "bold",
            marginTop: "10px",
          }}
        >
          {campaignName}
        </div>
      </Box>
    );
    ReactDOM.render(url, qrContainer);

    setTimeout(async () => {
      const canvas = await html2canvas(qrContainer);
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `${campaignName || "QRCode"}.png`;
      link.click();

      ReactDOM.unmountComponentAtNode(qrContainer);
      document.body.removeChild(qrContainer);
    }, 100);
  };

  const { loading, error, data, refetch } = useQuery(GET_CAMPAIGNS, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
    },
  });

  useEffect(() => {
    if (data?.getCampaigns?.data) {
      const processedData = data.getCampaigns.data.map((campaign: any) => ({
        ...campaign,
        inStock: campaign.stock?.[0]?.total?.inStockCount || 0,
        usedStock: campaign.stock?.[0]?.total?.usedStockCount,
        // remainingStock: campaign.stock?.[0]?.total?.remainingStockCount || 0,
      }));
      setList(processedData);
      setTotal(data.getCampaigns.count);
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term.length > 0 ? term : "");
      refetch({
        search: term,
        page: pagination.page,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  const [getCampaign] = useLazyQuery(GET_CAMPAIGN_BY_ID);

  const handleEdit = async (rowData: any) => {
    try {
      if (!rowData?._id) {
        toast.error("Invalid row data");
        console.error("Invalid row data");
        return;
      }

      const res = await getCampaign({
        variables: {
          _id: rowData._id,
        },
      });

      if (res?.data?.getCampaign?.statusCode === 200) {
        const removeTypename = (obj: any): any => {
          if (Array.isArray(obj)) {
            return obj.map(removeTypename);
          } else if (typeof obj === "object" && obj !== null) {
            return Object.keys(obj).reduce((acc, key) => {
              if (key !== "__typename") {
                acc[key] = removeTypename(obj[key]);
              }
              return acc;
            }, {} as any);
          }
          return obj;
        };

        const campaignData = removeTypename(res?.data?.getCampaign?.data);
        setCampaignObj(campaignData);

        // const { __typename, ...campaignDetails } = res?.data?.getCampaign.data;
        // setCampaignObj(campaignDetails);
        setOpenModal(true);
        setIsEditCampaign(true);
      } else {
        toast.error("Failed to fetch Campaign details");
        console.error("Failed to fetch Campaign details");
      }
    } catch (error: any) {
      toast.error(error?.message);
      console.error(
        "An error occurred while fetching Campaign details:",
        error
      );
    }
  };

  const handleDelete = (rowData: Campaign) => {
    setSelectedCampaign(rowData);
    setOpenConfirmDialog(true);
  };
  const confirmDelete = async () => {
    try {
      if (selectedCampaign && selectedCampaign._id) {
        const res = await DeleteCampaign({
          variables: {
            _id: selectedCampaign._id,
          },
        });
        if (res?.data?.deleteCampaign?.statusCode === 200) {
          toast.success(
            res?.data?.deleteCampaign?.message ||
              "Campaign deleted successfully!"
          );
          refetch();
        } else {
          throw new Error(res?.data?.deleteCampaign?.message);
        }
      }
    } catch (error: any) {
      toast.error(
        error?.message || "An error occurred while deleting the Campaign."
      );
    } finally {
      setOpenConfirmDialog(false);
      setSelectedCampaign(null);
    }
  };
  const handleCloseModal = () => {
    setCampaignObj({
      name: "",
      QrCode: "",
      url: "",
    });
    setOpenModal(false);
    setIsEditCampaign(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
    debouncedRefetch(value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <>
      <Box p={2}>
        {!openModal && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              paddingRight={4}
            >
              <Typography variant="h4" style={{ color: "#00C5B9" }}>
                Campaign QR Code
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Box width={200}>
                  <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: searchTerm && (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {isSuperAdmin && (
                  <ActionMenu
                    isSuperAdmin={isSuperAdmin}
                    setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
                    setOpenDeleteAllModel={setOpenDeleteAllModel}
                  />
                )}
                {hasPermissionPage("Campaign", "add") && (
                  <Box>
                    <CustomButton
                      onClick={() => setOpenModal(true)}
                      variant="contained"
                      className="width: 200px"
                    >
                      Create
                    </CustomButton>
                  </Box>
                )}
                <Box></Box>
              </Box>
            </Box>
            <Box mt={2}>
              <CustomTable
                columns={COLUMNS}
                data={list}
                paginationModel={{
                  page: pagination.page - 1,
                  pageSize: pagination.pageSize,
                }}
                totalCount={total}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
            </Box>
          </>
        )}
        {openModal && (
          <CampaignModal
            handleClose={handleCloseModal}
            refetchCampaign={() => refetch()}
            initialCampaignObj={initialCampaignObj}
            campaignObj={campaignObj}
            setCampaignObj={setCampaignObj}
            isEditCampaign={isEditCampaign}
          />
        )}
        <DeleteModel
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={confirmDelete}
          title="Confirm Deletion"
          message="Are you sure you want to delete this Campaign?"
        />
      </Box>
    </>
  );
};

export default Campaign;
