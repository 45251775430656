import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";
import styles from "./Business.module.css";

import CloseIcon from "@mui/icons-material/Close";
import { videoExtensions } from "src/constant";
import DisplayVideo from "src/components/video/DisplayVideo";

function BusinessImages({
  values,
  setFieldValue,
  gallaryImages,
  handleDragStart,
  handleDragOver,
  handleBannerImageChange,
  handleDrop,
  handleRemoveBannerImage,
  handleAddBannerImage,
  handleBack,
  loading,
  mainImageUrl,
  handleMainImageChange,
  setMainImageUrl,
  setMainImageFile,
}: any) {
   const isVideoUrl = (url: string | File): boolean => {
      if (typeof url === "string") {
        return videoExtensions.some(ext => url.endsWith(ext));
      } else if (url instanceof File) {
        return url.type.startsWith("video/");
      }
      return false;
    };
  return (
    <Card>
      <CardContent>
       
        <Grid container spacing={2}>
          {/* Brand Logo Section */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Brand Logo
            </Typography>
            <Box className={styles.imageContainer}>
              <input
                type="file"
                onChange={handleMainImageChange}
                style={{ display: "none" }}
                id="brand-logo-upload"
              />
              <label htmlFor="brand-logo-upload">
                {mainImageUrl ? (
                  <>
                    <img
                      src={mainImageUrl}
                      alt="Business"
                      height={300}
                      width={200}
                      className={styles.imagePreview}
                    />
                    <IconButton
                      className={styles.closeIcon}
                      onClick={() => {
                        setMainImageUrl(null);
                        setMainImageFile(null);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                ) : (
                  <Typography color="primary">Upload Brand Logo</Typography>
                )}
              </label>
            </Box>
          </Grid>

         
        </Grid>

        {/* Gallery Images Section */}
        <Divider style={{ margin: "16px 0" }} />
        <Typography variant="h6" gutterBottom>
          Gallery Images / Videos
        </Typography>
        <Grid container spacing={2}>
          {gallaryImages?.map((banner:  string | File, index: number) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              // key={index}
              key={banner instanceof File ? banner.name : banner}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index)}
            >
              <input
                type="file"
                multiple
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    handleBannerImageChange(
                      index,
                      Array.from(e.target.files),
                      null,
                      setFieldValue,
                      values?.businessName
                    );
                  }
                }}
                style={{ display: "none" }}
                id={`banner-upload-${index}`}
              />

              <label htmlFor={`banner-upload-${index}`}>
                <Box className={styles.gallerybanner}>
                  {banner ? (
                    isVideoUrl(banner) ? (
                      <DisplayVideo
                            item={banner}
                          />
                    ) : (
                      <img
                        height={200}
                        width={200}
                        // src={banner}
                        src={
                          typeof banner === "string"
                            ? banner
                            : URL.createObjectURL(banner)
                        }
                        alt={`banner-${index}`}
                        className={styles.gallerybannerindividual}
                      />
                    )
                  ) : (
                    <Typography color="primary">Upload Images / Videos</Typography>
                  )}
                </Box>
              </label>

              {/* TextField for Image URL input */}
              <TextField
                label="Or Enter Image URL"
                name={`gallery.image[${index}]`}
                value={values.gallery?.image || ""}
                onChange={(e) => {
                  const imageUrl = e.target.value;
                  handleBannerImageChange(
                    index,
                    null,
                    imageUrl,
                    setFieldValue,
                    values?.businessName
                  );
                }}
                fullWidth
                margin="normal"
              />

              <Box mt={1}>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleRemoveBannerImage(index)}
                  style={{ width: "100%" }}
                >
                  Remove Image / Video
                </CustomButton>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box mt={4}>
          <CustomButton
           variant="outlined" onClick={handleAddBannerImage}>
            Add Gallery Image / Video
          </CustomButton>
        </Box>

        <Box display="flex" justifyContent="end" mt={2}>
          <Box marginX={2}>
            <CustomButton onClick={handleBack} variant="outlined">
              Back
            </CustomButton>
          </Box>
          <Box marginX={2}>
            <CustomButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Submit
            </CustomButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BusinessImages;
