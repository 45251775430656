import * as Yup from "yup";
import { SingleValue } from "react-select";
// Separate validation schemas for each step
export const BasicInfoValidationSchema = Yup.object().shape({
  businessName: Yup.string()
    .required("Business Name is required")
    .min(2, "Business Name must be at least 2 characters")
    .max(100, "Business Name cannot exceed 100 characters"),
  brandName: Yup.string()
    .required("Brand Name is required")
    .min(2, "Brand Name must be at least 2 characters")
    .max(100, "Brand Name cannot exceed 100 characters"),
  primarySubCategory: Yup.string().required("Primary Sub Category is required"),
  businessAddress: Yup.string().required("Business Address is required"),
  pinCode: Yup.string().required("PIN Code is required"),
  googleMapLink: Yup.string().url("Enter a valid Google Maps link").nullable(),
  website: Yup.string().url("Enter a valid website URL").nullable(),
});


export const businessTimingValidationSchema = Yup.object().shape({});

export interface Services {
  value: string;
  __typename?: string;
}
export interface SpecialServices {
  value: string;
  __typename?: string;
}
export interface BusinessTiming {
  day: string;
  startTime: string;
  endTime: string;
  others?: string | null;  // Optional, as it can be null
}
export interface SocialMedia {
  platform?: string | undefined;
  handle?: string;
  __typename?: string;
}

export interface FormValues {
  businessName: string;
  primarySubCategory: string;
  additionalSubCategories: string[];
  brandName: string;
  brandLogo?: string;
  website?: string;
  businessEmail: string;
  documents?: string | File[]; 
  managedBy: string;
  mobileNumber: string;
  owners: {
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: string;
    designation?: string;
  };
  // addresses: Address[];
  businessAddress?: string;
  pinCode?: string;
  googleMapLink?: string;
  gallery: string[];
  timing?: BusinessTiming[];
  bussinessTiming?:BusinessTiming[];
  services: Services[];
  specialServices: SpecialServices[];
  about?: string;
  socialMedia?: SocialMedia[];
  isPromoted?: boolean; 
}

export const options = [
  { value: "FOREVERYONE", label: "FOR EVERYONE" },
  { value: "FORBUSINESS", label: "FOR BUSINESS" },
];

export interface Option {
  label: string;
  value: string | null;
  id: string;
  isSelect?: boolean;
}

export interface DynamicOption {
  id: string;
  type: "checkbox" | "dropdown" | "radio";
  display: string;
  order: number;
  options: Option[];
  isDynamic: boolean;
  isKey: boolean | null;
  value?: string | null;
}

export interface OverviewData {
  dynamicOptions: DynamicOption[];
}

export interface OverviewComponentProps {
  data: OverviewData;
}

export interface BusinessProfileProps {
  businessProfile: {
    businessName: string;
    brandName: string;
    brandLogo: string;
    bgImage: string;
    category: string;
    subCategory: string;
    mobileNumber: string;
    businessEmail: string;
    galleries: { image: string[] }[];
    user: {
      firstName: string;
      lastName: string;
      email?: string;
      role: string;
    };
  };
}

export interface AdditionalSubCategoriesProps {
  values: {
    additionalSubCategories: string[];
    primarySubCategory: "",
  };
  setFieldValue: (field: string, value: any) => void;
  touched: {
    additionalSubCategories?: boolean;
  };
  errors: {
    additionalSubCategories?: string;
  };
}

export interface DynamicFieldOption {
  value: string;
  label: string;
  id: string;
  isSelect?: boolean;
}

export interface Field {
  order: number;
  id: string;
  display: string;
  type: "text-area" | "radio" | "checkbox" | "dropdown";
  options?: DynamicFieldOption[];
  value?: string;
  isDynamic?: boolean;
}

export interface FormComponentProps {
  subCategoryData: Field[];
  tagData: DynamicFieldOption[];
  setFieldValue: (field: string, value: any) => void;
  jsonData?: any;
}

export interface SubCategoryOption {
  value: string;
  label: string;
}
export interface SubCategoryDropDownProps {
  selectedSubCategory: string | null;
  disabledOptions?: any[];
  handleSubCategoryChange: (
    selectedOption: SingleValue<SubCategoryOption>
  ) => void;
}
