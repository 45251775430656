import React, { useState, useEffect } from "react";
import { TextField, Box, Typography, Tooltip } from "@mui/material";
import { useMutation } from "@apollo/client";
import { ADD_CAMPAIGN_USER, UPDATE_CAMPAIGN_USER } from "src/graphql/mutations";
import CustomButton from "src/components/CustomButton";
import { toast } from "react-toastify";
import backButton from "../../Images/backButton.svg";
import * as yup from "yup";

interface CampaignUser {
  name: string;
  phoneNumber: string;
  campaignSlug: string;
  _id?: string;
}

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  phoneNumber: yup
    .string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("Phone number is required"),
  campaignSlug: yup.string().required("Campaign slug is required"),
});

const CampaignUserModal: React.FC<{
  refetchCampaignUsers: () => void;
  handleClose: () => void;
  initialCampaignUserObj: CampaignUser;
  campaignUserObj: CampaignUser;
  setCampaignUserObj: React.Dispatch<React.SetStateAction<CampaignUser>>;
  isEditCampaignUser: boolean;
}> = ({
  refetchCampaignUsers,
  handleClose,
  initialCampaignUserObj,
  campaignUserObj,
  setCampaignUserObj,
  isEditCampaignUser,
}) => {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [localCampaignUser, setLocalCampaignUser] = useState<CampaignUser>(
    initialCampaignUserObj
  );

  const [addCampaignUserMutation] = useMutation(ADD_CAMPAIGN_USER);
  const [updateCampaignUserMutation] = useMutation(UPDATE_CAMPAIGN_USER);

  useEffect(() => {
    if (isEditCampaignUser && campaignUserObj) {
      setLocalCampaignUser(campaignUserObj);
    } else {
      setLocalCampaignUser(initialCampaignUserObj);
    }
  }, [isEditCampaignUser, campaignUserObj, initialCampaignUserObj]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalCampaignUser((prev) => ({ ...prev, [name]: value }));

    validationSchema
      .validateAt(name, { ...localCampaignUser, [name]: value })
      .then(() => {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      })
      .catch((err) => {
        setErrors((prev) => ({ ...prev, [name]: err.message }));
      });

    setTouched((prev) => ({ ...prev, [name]: true }));
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await validationSchema.validate(localCampaignUser, { abortEarly: false });
      setErrors({});
      setTouched({});

      const { _id, ...restData } = localCampaignUser;
      const { data } = isEditCampaignUser
        ? await updateCampaignUserMutation({
            variables: { _id, input: restData },
          })
        : await addCampaignUserMutation({ variables: { input: restData } });

      const apiResponse = data?.updateCampaignUser || data?.addCampaignUser;

      if (apiResponse?.statusCode === 400) {
        let errorField = "phoneNumber";
        if (apiResponse.message.includes("Campaign Slug Not Found")) {
          errorField = "campaignSlug";
        }

        setErrors((prev) => ({
          ...prev,
          [errorField]: apiResponse.message || "Error occurred",
        }));

        setTouched((prev) => ({
          ...prev,
          [errorField]: true,
        }));

        return;
      }

      if (apiResponse?.statusCode === 200) {
        toast.success(
          apiResponse.message || "Campaign User saved successfully!"
        );
        setLocalCampaignUser(initialCampaignUserObj);
        refetchCampaignUsers();
        handleClose();
      }
    } catch (err: any) {
      if (err?.inner) {
        const validationErrors: Record<string, string> = {};
        err.inner.forEach((validationError: any) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
        setTouched({
          name: true,
          phoneNumber: true,
          campaignSlug: true,
        });
      } else {
        toast.error(err?.message || "An unexpected error occurred.");
      }
    }
  };


  return (
    <Box padding={4}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Back">
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Tooltip>
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {isEditCampaignUser ? "Update Campaign User" : "Create Campaign User"}
        </Typography>
      </Box>

      <Box>
        <TextField
          name="name"
          margin="dense"
          label="Name"
          fullWidth
          required
          value={localCampaignUser?.name || ""}
          onChange={handleInputChange}
          error={touched.name && !!errors.name}
          helperText={touched.name && errors.name}
        />
        <TextField
          name="phoneNumber"
          margin="dense"
          label="Phone Number"
          fullWidth
          required
          value={localCampaignUser?.phoneNumber || ""}
          onChange={handleInputChange}
          error={touched.phoneNumber && !!errors.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
        />

        <TextField
          name="campaignSlug"
          margin="dense"
          label="Campaign Slug"
          fullWidth
          required
          value={localCampaignUser?.campaignSlug || ""}
          onChange={handleInputChange}
          error={!!errors.campaignSlug}
          helperText={errors.campaignSlug}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <CustomButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          {isEditCampaignUser ? "Update" : "Create"}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default CampaignUserModal;
