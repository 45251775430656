import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  CircularProgress,
  Tab,
  Tabs,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import CustomTable from "src/components/CustomTable";
import { GET_CAMPAIGN_BY_ID } from "src/graphql/query";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

const CampaignView: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [stockList, setStockList] = React.useState<any[]>([]);
  const [priceList, setPriceList] = React.useState<any[]>([]);
  const [userList, setUserList] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const STOCK_COLUMNS = [
    {
      headerName: "Stock Name",
      field: "name",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row.name || "N/A"}</Typography>
      ),
    },
    {
      headerName: "In Stock Quantity",
      field: "quantity",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row.quantity}</Typography>
      ),
    },
    {
      headerName: "Remaining Stock",
      field: "remainingStock",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.remainingStock}</Typography>
      ),
    },
    {
      headerName: "Used Stock",
      field: "usedStock",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.usedStock}</Typography>
      ),
    },
  ];

  const PRICE_COLUMNS = [
    {
      headerName: "Name",
      field: "name",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.name || "N/A"}</Typography>
      ),
    },
    {
      headerName: "Price",
      field: "price",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", color: "#00C5B9" }}
        >
          ₹{row.price}
        </Typography>
      ),
    },
    {
      headerName: "Description",
      field: "description",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.description}</Typography>
      ),
    },
    {
      headerName: "KM",
      field: "km",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.km}</Typography>
      ),
    },
    {
      headerName: "Image",
      field: "image",
      width: 200,
      renderCell: ({ row }: any) => (
        <img
          src={row?.image}
          alt={row?.name}
          style={{
            width: 50,
            height: 50,
            objectFit: "cover",
            borderRadius: "4px",
          }}
        />
      ),
    },
  ];

  const USER_COLUMNS = [
    {
      headerName: "Name",
      field: "name",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.name || "N/A"}</Typography>
      ),
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.phoneNumber}</Typography>
      ),
    },
    {
      headerName: "Campaign Slug",
      field: "campaignSlug",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.campaignSlug}</Typography>
      ),
    },
    {
      headerName: "Date & Time",
      field: "createdAt",
      width: 150,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
        >
          <Typography variant="body2" color="textPrimary">
            {new Date(row?.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {new Date(row?.createdAt).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </Typography>
        </Box>
      ),
    },
  ];

  const id = window.location.pathname.split("/")[2];
  const { data, loading, error, refetch } = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: { _id: id },
  });

  useEffect(() => {
    if (data?.getCampaign?.data) {
      if (data?.getCampaign?.data?.stock) {
        const flattenedStock = data.getCampaign.data.stock.flatMap(
          (item: any, index: number) =>
            (item.inStock || []).map((stockItem: any, stockIndex: number) => {
              const usedStock =
                item?.usedStock?.find((s: any) => s.name === stockItem.name)
                  ?.quantity || 0;
              const remainingStock =
                item?.remainingStock?.find(
                  (s: any) => s.name === stockItem.name
                )?.quantity || 0;

              return {
                id: `${item._id || `stock-${index}`}-${stockIndex}`,
                stockId: item._id,
                name: stockItem.name,
                quantity: stockItem.quantity,
                totalStock: item.total?.inStockCount || 0,
                remainingStock: remainingStock,
                usedStock: usedStock,
              };
            })
        );
        setStockList(flattenedStock);
      }

      if (data?.getCampaign?.data?.pricing) {
        const pricingData = data?.getCampaign?.data?.pricing.map(
          (item: any, index: number) => ({
            id: `${item.name || `pricing-${index}`}-${index}`,
            name: item?.name,
            price: item?.price,
            description: item?.description,
            km: item?.km,
            image: item?.image,
          })
        );
        setPriceList(pricingData);
      }

      if (data?.getCampaign?.data?.campaignUsers) {
        const userData = data?.getCampaign?.data?.campaignUsers?.map(
          (user: any, index: number) => ({
            id: user?._id || `user-${index}`,
            name: user?.name,
            phoneNumber: user?.phoneNumber,
            campaignSlug: user?.campaignSlug,
            createdAt: user?.createdAt,
          })
        );
        setUserList(userData);
        setTotal(data?.getCampaign?.data?.count);
      }
    }
  }, [data]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">Error: {error.message}</Typography>
      </Box>
    );
  }

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const getRowId = (row: any) => row?.id || `row-${Math.random()}`;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" sx={{ color: "#00C5B9" }}>
          {data?.getCampaign?.data?.name || "No Name Available"}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          mt: 3,
          p: 3,
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "left",
          mx: "auto",
        }}
      >
        {/* Total Users */}
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "#333", mb: 1 }}
        >
          Total Users:
          <span
            style={{
              fontWeight: "bold",
              color: "#00C5B9",
              marginLeft: "8px",
            }}
          >
            {data?.getCampaign?.data?.count ?? 0}
          </span>
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "#333",
            borderBottom: "2px solid #00C5B9",
            display: "inline-block",
            pb: 0.5,
            mb: 2,
          }}
        >
          Stock and Price Details
        </Typography>

        <Typography variant="body1">
          <strong style={{ color: "#333" }}>In Stock : </strong>{" "}
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {data?.getCampaign?.data?.stock?.[0]?.total?.inStockCount || 0}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Used Stock : </strong>{" "}
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {data?.getCampaign?.data?.stock?.[0]?.total?.usedStockCount || 0}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Remaining Stock : </strong>{" "}
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {data?.getCampaign?.data?.stock?.[0]?.total?.remainingStockCount ||
              0}
          </span>
        </Typography>

        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Total Pricing : </strong>{" "}
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {data?.getCampaign?.data?.totalPricing}
          </span>
        </Typography>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Stock Information" />
          <Tab label="Pricing Information" />
          <Tab label="User Information" />
        </Tabs>
      </Box>

      <TabPanel value={activeTab} index={0}>
        <CustomTable
          columns={STOCK_COLUMNS}
          data={stockList}
          getRowId={getRowId}
          paginationModel={pagination}
          totalCount={stockList?.length}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <CustomTable
          columns={PRICE_COLUMNS}
          data={priceList}
          getRowId={getRowId}
          paginationModel={pagination}
          totalCount={priceList?.length}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <CustomTable
          columns={USER_COLUMNS}
          data={userList}
          getRowId={getRowId}
          paginationModel={pagination}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </TabPanel>
    </Box>
  );
};

export default CampaignView;
