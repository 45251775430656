import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import { useQuery, useMutation } from "@apollo/client";
import {
  DELETE_EXTRA_BUSINESS_PROFILE,
  VERIFY_BUSINESS,
  VERIFY_BUSINESS_BY_US,
} from "src/graphql/mutations";
import {
  GET_EXTRA_BUSINESS,
  RESTORE_EXTRA_BUSINESS_PROFILE,
} from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import BusinessModal from "./../Business/BusinessModal";
import { toast } from "react-toastify";
import VerifyEventsDialog from "../Events/VerifyEvents";
import DeleteModel from "src/components/Common/DeleteModel";
import backButton from "../../Images/backButton.svg";
import styles from "./../Business/Business.module.css";
import { ClearIcon } from "@mui/x-date-pickers";
import { createInitialsImage } from "../Region/BusinessCard";
import {
  DELETE_ALL_BUSINESS,
  DELETE_MULTIPLE_BUSINESS,
} from "src/graphql/DeleteMutation";
import { hasPermissionPage } from "src/components/Common/Utils";
interface Business {
  _id: string;
  slug: string;
  businessName: string;
  mobileNumber: string;
  status: string;
  verifiedBy: string;
}

const ExtraBusiness: React.FC = () => {
  const [list, setList] = useState<Business[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(
    null
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedPincodes, setSelectedPincodes] = useState<string[]>([]);
  const [selectedShowWeb, setSelectedShowWeb] = useState<string>("");
  const [selectedVerifyByUser, setSelectedVerifyByUser] = useState<string>("");
  const [selectedClaim, setSelectedClaim] = useState<string>("");
  const [subCategoryList, setSubCategoryList] = useState<string[]>([]);
  const [pinCodeList, setPinCodeList] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reason, setReason] = useState<string>("");
  const [verifyingBusiness, setVerifyingBusiness] = useState<string | null>(
    null
  );
  const [verifyBusinessProfileValue, setVerifyBusinessProfileValue] =
    useState<string>("");
  const [verifyBusinessByUsProfileValue, setVerifyBusinessByUsProfileValue] =
    useState<string>("");
  const [className, setClassName] = useState<string>("");
  const [verifyingByUsBusiness, setVerifyingByUsBusiness] = useState<
    string | null
  >(null);
  const [isBusinessOpen, setIsBusinessPageOpen] = useState<boolean>(false);
  const [businessPageData, setBusinessPageData] = useState<any>();
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [deleteBusinessId, setDeleteBusinessId] = useState<string | null>(null);

  const [deleteExtraBusinessProfile] = useMutation(
    DELETE_EXTRA_BUSINESS_PROFILE
  );
  const [restorextraBusinessProfile] = useMutation(
    RESTORE_EXTRA_BUSINESS_PROFILE
  );
  const [verifyBusinessProfile] = useMutation(VERIFY_BUSINESS);
  const [VerifyBusinessProfileByUs] = useMutation(VERIFY_BUSINESS_BY_US);
  const [DeleteMultipleBusinessProfiles] = useMutation(
    DELETE_MULTIPLE_BUSINESS
  );
  const [DeleteAllBusinessProfiles] = useMutation(DELETE_ALL_BUSINESS);

  const { loading, error, data, refetch } = useQuery(GET_EXTRA_BUSINESS, {
    variables: {
      search: searchTerm,
      page: pagination.page + 1,
      limit: pagination.pageSize,
      primaryCategory: selectedCategories,
      showOnWebFilter: selectedShowWeb,
      verifiedByUsFilter: selectedVerifyByUser,
      claimStatusFilter: selectedClaim,
      pinCode: selectedPincodes,
    },
  });

  useEffect(() => {
    if (data?.getExtraBusinessProfiles?.data) {
      setList(data?.getExtraBusinessProfiles?.data);
      setTotal(data?.getExtraBusinessProfiles?.count);
      setSubCategoryList(
        data?.getExtraBusinessProfiles?.subCategory?.map((item: any) => {
          return {
            label: item?.subCategoryName,
            value: item?.subCategoryName,
          };
        })
      );
      setPinCodeList(
        data?.getExtraBusinessProfiles?.pinCode?.map((item: any) => {
          return {
            label: item?.areaName,
            value: item?.areaName,
          };
        })
      );
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
        primaryCategory: selectedCategories,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleBusinessProfiles({
        variables: { _id: selectedRows },
      });
      if (res?.data?.deleteMultipleBusinessProfiles?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleBusinessProfiles?.message);

        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows?.filter((id) => !selectedRows?.includes(id))
        );
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleBusinessProfiles?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllBusinessProfiles();
      if (res?.data?.deleteAllBusinessProfiles?.statusCode === 200) {
        toast.success(res?.data?.deleteAllBusinessProfiles?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllBusinessProfiles?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };

  const handleRefetch = () => {
    refetch({
      variables: {
        page: pagination.page + 1,
        limit: pagination.pageSize,
      },
    });
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleEdit = async (rowData: any) => {
    if (rowData?.slug) {
      window.open(`/ExtraBusiness/edit/${rowData?.slug}`, "_blank");
    } else {
      console.error("Invalid row data. Unable to open edit page.");
    }
  };

  const handleDelete = async () => {
    if (!deleteBusinessId) return;
    try {
      const res = await deleteExtraBusinessProfile({
        variables: { id: deleteBusinessId },
      });
      if (res?.data?.deleteExtraBusinessProfile?.statusCode === 200) {
        toast.success(res?.data?.deleteExtraBusinessProfile?.message);
      } else {
        throw new Error(res?.data?.deleteExtraBusinessProfile?.message);
      }
      handleRefetch();
      setOpenDeleteModel(false);
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error deleting business:", error);
    }
  };

  const handleVerify = async (businessId: string, status: string) => {
    try {
      const res = await verifyBusinessProfile({
        variables: {
          input: {
            _id: businessId,
            status,
            reason,
          },
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error) => error.message)
            .join(", ")}`
        );
      }

      handleRefetch();
      setReason("");
      setVerifyBusinessProfileValue("");
      toast.success(res?.data?.verifyBusinessProfile?.message);
      setVerifyingBusiness(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };

  const handleVerifyByUs = async (businessId: string, status: string) => {
    try {
      const res = await VerifyBusinessProfileByUs({
        variables: {
          input: {
            _id: businessId,
            isstatusVerified: status,
            classOfbusiness: className,
            reason,
          },
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error) => error.message)
            .join(", ")}`
        );
      }

      handleRefetch();
      setReason("");
      setClassName("");
      setVerifyBusinessByUsProfileValue("");
      toast.success(res?.data?.verifyBusinessProfileByUs?.message);
      setVerifyingByUsBusiness(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handleCloseBusinessPage = () => {
    setIsBusinessPageOpen(false);
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteBusinessId(null);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  const COLUMNS = [
    {
      headerName: "Logo",
      field: "businessName",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (window) {
              window.open(`/ExtraBusiness/${row?.slug}`, "_blank");
            }
          }}
        >
          {row.brandLogo ? (
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <img
                src={row?.bgImage ?? createInitialsImage(row?.businessName)}
                alt={row.bgImage}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  margin: 4,
                }}
              />
              <Typography variant="body2" color="textSecondary">
                {row?.businessName}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body2" color="textSecondary" mt={2}>
              {row?.businessName}
            </Typography>
          )}
        </Box>
      ),
    },

    {
      headerName: "Primary & additional category",
      field: "primarySubcategoryName",
      width: 250,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
        >
          <Typography variant="body2" color="textPrimary">
            {row?.primarySubcategoryName || "---"}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {row?.additionalSubCategoriesName || "---"}
          </Typography>
        </Box>
      ),
    },
    {
      headerName: "Contact Info",
      field: "mobileNumber",
      width: 250,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
        >
          <Typography variant="body2" color="textPrimary">
            {row?.mobileNumber || "---"}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {row?.businessEmail || "---"}
          </Typography>
        </Box>
      ),
    },

    {
      headerName: "Actions",
      width: 150,
      renderCell: ({ row }: { row: Business }) => (
        <Box display="flex" alignItems="center">
          {hasPermissionPage("business", "update") && (
            <Tooltip title="Edit">
              <IconButton
                onClick={() => handleEdit(row)}
                aria-label="edit"
                className={styles.button}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {hasPermissionPage("business", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setDeleteBusinessId(row._id);
                  setOpenDeleteModel(true);
                }}
                aria-label="delete"
                className={styles.button}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Restore">
            <IconButton
              onClick={async () => {
                try {
                  const { data } = await restorextraBusinessProfile({
                    variables: { id: row._id },
                  });

                  if (data?.restoreExtraBusinessProfile?.message) {
                    toast.success(data.restoreExtraBusinessProfile.message);

                    refetch();
                  }
                } catch (error) {
                  toast.error("Failed to restore business profile!");
                  console.error("Restore Error:", error);
                }
              }}
              aria-label="restore"
              className={styles.button}
            >
              <RestoreIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <>
      {openModal && (
        <BusinessModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          business={selectedBusiness}
        />
      )}

      {!isBusinessOpen && !openModal && (
        <Box p={2} overflow={"hidden"}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            paddingRight={4}
          >
            <Typography variant="h4" className={styles.primaryColor}>
              Exra Business
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <Box width={200}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {hasPermissionPage("business", "add") && (
                <Box>
                  <CustomButton
                    onClick={() => {
                      window.open(`/business/create`, "_blank");
                    }}
                    variant="contained"
                  >
                    Create
                  </CustomButton>
                </Box>
              )}
            </Box>
          </Box>
          <Divider />
          <CustomTable
            columns={COLUMNS}
            data={list}
            paginationModel={{
              page: Number(pagination.page),
              pageSize: Number(pagination.pageSize),
            }}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            totalCount={total}
          />
        </Box>
      )}

      {isBusinessOpen && businessPageData && (
        <Box p={3} sx={{ maxWidth: 1200, mx: "auto", mt: "40px" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img
              src={backButton}
              alt="backButton"
              height={25}
              width={25}
              style={{ marginRight: "12px", cursor: "pointer" }}
              onClick={handleCloseBusinessPage}
            />
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#00C5B9" }}
              marginTop={2}
            >
              Business Profile
            </Typography>
          </Box>
          <Box mt={3}>
            <CustomButton
              onClick={handleCloseBusinessPage}
              color="primary"
              variant="outlined"
            >
              Close
            </CustomButton>
          </Box>
        </Box>
      )}

      <VerifyEventsDialog
        label="Business By us"
        className={className}
        setClassName={setClassName}
        handleVerify={handleVerifyByUs}
        setVerifyingData={setVerifyingByUsBusiness}
        verifyingData={verifyingByUsBusiness}
        verifyDataValue={verifyBusinessByUsProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyBusinessByUsProfileValue}
        setReason={setReason}
      />
      <VerifyEventsDialog
        label="Business Web"
        handleVerify={handleVerify}
        setVerifyingData={setVerifyingBusiness}
        verifyingData={verifyingBusiness}
        verifyDataValue={verifyBusinessProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyBusinessProfileValue}
        setReason={setReason}
      />

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this business?"
        />
      )}
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } business?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all business?"
        />
      )}
    </>
  );
};

export default ExtraBusiness;
