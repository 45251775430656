import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  TablePagination,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_DEAL, GET_DEALS, GET_DEALS_BY_BUSSINESS_ID } from "src/graphql/query";
import { DELETE_DEAL } from "src/graphql/mutations";
import {
  DELETE_All_DEALS,
  DELETE_MULTIPLE_DEALS,
} from "src/graphql/DeleteMutation";
import { toast } from "react-toastify";
import Loader from "src/components/Loader";
import { hasPermissionPage } from "src/components/Common/Utils";
import CreateDeal from "./CreateDeal";
import { DealCategory, FilterDeals } from "./dealCategory";
import { ROUTES } from "src/constant";
import { useNavigate } from "react-router";
import { ClearIcon } from "@mui/x-date-pickers";
import DealViewPage from "./DealViewPage";

interface DealDetailPageProps {
  dealData: any;
  onClose: () => void;
}

const DealDetailPage: React.FC<DealDetailPageProps> = ({
  dealData,
  onClose,
}) => {

  const [deals, setDeals] = useState<any[]>([]);
  const [deleteDealId, setDeleteDealId] = useState<string | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [selectedCategories, setSelectedCategories] = useState<DealCategory[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterDeals[]>([]);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [GetDealsByBusinessId] = useLazyQuery(GET_DEALS_BY_BUSSINESS_ID);
  const [GetDeal] = useLazyQuery(GET_DEAL);
  const [deleteDeal] = useMutation(DELETE_DEAL);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedDeal, setSelectedDeal] = useState<any | null>(null);

  const { loading, error, data, refetch } = useQuery(GET_DEALS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
      selectedCategory: selectedCategories.length
        ? selectedCategories
        : undefined,
      filter: filterOptions.length ? filterOptions : undefined,
      showAllDeal:true,
    },
  });

  const businessProfileId = dealData?.[0]?.businessProfileId;
  const { data: businessidata } = useQuery(GET_DEALS_BY_BUSSINESS_ID, {
    variables: { businessProfileId },
    skip: !businessProfileId,
  });

  const businessProfile = businessidata?.getDealsByBusinessId?.businessProfileExists;

  useEffect(() => {
    setDeals(dealData);
  }, [dealData]);


  const handleEdit = async (dealId: string) => {
    try {
      const response = await GetDeal({ variables: { _id: dealId } });
      setDeals(response.data?.getDeal?.data);
      setOpenModal(true);
    } catch (err) {
      console.error("Error fetching deal data:", err);
    }
  };

  const handleView = (deal: any) => {
    setSelectedDeal(deal);
  };

  const handleDelete = async () => {
    if (!deleteDealId) return;
    try {
      await deleteDeal({ variables: { _id: deleteDealId } });
      toast.success("Deal deleted successfully");
      navigate(ROUTES.DEALS)
      window.location.reload();
    } catch (error) {
      toast.error("Failed to delete deal");
      console.error(error);
    } finally {
      setOpenDeleteModal(false);
      setDeleteDealId(null);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };


  const dealsArray = Array.isArray(deals) ? deals : [];
  const filteredDeals = dealsArray.filter((deal) =>
    deal?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const paginatedDeals = filteredDeals.slice(
    pagination.page * pagination.pageSize,
    pagination.page * pagination.pageSize + pagination.pageSize
  );

  const handlePageChange = (event: unknown, newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({ page: 0, pageSize: parseInt(event.target.value, 10) });
  };

  const columns = [
    { headerName: "Index", field: "index", width: 10 },
    {
      headerName: "Title",
      field: "title",
      flex: 1,
      renderCell: (deal: any) => (
        <Typography
          color="textSecondary"
          sx={{ cursor: "pointer" }}
          onClick={() => handleView(deal)}
        >
          {deal.title}
        </Typography>
      )
    },
    { headerName: "Category", field: "category", flex: 1 },
    { headerName: "Area", field: "area", flex: 1 },
    {
      headerName: "Current Price",
      field: "currentPrice",
      flex: 1,
      renderCell: (deal: any) => (
        <Typography>
          {deal?.discountType === "PERCENTAGE" && (
            <>₹{deal?.currentPrice || "N/A"} ({deal?.discountPercentage}%)</>
          ) || "-"} 
        </Typography>
      ),
    },
    {
      headerName: "Offer Price",
      field: "discountPrice",
      flex: 1,
      renderCell: (deal: any) => <Typography>₹{deal?.discountPrice}</Typography>,
    },
    { headerName: "Offer Type", field: "discountType", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (row: any) => (
        <Box>
          {hasPermissionPage("Deals", "update") && (
            <Tooltip title="Edit">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(row?._id);
                  // Handle edit functionality here
                }}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {hasPermissionPage("Deals", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setDeleteDealId(row._id);
                  setOpenDeleteModal(true);
                }}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  if (selectedDeal) {
    return <DealViewPage dealData={selectedDeal} businessProfile={businessProfile} onClose={() => setSelectedDeal(null)} />;
  }

  // if (loading) return <Loader />;
  // if (error) return <Typography color="error">Error loading deals</Typography>;
  return openModal ? (
    <CreateDeal
      reload={() => refetch()}
      dealData={deals ? deals : dealData}
      isDuplicating={isDuplicating}
      onClose={() => {
        setOpenModal(false);
        setIsDuplicating(false);
        setDeals(dealData);
      }}
    />
  ) : (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1500px",
        margin: "auto",
        mt: 5,
        p: 3,
        borderRadius: 2,
        bgcolor: "background.paper",
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color="primary" fontWeight="bold"  >
          {businessidata?.getDealsByBusinessId?.businessProfileExists?.businessName || "Business"} Deals
        </Typography>
        <Box sx={{
          display: "flex",
          justifyContent: 'center',
          alignContent: 'center'
        }}>
          <TextField
            label="Search Deals"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchTerm("")}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <IconButton onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.field} style={{ fontWeight: 'bold' }}>
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDeals.length > 0 ? (
              paginatedDeals.map((deal, index) => (
                <TableRow key={deal._id}>
                  {columns.map((column) => (
                    <TableCell key={`${deal._id}-${column.field}`}>
                      {column.field === "index"
                        ? pagination.page * pagination.pageSize + index + 1
                        : column.renderCell
                          ? column.renderCell(deal)
                          : deal[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {dealsArray.length === 0 ? "No deals available" : "No matching deals found"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={filteredDeals.length}
        rowsPerPage={pagination.pageSize}
        page={pagination.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePageSizeChange}
      />

      {/* Delete Confirmation Modal */}
      {openDeleteModal && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1300,
          }}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              padding: 3,
              borderRadius: 2,
              minWidth: 300,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Confirm Delete
            </Typography>
            <Typography variant="body1" gutterBottom>
              Are you sure you want to delete this deal?
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                onClick={() => setOpenDeleteModal(false)}
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                color="error"
                variant="contained"
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DealDetailPage;