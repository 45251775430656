import React, { useEffect, useRef, useState } from "react";
import logo from "src/assests/images/appLogo.png";
import CustomButton from "src/components/CustomButton";
import {
  AddCircleOutline,
  DeleteOutline,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { QRCodeGenerator } from "./campaignQR";
import {
  Box,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import backButton from "../../Images/backButton.svg";
import html2canvas from "html2canvas";
import { campaignPageProps } from "./campaign";
import { ADD_CAMPAIGN, UPDATE_CAMPAIGN } from "src/graphql/mutations";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { uploadImage } from "src/components/Common/Utils";
import { useNavigate } from "react-router";
export interface StockItem {
  name: string;
  inStock: number;
  usedStock: number;
  remainingStock: number;
}

export interface StockData {
  inStock: StockItem[];
  remainingStock: StockItem[];
  usedStock: StockItem[];
  total: {
    inStockCount: number;
    usedStockCount: number;
    remainingStockCount: number;
  };
}

function removeQueryParams(url: any, params: any) {
  params.forEach((param: any) => {
    let regex = new RegExp(`[?&]${param}=[^&]*`, "g");
    url = url.replace(regex, "");
  });
  url = url.replace(/[?&]$/, "");
  return url;
}
interface Price {
  name: string;
  price: string; // You can keep it as a string until you need to convert it to number
  km: string;
  description: string;
  image: File | null;
  imagePreview: string;
}

const CampaignModal: React.FC<campaignPageProps> = ({
  refetchCampaign,
  handleClose,
  initialCampaignObj = {
    name: "",
    qrCode: "",
    utmCampaign: "",
    utmSource: "",
    url: "",
    slug: "",
    pricing: [
      {
        name: "",
        price: "",
        km: "",
        description: "",
        image: "",
      },
    ],
    stock: [
      {
        inStock: [],
        usedStock: [],
        remainingStock: [],
        total: {
          inStockCount: 0,
          usedStockCount: 0,
          remainingStockCount: 0,
        },
      },
    ],
  },
  campaignObj = {
    name: "",
    qrCode: "",
    utmCampaign: "",
    utmSource: "",
    url: "",
    slug: "",
    _id: "",
    pricing: [
      {
        name: "",
        price: "",
        km: "",
        description: "",
        image: "",
      },
    ],
    stock: [
      {
        inStock: [],
        usedStock: [],
        remainingStock: [],
        total: {
          inStockCount: 0,
          usedStockCount: 0,
          remainingStockCount: 0,
        },
      },
    ],
  },
  setCampaignObj = () => {},
  isEditCampaign = false,
}) => {
  const [campaignValue, setCampaignValue] = useState("");
  const [qrUrl, setQrUrl] = useState<string | null | undefined>("");
  const [urlError, setUrlError] = useState<string | null>(null);
  const [addCampaign] = useMutation(ADD_CAMPAIGN);
  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN);
  const [stockItems, setStockItems] = useState<StockItem[]>([
    { name: "", inStock: 0, usedStock: 0, remainingStock: 0 },
  ]);
  const navigate = useNavigate();

  const qrRef = useRef(null);
  const [pricing, setPricing] = useState<Price[]>([
    {
      name: "",
      price: "",
      description: "",
      km: "",
      image: null,
      imagePreview: "",
    },
  ]);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [existingImageUrl, setExistingImageUrl] = useState<string | null>(
    campaignObj?.qrCode || null
  );
  const handleStockItemChange = (
    index: number,
    field: keyof StockItem,
    value: string | number
  ) => {
    const newStockItems = [...stockItems];

    // Convert value to number if required
    const numericValue =
      field === "inStock" || field === "usedStock" || field === "remainingStock"
        ? Number(value)
        : (value as any);

    // Prevent negative values
    if (numericValue < 0) {
      toast.error(`${field} cannot be negative`);
      return;
    }

    const tempItem = { ...newStockItems[index] } as any;
    tempItem[field] = numericValue;

    if (field === "inStock") {
      if (tempItem?.remainingStock > numericValue) {
        toast.error("In-stock quantity cannot be less than remaining stock");
        return;
      }
      const newUsedStock = numericValue - tempItem?.remainingStock;
      if (newUsedStock < 0) {
        toast.error("Invalid combination: Would result in negative used stock");
        return;
      }
      tempItem.usedStock = newUsedStock;
    } else if (field === "remainingStock") {
      if (numericValue > tempItem?.inStock) {
        toast.error("Remaining stock cannot be greater than in-stock quantity");
        return;
      }
      const newUsedStock = tempItem?.inStock - numericValue;
      if (newUsedStock < 0) {
        toast.error("Invalid combination: Would result in negative used stock");
        return;
      }
      tempItem.usedStock = newUsedStock;
    } else if (field === "usedStock") {
      if (numericValue > tempItem?.inStock) {
        toast.error("Used stock cannot be greater than in-stock quantity");
        return;
      }
      const newRemainingStock = tempItem?.inStock - numericValue;
      if (newRemainingStock < 0) {
        toast.error(
          "Invalid combination: Would result in negative remaining stock"
        );
        return;
      }
      tempItem.remainingStock = newRemainingStock;
    }
    newStockItems[index] = tempItem;

    setStockItems(newStockItems);

    // Format stock data for server according to StockInput type
    const stockData = {
      inStock: newStockItems.map((item) => ({
        name: item.name,
        quantity: item.inStock,
      })),
      usedStock: newStockItems.map((item) => ({
        name: item.name,
        quantity: item.usedStock,
      })),
      total: {
        inStockCount: newStockItems.reduce(
          (sum, item) => sum + item.inStock,
          0
        ),
        remainingStockCount: newStockItems.reduce(
          (sum, item) => sum + (item.inStock - item.usedStock),
          0
        ),
        usedStockCount: newStockItems.reduce(
          (sum, item) => sum + item.usedStock,
          0
        ),
      },
    };

    setCampaignObj({
      ...campaignObj,
      stock: [stockData],
    });
  };

  useEffect(() => {
    if (isEditCampaign && campaignObj?.pricing) {
      // Initialize pricing state from campaignObj
      const updatedPricing = campaignObj.pricing.map((priceItem) => ({
        name: priceItem.name || "",
        price: priceItem.price || "",
        km: priceItem.km || "",
        description: priceItem.description || "",
        image: null, // If no image, set as null
        imagePreview: priceItem.image || "", // Use the image URL if available
      }));
      setPricing(updatedPricing);
    }
  }, [isEditCampaign, campaignObj]);

  useEffect(() => {
    if (isEditCampaign && campaignObj?.pricing) {
      // Initialize pricing state from campaignObj
      const updatedPricing = campaignObj.pricing.map((priceItem) => ({
        name: priceItem.name || "",
        price: priceItem.price || "",
        km: priceItem.km || "",
        description: priceItem.description || "",
        image: null,
        imagePreview: priceItem.image || "",
      }));
      setPricing(updatedPricing);
    }
  }, [isEditCampaign, campaignObj]);

  const handlePricingChange = (
    index: number,
    field: keyof Price,
    value: string | File | null
  ) => {
    const updatedPricing = [...pricing];

    // Handle image field separately
    if (field === "image" && value instanceof File) {
      updatedPricing[index][field] = value; // Only set the image as File
    } else if (field === "imagePreview" && typeof value === "string") {
      updatedPricing[index][field] = value; // Handle imagePreview as string
    } else if (
      field !== "image" &&
      field !== "imagePreview" &&
      typeof value === "string"
    ) {
      updatedPricing[index][field] = value; // Handle other fields (name, price, etc.) as string
    }

    setPricing(updatedPricing);
  };
  useEffect(() => {
    if (isEditCampaign && campaignObj?.pricing) {
      // Initialize pricing state from campaignObj
      const updatedPricing = campaignObj.pricing.map((priceItem) => ({
        name: priceItem.name || "",
        price: priceItem.price || "",
        km: priceItem.km || "",
        description: priceItem.description || "",
        image: null, // If no image, set as null
        imagePreview: priceItem.image || "", // Use the image URL if available
      }));
      setPricing(updatedPricing);
    }
  }, [isEditCampaign, campaignObj]);

  const handleAddPrice = () => {
    setPricing([
      ...pricing,
      {
        name: "",
        price: "",
        km: "",
        description: "",
        image: null,
        imagePreview: "",
      },
    ]);
  };

  const handleRemovePrice = (index: number) => {
    if (pricing.length > 1) {
      const updatedPricing = pricing.filter((_, i) => i !== index);
      setPricing(updatedPricing);
    }
  };

  const handleImageChange = async (event: any, index: number) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Upload image using uploadImage API
        const res = await uploadImage(file, "pricing", `price_${index}`);
        if (res?.uploadImage?.url) {
          const newPricing = [...pricing];
          newPricing[index].image = file;
          newPricing[index].imagePreview = res.uploadImage.url; // Store uploaded URL
          setPricing(newPricing);
        } else {
          toast.error("Image upload failed");
        }
      } catch (error) {
        toast.error("Error uploading image");
      }
    }
  };

  useEffect(() => {
    if (isEditCampaign) {
      if (campaignObj.pricing?.length) {
        // If pricing exists, use it
        const updatedPricing = campaignObj.pricing.map((priceItem) => ({
          name: priceItem.name || "",
          price: priceItem.price || "",
          km: priceItem.km || "",
          description: priceItem.description || "",
          image: null,
          imagePreview: priceItem.image || "",
        }));
        setPricing(updatedPricing);
      } else {
        setPricing([
          {
            name: "",
            price: "",
            km: "",
            description: "",
            image: null,
            imagePreview: "",
          },
        ]);
      }
    }
  }, [isEditCampaign, campaignObj]);

  const saveQRCode = async (e: any) => {
    e.preventDefault();

    if (!qrRef.current) {
      toast.error("QR Code reference is not available");
      return;
    }

    try {
      // Capture the QR code element as a canvas
      const canvas = await html2canvas(qrRef.current);

      // Convert the canvas to a Blob
      const blob = await new Promise<Blob | null>((resolve) => {
        canvas.toBlob((blob) => resolve(blob), "image/png");
      });

      if (!blob) {
        toast.error("Failed to generate QR Code image");
        return;
      }

      // Create a File object from the Blob
      const fileName = (campaignObj.name || "QRCode").replace(/\s+/g, "");
      const file = new File([blob], `${fileName}.png`, {
        type: "image/png",
      });

      // Upload the image using your existing uploadImage function
      const res = await uploadImage(file, "campaign", campaignObj?.name);
      const imageUrl = res.uploadImage.url;

      if (!imageUrl) {
        toast.error("Failed to upload QR Code");
        return;
      }

      // Update the campaign object with the new QR code URL
      const updatedCampaignObj = {
        ...campaignObj,
        qrCode: imageUrl,
        pricing: pricing.map((price) => ({
          name: price.name,
          price: parseFloat(price.price),
          km: price.km,
          description: price.description,
          image: price.imagePreview || null, // You might want to handle this accordingly based on your backend structure
        })),
      };

      // Determine whether to create a new campaign or update an existing one
      const { data } = isEditCampaign
        ? await updateCampaign({
            variables: {
              _id: updatedCampaignObj._id,
              input: {
                name: updatedCampaignObj.name,
                qrCode: updatedCampaignObj.qrCode,
                url: updatedCampaignObj.url,
                utmCampaign: updatedCampaignObj.utmCampaign,
                utmSource: updatedCampaignObj.utmSource,
                slug: updatedCampaignObj.slug,
                pricing: updatedCampaignObj.pricing,
                stock: updatedCampaignObj.stock,
              },
            },
          })
        : await addCampaign({
            variables: {
              input: {
                name: updatedCampaignObj.name,
                qrCode: updatedCampaignObj.qrCode,
                url: updatedCampaignObj.url,
                utmCampaign: updatedCampaignObj.utmCampaign,
                utmSource: updatedCampaignObj.utmSource,
                slug: updatedCampaignObj.slug,
                pricing: updatedCampaignObj.pricing,
                stock: updatedCampaignObj.stock,
              },
            },
          });

      // Refetch campaigns to update the list
      refetchCampaign();

      // Provide feedback to the user
      if (
        data?.addCampaign?.statusCode === 200 ||
        data?.updateCampaign?.statusCode === 200
      ) {
        toast.success(
          data?.addCampaign?.message ||
            data?.updateCampaign?.message ||
            "QR Code saved successfully"
        );
        setCampaignObj(initialCampaignObj);
        handleClose();
      } else {
        toast.error(data?.addCampaign?.message || "Error saving QR Code");
      }
    } catch (error: any) {
      toast.error(error?.message || "An unexpected error occurred.");
    }
  };

  useEffect(() => {
    if (isEditCampaign && campaignObj) {
      setCampaignObj(campaignObj);
      setCampaignValue(campaignObj?.name);
      setQrUrl(campaignObj?.url);

      if (campaignObj.stock?.[0]?.inStock) {
        const inStockData = campaignObj.stock[0].inStock;
        const usedStockData = campaignObj.stock[0].usedStock || [];

        setStockItems(
          inStockData.map((item) => {
            const usedStockItem = usedStockData.find(
              (u) => u.name === item.name
            );

            return {
              name: item.name,
              inStock: item.quantity,
              usedStock: usedStockItem ? usedStockItem.quantity : 0,
              remainingStock:
                item.quantity - (usedStockItem ? usedStockItem.quantity : 0),
            };
          })
        );
      }
    } else {
      setCampaignObj(initialCampaignObj);
    }
  }, []);

  const urlRegex = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/;

  useEffect(() => {
    const url = campaignObj?.url ?? "";

    if (url.trim() !== "") {
      if (urlRegex.test(url)) {
        setQrUrl(url);
        setUrlError(null);
      } else {
        setQrUrl("");
        setUrlError(
          "Please enter a valid URL (starting with http:// or https://)"
        );
      }
    } else {
      setQrUrl("");
      setUrlError(null);
    }
  }, [campaignObj?.url]);

  const handleUtmCampaignChange = (e: any) => {
    if (!!campaignObj?.url) {
      let updateQuery = campaignObj?.url;
      let newUrl = removeQueryParams(updateQuery, [
        "utm_campaign",
        "utm_source",
        "url_slug",
      ]);
      setCampaignObj({
        ...campaignObj,
        utmCampaign: e.target.value.replaceAll(" ", "_"),
        url:
          newUrl +
          "?utm_campaign=" +
          e.target.value.replaceAll(" ", "_") +
          (!!campaignObj?.utmSource
            ? "&utm_source=" + campaignObj?.utmSource
            : "") +
          (!!campaignObj?.slug ? "&url_slug=" + campaignObj?.slug : ""),
      });
    }
  };
  const handleUtmSourceChange = (e: any) => {
    if (!!campaignObj?.url) {
      let updateQuery = campaignObj?.url;
      let newUrl = removeQueryParams(updateQuery, [
        "utm_campaign",
        "utm_source",
        "url_slug",
      ]);
      setCampaignObj({
        ...campaignObj,
        utmSource: e.target.value.replaceAll(" ", "_"),
        url:
          newUrl +
          "?utm_campaign=" +
          campaignObj.utmCampaign +
          "&utm_source=" +
          e.target.value.replaceAll(" ", "_") +
          (!!campaignObj?.slug ? "&url_slug=" + campaignObj?.slug : ""),
      });
    }
  };

  const handleSlugChange = (e: any) => {
    if (!!campaignObj?.url) {
      let updateQuery = campaignObj?.url;
      let newUrl = removeQueryParams(updateQuery, [
        "utm_campaign",
        "utm_source",
        "url_slug",
      ]);
      setCampaignObj({
        ...campaignObj,
        slug: e.target.value.replaceAll(" ", "-"),
        url:
          newUrl +
          "?utm_campaign=" +
          campaignObj.utmCampaign +
          "&utm_source=" +
          campaignObj.utmSource +
          "&url_slug=" +
          e.target.value.replaceAll(" ", "-"),
      });
    }
  };

  const handleDownloadQRCode = async () => {
    if (qrRef.current) {
      const canvas = await html2canvas(qrRef.current);
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `${campaignObj.name || "QRCode"}.png`;
      link.click();
    }
  };
  const handleRemoveItem = (index: number) => {
    setStockItems((prevStockItems) => {
      const updatedStock = prevStockItems.filter((_, i: number) => i !== index);

      setCampaignObj((prev: any) => {
        if (!prev.stock || !prev.stock[0]) {
          console.error("Invalid stock structure in campaignObj");
          return prev;
        }

        let updatedStockObj = { ...prev.stock[0] };

        updatedStockObj.inStock =
          updatedStockObj.inStock?.filter((_: any, i: number) => i !== index) ||
          [];
        updatedStockObj.remainingStock =
          updatedStockObj.remainingStock?.filter(
            (_: any, i: number) => i !== index
          ) || [];
        updatedStockObj.usedStock =
          updatedStockObj.usedStock?.filter(
            (_: any, i: number) => i !== index
          ) || [];

        updatedStockObj.total.inStockCount = updatedStockObj.inStock.reduce(
          (sum: number, item: any) => sum + (item.quantity || 0),
          0
        );
        updatedStockObj.total.remainingStockCount =
          updatedStockObj.remainingStock.reduce(
            (sum: number, item: any) => sum + (item.quantity || 0),
            0
          );
        updatedStockObj.total.usedStockCount = updatedStockObj.usedStock.reduce(
          (sum: number, item: any) => sum + (item.quantity || 0),
          0
        );

        return {
          ...prev,
          stock: [updatedStockObj],
        };
      });

      return updatedStock;
    });
  };
  return (
    <Box p={2}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Back">
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Tooltip>
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {isEditCampaign
            ? "Update Campaign QR Code"
            : "Create Campaign QR Code"}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        paddingRight={4}
      >
        <Grid item xs={6} paddingRight={4} width="60%" paddingTop="30px">
          <Box width={"100%"}>
            <TextField
              name="Campaign Name"
              margin="dense"
              label="Campaign Name"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={campaignObj?.name || ""}
              onChange={(e) =>
                setCampaignObj({ ...campaignObj, name: e.target.value })
              }
            />
          </Box>

          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Custom URL"
              margin="dense"
              label="Enter Your Own URL"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={campaignObj?.url || ""}
              onChange={(e) =>
                setCampaignObj({ ...campaignObj, url: e.target.value })
              }
              error={!!urlError}
              helperText={urlError}
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Utm Campaign"
              margin="dense"
              label="Enter Your Utm Campaign"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={campaignObj?.utmCampaign || ""}
              onChange={handleUtmCampaignChange}
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Utm Source"
              margin="dense"
              label="Enter Your Utm Source"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={campaignObj?.utmSource || ""}
              onChange={handleUtmSourceChange}
            />
          </Box>
          <Box marginTop={2} width={"100%"}>
            <TextField
              name="Slug"
              margin="dense"
              label="Enter Your Slug"
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={campaignObj?.slug || ""}
              onChange={handleSlugChange}
            />
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ py: 3 }}>
              Price Management
            </Typography>
            {pricing.map((priceField, index) => (
              <Grid container spacing={2} key={index}>
                {/* First Row: Name, Price, Kilometer */}
                <Grid container item xs={12} spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      name={`pricing.${index}.name`}
                      margin="dense"
                      label="Name"
                      fullWidth
                      value={priceField.name}
                      // onChange={(e) => {
                      //   const newPricing = [...pricing];
                      //   newPricing[index].name = e.target.value;
                      //   setPricing(newPricing);
                      // }}
                      onChange={(e) =>
                        handlePricingChange(index, "name", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name={`pricing.${index}.price`}
                      margin="dense"
                      label="Price"
                      fullWidth
                      value={priceField.price}
                      // onChange={(e) => {
                      //   const newPricing = [...pricing];
                      //   newPricing[index].price = e.target.value;
                      //   setPricing(newPricing);
                      // }}
                      onChange={(e) =>
                        handlePricingChange(index, "price", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name={`pricing.${index}.kilometer`}
                      margin="dense"
                      label="Kilometer"
                      fullWidth
                      value={priceField.km}
                      // onChange={(e) => {
                      //   const newPricing = [...pricing];
                      //   newPricing[index].kilometer = e.target.value;
                      //   setPricing(newPricing);
                      // }}
                      onChange={(e) =>
                        handlePricingChange(index, "km", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>

                {/* Second Row: Description, Image */}
                <Grid container item xs={12} spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <TextField
                      name={`pricing.${index}.description`}
                      margin="dense"
                      label="description"
                      fullWidth
                      value={priceField.description}
                      // onChange={(e) => {
                      //   const newPricing = [...pricing];
                      //   newPricing[index].description = e.target.value;
                      //   setPricing(newPricing);
                      // }}
                      onChange={(e) =>
                        handlePricingChange(
                          index,
                          "description",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">Upload Image:</Typography>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(event) => handleImageChange(event, index)}
                      style={{ display: "none" }}
                      id={`campaign-price-image-upload-${index}`}
                    />
                    <label htmlFor={`campaign-price-image-upload-${index}`}>
                      <CustomButton variant="outlined" component="span">
                        Upload Image
                      </CustomButton>
                    </label>

                    {pricing[index].imagePreview && (
                      <Box
                        sx={{
                          mt: 2,
                          width: "100%",
                          height: "auto",
                          borderRadius: 2,
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <img
                          src={pricing[index].imagePreview}
                          alt="Preview"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>

                {/* Add and Remove Price Buttons */}
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item>
                    <Tooltip title="Add">
                      <IconButton color="primary" onClick={handleAddPrice}>
                        <AddCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    {pricing.length > 1 && (
                      <Tooltip title="Remove">
                        <IconButton
                          color="secondary"
                          onClick={() => handleRemovePrice(index)}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Box>

          <Divider sx={{ my: 3 }} />
          <Box p={2}>
            {/* Title */}
            <Typography variant="h6" gutterBottom sx={{ py: 3 }}>
              Stock Management
            </Typography>

            {/* Stock Items */}
            {stockItems.map((item, index) => (
              <Box key={index} mb={2}>
                {/* Item Name */}
                <TextField
                  label="Item Name"
                  value={item.name}
                  onChange={(e) =>
                    handleStockItemChange(index, "name", e.target.value)
                  }
                  fullWidth
                  sx={{ mb: 2 }}
                />

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label="In Stock"
                      type="number"
                      value={item.inStock}
                      onChange={(e) =>
                        handleStockItemChange(index, "inStock", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      label="Remaining Stock"
                      type="number"
                      value={item?.remainingStock}
                      onChange={(e) =>
                        handleStockItemChange(
                          index,
                          "remainingStock",
                          e.target.value
                        )
                      }
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      label="Used Stock"
                      type="number"
                      value={item?.usedStock}
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {/* Add and Remove Buttons */}
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  {/* Add Button */}
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setStockItems([
                        ...stockItems,
                        {
                          name: "",
                          inStock: 0,
                          usedStock: 0,
                          remainingStock: 0,
                        },
                      ])
                    }
                  >
                    <AddCircleOutline />
                  </IconButton>

                  {stockItems.length > 1 && (
                    <IconButton
                      color="error"
                      onClick={() => handleRemoveItem(index)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
          <CustomButton onClick={saveQRCode}>Save Campaign</CustomButton>
        </Grid>

        {/* QR Code Preview Section */}
        <Box>
          {qrUrl && (
            <>
              <div style={{ paddingTop: "20px" }}>
                <div ref={qrRef} style={{ padding: "10px" }}>
                  <QRCodeGenerator url={qrUrl} logoSrc={logo} />
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    {campaignObj?.name}
                  </div>
                </div>
              </div>
              <CustomButton onClick={handleDownloadQRCode}>
                Download QR Code
              </CustomButton>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignModal;
