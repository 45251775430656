import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  Typography,
  Link,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import styles from "./../Business/Business.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageGallery from "./../Business/ImageGallery";
import { useMutation, useQuery } from "@apollo/client";
import { GET_EXTRA_BUSINESS_PROFILE_SLUG } from "src/graphql/query";
import { useNavigate } from "react-router";
import Loader from "src/components/Loader";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { hasPermissionPage } from "src/components/Common/Utils";
import OverviewComponent from "./../Business/OverView";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import WarningIcon from "@mui/icons-material/Warning";
import VerifyEventsDialog from "../Events/VerifyEvents";
import {
  DELETE_EXTRA_BUSINESS_PROFILE,
  VERIFY_BUSINESS,
} from "src/graphql/mutations";
import { DELETE_MULTIPLE_BUSINESS } from "src/graphql/DeleteMutation";
const ExtraBusinessProfileView: React.FC = () => {
  const [deleteExtraBusiness] = useMutation(DELETE_EXTRA_BUSINESS_PROFILE);
  const [businessData, setBusinessData] = useState<any>(null);
  const [isShowTimings, setIsShowTimings] = useState(false);
  const [isShowBusiness, setIsShowBusiness] = useState(true);
  const [isShowSocialMedia, setIsShowSocialMedia] = useState(false);
  const [isShowImages, setIsShowImages] = useState(false);
  const [verifyBusinessProfile] = useMutation(VERIFY_BUSINESS);
  const [verifyingBusiness, setVerifyingBusiness] = useState<string | null>(
    null
  );
  const [verifyBusinessProfileValue, setVerifyBusinessProfileValue] =
    useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const [deleteBusinessId, setDeleteBusinessId] = useState(null);
  const [deleteBusiness] = useMutation(DELETE_MULTIPLE_BUSINESS);
  const [isShowAllToggles, setIsShowAllToggles] = useState(false);

  const id = window.location.pathname.split("/")[2];
  const navigate = useNavigate();

  const { refetch: refetchBusinessProfile, loading } = useQuery(
    GET_EXTRA_BUSINESS_PROFILE_SLUG
  );

  const fetchData = async () => {
    try {
      const res = await refetchBusinessProfile({ slug: id });
      setBusinessData(res?.data?.getExtraBusinessProfilebyslug?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, refetchBusinessProfile]);

  const handleVerify = async (businessId: string, status: string) => {
    try {
      const res = await verifyBusinessProfile({
        variables: {
          input: {
            _id: businessId,
            status,
            reason,
          },
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error: any) => error.message)
            .join(", ")}`
        );
      }

      fetchData();
      setReason("");
      setVerifyBusinessProfileValue("");
      toast.success(res?.data?.verifyBusinessProfile?.message);
      setVerifyingBusiness(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };

  const handleOpenDeleteModel = (_id: any) => {
    setDeleteBusinessId(_id);
    setIsDeleteModelOpen(true);
  };

  const handleCloseDeleteModel = () => {
    setIsDeleteModelOpen(false);
    setDeleteBusinessId(null);
  };

  const handleConfirmDelete = async () => {
    if (!deleteBusinessId) return;
    try {
      const response = await deleteExtraBusiness({
        variables: { id: deleteBusinessId },
      });
      // Adjusting the response destructuring based on the response format
      const { statusCode, message } =
        response?.data?.deleteExtraBusinessProfile || {};

      // Check for success based on statusCode
      if (statusCode === 200) {
        toast.success(`Success: ${message}`);
        fetchData(); // Refetch the business data after deletion
        navigate("/ExtraBusiness");
      } else {
        throw new Error(message || "Unexpected response format from server.");
      }
    } catch (error: any) {
      // Handle error with toast
      toast.error(
        error?.message || "Failed to delete the business. Please try again."
      );
      console.error("Error deleting business:", error);
    } finally {
      handleCloseDeleteModel();
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!businessData && !loading) {
    return <Box textAlign="center" mt={4}></Box>;
  }

  const {
    _id,
    businessName,
    brandName,
    brandLogo,
    bgImage,
    overviews,
    subCategory,
    mobileNumber,
    timing,
    addresses,
    galleries,
    businessEmail,
    primarySubcategoryName,
    images,
    pinCodeDetails,
    website,
    slug,
    additionalSubCategoriesName,
    tagsValue,
    managedBy,
    totalReviewsCount,
    averageRating,
    status,
    verifiedBy,
    verificationDate,
    metaDescription,
    metaKeywords,
    metaTitle,
    socialMedia,
    members,
    claimStatus,
    isPromoted,
    reviewCount,
    rank,
  } = businessData;

  const handleEdit = async (slug: any) => {
    if (slug) {
      window.open(`/ExtraBusiness/edit/${slug}`, "_blank");
    } else {
      console.error("Invalid row data. Unable to open edit page.");
    }
  };

  const toggleAllSwitches = () => {
    const newState = !(
      isShowSocialMedia &&
      isShowTimings &&
      isShowImages &&
      isShowBusiness
    );
    setIsShowSocialMedia(newState);
    setIsShowTimings(newState);
    setIsShowImages(newState);
    setIsShowBusiness(newState);
  };

  return (
    <Box maxWidth={1600} marginX="auto">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{ borderBottom: "1px solid #e0e0e0" }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h6" color="primary" textAlign="left">
            ExtraBusiness Informatio
          </Typography>
          <Switch
            checked={isShowBusiness}
            onChange={(e) => setIsShowBusiness(e.target.checked)}
            name="timingsEnabled"
            color="primary"
          />
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #60a5fa",
              padding: "2px",
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="body1"
              color="primary"
              sx={{ marginLeft: "8px", fontWeight: 500 }}
            >
              Set All Toggles
            </Typography>
            <Switch
              checked={isShowAllToggles}
              onChange={(e) => {
                setIsShowAllToggles(e.target.checked);
                toggleAllSwitches();
              }}
              name="toggleAllSwitch"
              color="primary"
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #60a5fa",
              padding: "2px",
              borderRadius: "8px",
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                }}
              >
                Delete this Business
              </Typography>
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => handleOpenDeleteModel(_id)}
                  aria-label="delete"
                  className={styles.button}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>

            {isDeleteModelOpen && (
              <DeleteModel
                open={isDeleteModelOpen}
                onClose={handleCloseDeleteModel}
                onConfirm={handleConfirmDelete}
                title="Delete Business"
                message="Are you sure you want to delete this business?"
              />
            )}
          </div>

          {hasPermissionPage("business", "verify") && (
            <Box display="flex" alignItems="center" gap={1}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "2px solid #60a5fa",
                  padding: "2px",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  }}
                >
                  Show Web
                </Typography>
                <Box display="flex" alignItems="center" gap={2}>
                  {businessData?.status === "APPROVED" ? (
                    <Tooltip title="Web live">
                      <IconButton
                        onClick={() => setVerifyingBusiness(businessData._id)}
                        aria-label="status"
                        style={{ color: "#039487" }}
                      >
                        <ThumbUpAltIcon />
                      </IconButton>
                    </Tooltip>
                  ) : businessData?.status === "REJECTED" ? (
                    <Tooltip title="Web rejected">
                      <IconButton
                        onClick={() => setVerifyingBusiness(businessData._id)}
                        aria-label="status"
                        style={{ color: "#ff0000" }}
                      >
                        <ThumbDownAltIcon />{" "}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Web not live">
                      <IconButton
                        onClick={() => setVerifyingBusiness(businessData._id)}
                        aria-label="status"
                        style={{ color: "#ff9966" }}
                      >
                        <WarningIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </div>
            </Box>
          )}

          <VerifyEventsDialog
            label="Business Web"
            handleVerify={handleVerify}
            setVerifyingData={setVerifyingBusiness}
            verifyingData={verifyingBusiness}
            verifyDataValue={verifyBusinessProfileValue}
            reason={reason}
            setVerifyDataValue={setVerifyBusinessProfileValue}
            setReason={setReason}
          />

          {hasPermissionPage("business", "update") && (
            <Tooltip title="Edit">
              <IconButton
                onClick={() => handleEdit(slug)}
                aria-label="edit"
                className={styles.button}
              >
                <Typography
                  variant="body2"
                  p={1}
                  borderRadius={1}
                  sx={{
                    fontSize: "1rem",
                    color: "#fff",
                    background: "#60a5fa",
                  }}
                >
                  Edit this Deletebusiness
                </Typography>
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      {isShowBusiness && (
        <Grid>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              backgroundColor: "#f9fafb",
              borderRadius: "8px",
              mb: 4,
            }}
          >
            <Grid container spacing={3}>
              {/* Column 1 */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Business Details
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.3rem", color: "#030712" }}
                >
                  {businessName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#2dd4bf" }}
                >
                  {primarySubcategoryName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#555" }}
                >
                  Additional SubCategories: {additionalSubCategoriesName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Promoted: {isPromoted ? "True" : "False"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Slug : {slug}
                </Typography>
              </Grid>

              {/* Column 2 */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Contact Information
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Mobile: {mobileNumber}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Email: {businessEmail}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Claim Status: {claimStatus}
                </Typography>
              </Grid>

              {/* Divider */}
              <Grid item xs={12}>
                <Divider />
              </Grid>

              {/* Column 3 */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Address
                </Typography>
                {addresses?.length > 0 ? (
                  addresses.map((addr: any, index: number) => (
                    <Box key={index} sx={{ mt: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1.2rem", color: "#030712" }}
                      >
                        <strong>Address:</strong> {addr.address}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1.2rem", color: "#030712" }}
                      >
                        <strong>Building/Shop Number:</strong>{" "}
                        {addr.buildingOrShopNumber}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1.2rem", color: "#030712" }}
                      >
                        <strong>Landmark:</strong> {addr.landmark}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "1.2rem", color: "#030712" }}
                      >
                        <strong>Neighborhood:</strong> {addr.neighborhood}
                      </Typography>
                      {addr.googleMapLink && (
                        <Link
                          href={addr.googleMapLink}
                          target="_blank"
                          rel="noopener"
                          sx={{
                            color: "#1976d2",
                            fontWeight: 500,
                            fontSize: "1rem",
                          }}
                        >
                          View on Google Maps
                        </Link>
                      )}
                    </Box>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "1.2rem", color: "#030712" }}
                  >
                    No address data available.
                  </Typography>
                )}
              </Grid>
              {/* Column 4 */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Online Presence
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Website:{" "}
                  <a
                    href={website}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1976d2", textDecoration: "none" }}
                  >
                    {website}
                  </a>
                </Typography>
              </Grid>

              {/* Divider */}
              <Grid item xs={12}>
                <Divider />
              </Grid>

              {/* Column 5 */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Reviews and Tags
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Tags: {tagsValue}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Managed By: {managedBy}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Total Reviews Count: {totalReviewsCount}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Average Rating: {averageRating}
                </Typography>
              </Grid>

              {/* Column 6 */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Status
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Status: {status}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Verified By: {verifiedBy}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Verification Date: {verificationDate}
                </Typography>
              </Grid>

              {/* Divider */}
              <Grid item xs={12}>
                <Divider />
              </Grid>

              {/* Column 7 */}
              <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Metadata
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Meta Title: {metaTitle}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Meta Description: {metaDescription}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.2rem", color: "#030712" }}
                >
                  Meta Keywords: {metaKeywords}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

      <Typography variant="h6" justifyContent={"center"}>
        Social Media and Members
      </Typography>
      <Switch
        checked={isShowSocialMedia}
        onChange={(e) => setIsShowSocialMedia(e.target.checked)}
        name="timingsEnabled"
        color="primary"
      />
      {isShowSocialMedia && (
        <Grid container spacing={3} mb={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" color="primary" gutterBottom>
              Social Media
            </Typography>
            {socialMedia?.length > 0 ? (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={600}>Platform</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={600}>Handle</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {socialMedia.map((social: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{social.platform}</TableCell>
                        <TableCell>
                          <Link
                            href={social.handle}
                            target="_blank"
                            rel="noopener"
                            sx={{
                              color: "#1976d2",
                              fontWeight: 500,
                            }}
                          >
                            {social.handle}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body2" sx={{ color: "#555", mt: 2 }}>
                No social media links available.
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" color="primary" gutterBottom>
              Members
            </Typography>
            {members?.length > 0 ? (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={600}>Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={600}>Email</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={600}>Status</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {members.map((member: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{member.name}</TableCell>
                        <TableCell>{member.email}</TableCell>
                        <TableCell>{member.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body2" sx={{ color: "#555", mt: 2 }}>
                No members found.
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      <Typography variant="h6">Check timings and services</Typography>
      <Switch
        checked={isShowTimings}
        onChange={(e) => setIsShowTimings(e.target.checked)}
        name="timingsEnabled"
        color="primary"
      />
      {isShowTimings && (
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 500, color: "#1976d2", mb: 2 }}
              >
                Timings
              </Typography>
              <Card
                variant="outlined"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  boxShadow: "0 1px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#f9f9f9",
                  p: 2,
                }}
              >
                <Box component="ul" sx={{ listStyle: "none", m: 0, p: 0 }}>
                  {timing?.length ? (
                    timing.map((timing: any, index: number) => (
                      <Box
                        component="li"
                        key={timing.day + index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          py: 1,
                          borderBottom:
                            index !== timing.length - 1
                              ? "1px solid #e0e0e0"
                              : "none",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 500, color: "#333" }}
                        >
                          {timing.day}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#555",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <AccessTimeIcon
                            sx={{ fontSize: 16, mr: 0.5, color: "#1976d2" }}
                          />
                          {timing.startTime} - {timing.endTime}
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        color: "#999",
                        py: 2,
                      }}
                    >
                      No timings data available
                    </Typography>
                  )}
                </Box>
              </Card>
            </Box>
          </Grid>
          {overviews?.[0]?.dynamicOptions?.length > 0 && (
            <Box mb={4}>
              <OverviewComponent overview={overviews} />
            </Box>
          )}
        </Grid>
      )}

      <Typography variant="h6">Check images</Typography>
      <Switch
        checked={isShowImages}
        onChange={(e) => setIsShowImages(e.target.checked)}
        name="timingsEnabled"
        color="primary"
      />

      {isShowImages && (
        <>
          <Typography variant="subtitle1" color="#06b6d4" mb={2}>
            Background image and Brand logo of {businessName}
          </Typography>
          <Grid container spacing={3} alignItems="center" mb={4}>
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <Box
                height={250}
                width={250}
                sx={{
                  borderRadius: "50%",
                  overflow: "hidden",
                  border: "3px solid #1976d2",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardMedia
                  component="img"
                  image={brandLogo || "/default-logo.png"}
                  alt={brandName}
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {images?.length > 0 && (
            <Box mb={4}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 600, color: "#1976d2" }}
              >
                Gallery
              </Typography>
              <ImageGallery galleries={images} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ExtraBusinessProfileView;
