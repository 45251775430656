import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  IconButton,
} from "@mui/material";
import React from "react";
import {
  Category as CategoryIcon,
  Event as EventIcon,
  Forum as BlogIcon,
  Help as FaqIcon,
  Business as BusinessIcon,
  Approval as ClaimIcon,
  FeaturedVideo as FeaturedVideoIcon,
  AddIcCall as AddIcCallIcon,
  LocalOffer as LocalOfferIcon,
  LocationOn as LocationOnIcon,
  Person as PersonIcon,
  FiberPin as FiberPinIcon,
  Tag as TagIcon,
  ChevronLeft as ChevronLeftIcon,
  Settings,
  TrackChanges,
  BatchPrediction as BatchPredictionIcon,
  Today as TodayIcon,
  PermDataSetting as PermDataSettingIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
} from "@mui/icons-material";
import { ROUTES } from "src/constant";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getPermissions,
  getRole,
  hasPermission,
} from "src/components/Common/Utils";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import Person3Icon from "@mui/icons-material/Person3";
import CampaignIcon from "@mui/icons-material/Campaign";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import InfoIcon from "@mui/icons-material/Info";
import StarsIcon from "@mui/icons-material/Stars";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GroupIcon from "@mui/icons-material/Group";
import DeleteIcon from "@mui/icons-material/Delete";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MessageIcon from '@mui/icons-material/Message';
interface MenuItem {
  id: number;
  menuText: string;
  route: string;
  icon: React.ReactNode;
  requiredPermission?: { type: string; action: string }; // Permission required to access the route
}

interface SideBarProps {
  isOpen: boolean;
  toggleSidebar: () => void; // Function to toggle sidebar
}

const SideBar: React.FC<SideBarProps> = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const drawerWidth = 240;
  const isSuperAdmin = getRole() === "SuperAdmin";
  const permissions = getPermissions();

  // Define the menu items
  const MenuItemsArray: MenuItem[] = [
    ...(isSuperAdmin
      ? [
          {
            id: 1,
            menuText: "Users",
            icon: <PersonIcon />,
            route: ROUTES.USERS,
            requiredPermission: { type: "User", action: "view" },
          },
        ]
      : []),
    {
      id: 9,
      menuText: "Business",
      icon: <BusinessIcon />,
      route: ROUTES.BUSINESS,
      requiredPermission: { type: "Business", action: "view" },
    },
    {
      id: 29,
      menuText: "DeleteBusiness",
      icon: <DeleteIcon />,
      route: ROUTES.DELETEBUSINESS,
      requiredPermission: { type: "DeleteBusiness", action: "view" },
    },
    {
      id: 30,
      menuText: "Extra Business",
      icon: <StorefrontIcon />,
      route: ROUTES.EXTRABUSINESS,
      requiredPermission: { type: "ExtraBusiness", action: "view" },
    },

    {
      id: 2,
      menuText: "Events",
      icon: <EventIcon />,
      route: ROUTES.EVENTS,
      requiredPermission: { type: "Event", action: "view" },
    },
    {
      id: 5,
      menuText: "Deals",
      icon: <LocalOfferIcon />,
      route: ROUTES.DEALS,
      requiredPermission: { type: "Deals", action: "view" },
    },
    {
      id: 31,
      menuText: "Coupon Details",
      icon: <NewspaperIcon />,
      route: ROUTES.COUPON,
      requiredPermission: { type: "CouponDetails", action: "view" },
    },
    {
      id: 32,
      menuText: "Spin And Win ",
      icon: <AutoModeIcon />,
      route: ROUTES.SPINANDWIN,
      requiredPermission: { type: "SpinAndWin", action: "view" },
    },
    {
      id: 33,
      menuText: "Spin Rewards",
      icon: <EmojiEventsIcon />,
      route: ROUTES.SPIN_REWARD,
      requiredPermission: { type: "SpinReward", action: "view" },
    },
    {
      id: 34,
      menuText: "User Spin",
      icon: <Person3Icon />,
      route: ROUTES.USERSPIN,
      requiredPermission: { type: "UserSpin", action: "view" },
    },
    {
      id: 35,
      menuText: "Send Messages",
      icon: <MessageIcon />,
      route: ROUTES.SEND_BULK_MESSAGES,
      requiredPermission: { type: "SendBulkMessages", action: "view" },
    },
    {
      id: 13,
      menuText: "Claim Business",
      icon: <ClaimIcon />,
      route: ROUTES.CLAIM_BUSINESS,
      requiredPermission: { type: "ClaimBusiness", action: "view" },
    },
    {
      id: 26,
      menuText: "Latest Business",
      icon: <BusinessCenterIcon />,
      route: ROUTES.BUSINESSBYDATE,
      requiredPermission: { type: "BusinessByDate", action: "view" },
    },
    {
      id: 14,
      menuText: "Popular Location",
      icon: <LocationOnIcon />,
      route: ROUTES.POPULAR_LOCATION,
      requiredPermission: { type: "PopularLocation", action: "view" },
    },
    {
      id: 20,
      menuText: "Config",
      icon: <PermDataSettingIcon />,
      route: ROUTES.CONFIG,
      requiredPermission: { type: "ConfigData", action: "view" },
    },
    {
      id: 27,
      menuText: "Campaign",
      icon: <CampaignIcon />,
      route: ROUTES.CAMPAIGN,
      requiredPermission: { type: "Campaign", action: "view" },
    },
    {
      id: 28,
      menuText: "Campaign User",
      icon: <GroupIcon />,
      route: ROUTES.CAMPAIGNUSER,
      requiredPermission: { type: "CampaignUser", action: "view" },
    },
    {
      id: 7,
      menuText: "Blogs",
      icon: <BlogIcon />,
      route: ROUTES.BLOGS,
      requiredPermission: { type: "Blog", action: "view" },
    },
    {
      id: 25,
      menuText: "Ads Link",
      icon: <HistoryEduIcon />,
      route: ROUTES.ADSLINK,
      requiredPermission: { type: "AdsLink", action: "view" },
    },
    ...(isSuperAdmin
      ? [
          {
            id: 21,
            menuText: "Permission",
            icon: <AdminPanelSettingsIcon />,
            route: ROUTES.PERMISSION,
            requiredPermission: { type: "Permission", action: "view" },
          },
        ]
      : []),
    {
      id: 6,
      menuText: "Category",
      icon: <CategoryIcon />,
      route: ROUTES.CATEGORY,
      requiredPermission: { type: "Category", action: "view" },
    },

    {
      id: 10,
      menuText: "Advertise ",
      icon: <FeaturedVideoIcon />,
      route: ROUTES.ADVERTISE,
      requiredPermission: { type: "AdsWithUs", action: "view" },
    },
    {
      id: 11,
      menuText: "Contact Us",
      icon: <AddIcCallIcon />,
      route: ROUTES.CONTACT_US,
      requiredPermission: { type: "ContactUs", action: "view" },
    },
    {
      id: 3,
      menuText: "Pincode",
      icon: <FiberPinIcon />,
      route: ROUTES.PINCODES,
      requiredPermission: { type: "PinCode", action: "view" },
    },
    {
      id: 12,
      menuText: "Tags",
      icon: <TagIcon />,
      route: ROUTES.TAGS,
      requiredPermission: { type: "Tags", action: "view" },
    },

    {
      id: 15,
      menuText: "Home Page Setting",
      icon: <Settings />,
      route: ROUTES.HOME_PAGE_SETTINGS,
      requiredPermission: { type: "HomePageSetting", action: "view" },
    },
    {
      id: 16,
      menuText: "Tracking Data",
      icon: <TrackChanges />,
      route: ROUTES.TRACKING_DATA,
      requiredPermission: { type: "TrackingData", action: "view" },
    },
    // {
    //   id: 17,
    //   menuText: "Region",
    //   icon: <BatchPredictionIcon />,
    //   route: ROUTES.REGION,
    //   requiredPermission: { type: "Blogs", action: "view" },
    // },
    {
      id: 18,
      menuText: "Enum",
      icon: <TodayIcon />,
      route: ROUTES.ENUM,
      requiredPermission: { type: "DealsEnum", action: "view" },
    },

    {
      id: 22,
      menuText: "Surat History",
      icon: <HistoryEduIcon />,
      route: ROUTES.HISTORY,
      requiredPermission: { type: "History", action: "view" },
    },
    {
      id: 23,
      menuText: "About Surat",
      icon: <InfoIcon />,
      route: ROUTES.ABOUT_SURAT,
      requiredPermission: { type: "AboutSurat", action: "view" },
    },
    {
      id: 24,
      menuText: "Sub Category Rank",
      icon: <StarsIcon />,
      route: ROUTES.SUB_CATEGORY_RANK,
      requiredPermission: { type: "SubcategoryRank", action: "view" },
    },
    {
      id: 8,
      menuText: "Faq",
      icon: <FaqIcon />,
      route: ROUTES.FAQ,
      requiredPermission: { type: "Faq", action: "view" },
    },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isOpen ? drawerWidth : 0,
        flexShrink: 0,
        transition: "width 0.3s ease",
        [`& .MuiDrawer-paper`]: {
          width: isOpen ? drawerWidth : 0,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar>
        {!isOpen && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleSidebar}
            sx={{ marginLeft: "auto" }}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
      </Toolbar>
      <Box sx={{ overflow: "auto" }}>
        <IconButton
          onClick={toggleSidebar}
          sx={{
            zIndex: 9999999999,
            position: "absolute",
            top: "50%",
            right: isOpen ? "-15px" : "0px",
            transform: "translateY(-50%)",
            backgroundColor: "#ffffff",
            border: "1px solid #ddd",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          }}
        >
          {isOpen ? (
            <ArrowBackIosIcon sx={{ color: "#326e99" }} />
          ) : (
            <ArrowForwardIosIcon sx={{ color: "#326e99" }} />
          )}
        </IconButton>
        <List>
          {MenuItemsArray?.filter((item) =>
            hasPermission(
              permissions,
              item.requiredPermission?.type as any,
              item.requiredPermission?.action as any
            )
          )?.map(({ id, menuText, icon, route }) => {
            const isSelected = (route: string) => {
              return location.pathname.startsWith(route);
            };
            return (
              <ListItem key={id} disablePadding>
                <ListItemButton
                  onClick={() => navigate(route)}
                  sx={{
                    "&:hover": {
                      backgroundColor: isSelected(route) ? "#00C1BC" : "#eeeee", // Change background color on hover
                      color: "black", // Change text color on hover
                    },
                    backgroundColor: isSelected(route)
                      ? "#00C4B9"
                      : "transparent", // Highlight selected item
                    color: isSelected(route) ? "white" : "inherit", // Change text color if selected
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: isSelected(route) ? "white" : "inherit", // Change icon color if selected
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={menuText} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
