import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  CircularProgress,
  Tooltip,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "src/components/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import backButton from "../../Images/backButton.svg";
import { uploadImages } from "src/components/Common/Utils";
import { ADD_SPIN_WHEEL, UPDATE_SPIN_WHEEL } from "src/graphql/mutations";
import { GET_COUPON } from "src/graphql/query";
interface SpinWheelModalProps {
  open: boolean;
  handleClose: () => void;
  spinWheel?: any;
  refetchSpinWheels: () => void;
}

interface Spinner {
  couponId: string;
  image: string;
  bgColor: string;
  textColor: string;
  isOpps: boolean;
  textValue?: string;
  file?: File;
  rank?: number;
  staticName?: string;
}

interface RewardRow {
  date: string;
  slug: string;
}

const SpinWheelModal: React.FC<SpinWheelModalProps> = ({
  handleClose,
  spinWheel,
  refetchSpinWheels,
}) => {
  const [spinWheelData, setSpinWheelData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [spinnerItems, setSpinnerItems] = useState<Spinner[]>([]);
  const [rewardRows, setRewardRows] = useState<RewardRow[]>([]);
  const [draggingIndex, setDraggingIndex] = useState<any>(null);
  const [currentSlug, setCurrentSlug] = useState<string>(
    spinWheel?.currentSlug || ""
  );
  const [addSpinWheel] = useMutation(ADD_SPIN_WHEEL);
  const [updateSpinWheel] = useMutation(UPDATE_SPIN_WHEEL);

  const { data, loading, error } = useQuery(GET_COUPON, {
    variables: { page: 1, limit: 100 },
  });
  const handleDragStart = (index: any) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (index: number) => {
    if (draggingIndex === null) return;

    const updatedSpinnerItems = [...spinnerItems];
    const [movedItem] = updatedSpinnerItems.splice(draggingIndex, 1);
    updatedSpinnerItems.splice(index, 0, movedItem);

    setSpinnerItems(updatedSpinnerItems);
    setDraggingIndex(null);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after start date"),
    noOfDataAfterWin: Yup.number()
      .required("Number of data after win is required")
      .min(0, "Value must be a positive number"),
    spinner: Yup.array().of(
      Yup.object().shape({
        couponId: Yup.string().required("Coupon ID is required"),
        image: Yup.string().when("isOpps", {
          is: false,
          then: (schema) => schema.required("Image is required"),
          otherwise: (schema) => schema.notRequired(),
        }),

        bgColor: Yup.string().required("Background color is required"),
        textColor: Yup.string().required("Text color is required"),
        isOpps: Yup.boolean().required("Is Opps field is required"),
        textValue: Yup.string().required("textValue field is required"),
        rank: Yup.number() // Optional
          .min(1, "Rank must be at least 1")
          .nullable(), // Allow null/undefined
        staticName: Yup.string().required("Static Name is required"),
      })
    ),
    selectRewardRow: Yup.array().of(
      Yup.object().shape({
        date: Yup.string().required("Date is required"),
        slug: Yup.string().required("Slug is required"),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: spinWheel?.name || "",
      startDate: spinWheel?.startDate || null,
      endDate: spinWheel?.endDate || null,
      noOfDataAfterWin: spinWheel?.noOfDataAfterWin || 0,
      ...(spinWheel?._id && { slug: spinWheel?.slug || "" }),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async () => handleSubmit,
  });

  const generateSlug = (value: string) => {
    return value
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  useEffect(() => {
    if (spinWheel) {
      setSpinnerItems(
        spinWheel.spinner?.map((item: any) => ({
          couponId: item.couponId || "",
          image: item.image || "",
          bgColor: item.bgColor || "#FFFFFF",
          textColor: item.textColor || "#000000",
          isOpps: item.isOpps || false,
          textValue: item.textValue || "",
          rank: item.rank || undefined,
          staticName: item.staticName || "",
        })) || []
      );

      setRewardRows(
        spinWheel.selectRewardRow?.map((item: any) => ({
          date: item.date || "",
          slug: item.slug || "",
        })) || []
      );

      formik.setValues({
        ...formik.values,
        startDate: new Date(spinWheel.startDate).toISOString().split("T")[0],
        endDate: new Date(spinWheel.endDate).toISOString().split("T")[0],
        noOfDataAfterWin: spinWheel.noOfDataAfterWin || 0,
      });
    } else {
      formik.resetForm();
      setSpinnerItems([
        {
          couponId: "",
          image: "",
          bgColor: "#FFFFFF",
          textColor: "#000000",
          isOpps: false,
          textValue: "",
          rank: undefined,
          staticName: "",
        },
      ]);
      setRewardRows([]);
    }
  }, [spinWheel]);

  const handleImageChange = (index: number, file: File | null) => {
    if (!file) return;

    const updatedSpinnerItems = [...spinnerItems];
    updatedSpinnerItems[index] = {
      ...updatedSpinnerItems[index],
      image: URL.createObjectURL(file),
      file,
    };
    setSpinnerItems(updatedSpinnerItems);
  };

  const handleRemoveImage = (index: number) => {
    const updatedSpinnerItems = [...spinnerItems];
    const currentItem = updatedSpinnerItems[index];

    if (currentItem.image && currentItem.image.startsWith("blob:")) {
      URL.revokeObjectURL(currentItem.image);
    }

    updatedSpinnerItems[index] = {
      ...currentItem,
      image: "",
      file: undefined,
    };

    setSpinnerItems(updatedSpinnerItems);
  };

  const handleSpinnerItemChange = (
    index: number,
    field: keyof Spinner,
    value: any
  ) => {
    const updatedSpinnerItems = [...spinnerItems];
    updatedSpinnerItems[index] = {
      ...updatedSpinnerItems[index],
      [field]: value,
    };
    setSpinnerItems(updatedSpinnerItems);
  };

  const addSpinnerItem = () => {
    setSpinnerItems([
      ...spinnerItems,
      {
        couponId: "",
        image: "",
        bgColor: "#FFFFFF",
        textColor: "#000000",
        isOpps: false,
        rank: undefined,
      },
    ]);
  };

  const removeSpinnerItem = (index: number) => {
    if (spinnerItems.length <= 1) {
      toast.error("At least one spinner item is required");
      return;
    }

    const updatedSpinnerItems = spinnerItems.filter((_, i) => i !== index);
    setSpinnerItems(updatedSpinnerItems);
  };

  const handleRewardRowChange = (
    index: number,
    field: keyof RewardRow,
    value: string
  ) => {
    const updatedRewardRows = [...rewardRows];
    updatedRewardRows[index] = {
      ...updatedRewardRows[index],
      [field]: value,
    };
    setRewardRows(updatedRewardRows);
  };

  const addRewardRow = () => {
    setRewardRows([
      ...rewardRows,
      {
        date: "",
        slug: "",
      },
    ]);
  };

  const removeRewardRow = (index: number) => {
    const updatedRewardRows = rewardRows.filter((_, i) => i !== index);
    setRewardRows(updatedRewardRows);
  };

  const customHandleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let transformedValue = value;

    if (name === "name" && !spinWheel?._id) {
      formik.setFieldValue("slug", generateSlug(value));
    }

    if (name === "slug") {
      transformedValue = value?.toLowerCase()?.replace(/\s+/g, "-");
    }

    formik.setFieldValue(name, transformedValue);
    setSpinWheelData((prev: any) => ({
      ...prev,
      [name]: transformedValue,
    }));
  };
  useEffect(() => {
    if (rewardRows.length > 0 && !currentSlug && currentSlug !== "") {
      setCurrentSlug("");
    }
  }, [rewardRows]);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await validationSchema.validate(
        {
          ...formik.values,
          spinner: spinnerItems,
          selectRewardRow: rewardRows,
        },
        { abortEarly: false }
      );

      const spinnerWithImages = await Promise.all(
        spinnerItems.map(async (item) => {
          if (item.file) {
            const uploadResult = await uploadImages(
              [item.file],
              "spinwheel",
              formik.values.name
            );

            const imageUrl =
              uploadResult?.uploadMultipleImages?.[0]?.url ||
              uploadResult?.[0]?.url ||
              item.image;

            return {
              ...item,
              image: imageUrl,
              file: undefined,
            };
          }
          return {
            ...item,
            file: undefined,
          };
        })
      );

      const variables = {
        input: {
          ...formik.values,
          spinner: spinnerWithImages.map(({ file, ...rest }) => rest),
          selectRewardRow: rewardRows,
          currentSlug,
        },
      };

      const res = spinWheel
        ? await updateSpinWheel({
            variables: { _id: spinWheel._id, input: variables.input },
          })
        : await addSpinWheel({ variables });

      if (res?.data) {
        toast.success(
          res.data.addSpinWheel?.message ||
            res.data.updateSpinWheel?.message ||
            "Spin wheel updated successfully!"
        );
        refetchSpinWheels();
        handleClose();
      }
    } catch (error: any) {
      if (error.inner) {
        const validationErrors: Record<string, string> = {};
        error.inner.forEach((err: any) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        toast.error(error.message || "Error submitting data.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Box
        display={"flex"}
        justifyContent={"start"}
        mb={2}
        alignItems={"center"}
      >
        <Tooltip title="Back">
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Tooltip>
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          {spinWheel ? "Update Spin Wheel" : "Create Spin Wheel"}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <TextField
            label="Name"
            name="name"
            value={formik.values.name}
            onChange={customHandleChange}
            fullWidth
            variant="outlined"
            error={!!errors.name}
            helperText={errors.name}
          />
        </Box>

        {spinWheel && (
          <Box mb={3}>
            <TextField
              label="Slug"
              name="slug"
              value={formik?.values?.slug}
              onChange={customHandleChange}
              fullWidth
              variant="outlined"
              inputProps={{ style: { textTransform: "lowercase" } }}
              error={!!errors?.slug}
              helperText={errors?.slug}
            />
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box marginTop={2}>
              <TextField
                name="startDate"
                margin="dense"
                label="Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={formik.values.startDate}
                onChange={customHandleChange}
                error={!!errors.startDate}
                helperText={errors?.startDate}
                InputProps={{
                  inputProps: {
                    min: new Date().toISOString().split("T")[0],
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box marginTop={2}>
              <TextField
                name="endDate"
                margin="dense"
                label="End Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={formik.values.endDate}
                onChange={customHandleChange}
                error={!!errors.endDate}
                helperText={errors.endDate}
                InputProps={{
                  inputProps: {
                    min:
                      formik.values.startDate ||
                      new Date().toISOString().split("T")[0],
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Box mb={3} mt={3}>
          <TextField
            label="Number of Data After Win"
            name="noOfDataAfterWin"
            type="number"
            value={formik.values.noOfDataAfterWin}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              formik.setFieldValue(
                "noOfDataAfterWin",
                isNaN(value) ? 0 : value
              );
            }}
            fullWidth
            variant="outlined"
            error={!!errors.noOfDataAfterWin}
            helperText={errors.noOfDataAfterWin}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        </Box>

        <Box mb={3} mt={3}>
          <Typography variant="h6" mb={2}>
            Spinner Items
          </Typography>
          {spinnerItems.map((item, index) => {
            // Filter out coupons that are already selected in other spinner items
            const availableCoupons = data?.getCoupons?.data?.filter(
              (coupon: any) => {
                return !spinnerItems.some(
                  (spinnerItem, spinnerIndex) =>
                    spinnerIndex !== index &&
                    spinnerItem.couponId === coupon._id
                );
              }
            );

            return (
              <Box
                key={index}
                mb={4}
                p={2}
                border="1px solid #e0e0e0"
                borderRadius={2}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(index)}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="subtitle1">Item {index + 1}</Typography>
                  <IconButton
                    color="error"
                    onClick={() => removeSpinnerItem(index)}
                    disabled={spinnerItems.length <= 1}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!errors[`spinner[${index}].couponId`]}
                    >
                      <InputLabel>Coupon</InputLabel>
                      <Select
                        value={item.couponId || ""}
                        onChange={(e) =>
                          handleSpinnerItemChange(
                            index,
                            "couponId",
                            e.target.value
                          )
                        }
                        label="Coupon"
                      >
                        {loading ? (
                          <MenuItem disabled>
                            <CircularProgress size={20} />
                          </MenuItem>
                        ) : error ? (
                          <MenuItem disabled>Error loading coupons</MenuItem>
                        ) : (
                          availableCoupons?.map((coupon: any) => (
                            <MenuItem key={coupon._id} value={coupon._id}>
                              {coupon.name} ({coupon.code})
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      <FormHelperText>
                        {errors[`spinner[${index}].couponId`]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {/* Other fields remain unchanged */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Text Value"
                      value={item.textValue || ""}
                      onChange={(e) =>
                        handleSpinnerItemChange(
                          index,
                          "textValue",
                          e.target.value
                        )
                      }
                      fullWidth
                      variant="outlined"
                      error={!!errors[`spinner[${index}].textValue`]}
                      helperText={errors[`spinner[${index}].textValue`]}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Background Color"
                      type="color"
                      value={item.bgColor}
                      onChange={(e) =>
                        handleSpinnerItemChange(
                          index,
                          "bgColor",
                          e.target.value
                        )
                      }
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              marginRight: 1,
                              backgroundColor: item.bgColor,
                              border: "1px solid #ccc",
                            }}
                          />
                        ),
                      }}
                      error={!!errors[`spinner[${index}].bgColor`]}
                      helperText={errors[`spinner[${index}].bgColor`]}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Text Color"
                      type="color"
                      value={item.textColor}
                      onChange={(e) =>
                        handleSpinnerItemChange(
                          index,
                          "textColor",
                          e.target.value
                        )
                      }
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              marginRight: 1,
                              backgroundColor: item.textColor,
                              border: "1px solid #ccc",
                            }}
                          />
                        ),
                      }}
                      error={!!errors[`spinner[${index}].textColor`]}
                      helperText={errors[`spinner[${index}].textColor`]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box position="relative">
                      {item.image && (
                        <img
                          src={item.image || ""}
                          alt={`Spinner Image ${index + 1}`}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                      )}
                      {item.image && (
                        <Tooltip title="Remove">
                          <IconButton
                            size="small"
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                            }}
                            onClick={() => handleRemoveImage(index)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <CustomButton
                        variant="contained"
                        component="label"
                        style={{ marginTop: "10px" }}
                      >
                        {item.image ? "Change Image" : "Add Image"}
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={(e) =>
                            handleImageChange(
                              index,
                              e.target.files ? e.target.files[0] : null
                            )
                          }
                        />
                      </CustomButton>
                      {!item.isOpps && errors[`spinner[${index}].image`] && (
                        <Typography color="error" variant="body2">
                          {errors[`spinner[${index}].image`]}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.isOpps}
                          onChange={(e) =>
                            handleSpinnerItemChange(
                              index,
                              "isOpps",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Is Oops"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Rank"
                      type="number"
                      value={item.rank || ""} // Handle empty value
                      onChange={(e) =>
                        handleSpinnerItemChange(
                          index,
                          "rank",
                          e.target.value === ""
                            ? undefined
                            : parseInt(e.target.value, 10)
                        )
                      }
                      fullWidth
                      variant="outlined"
                      error={!!errors[`spinner[${index}].rank`]}
                      helperText={errors[`spinner[${index}].rank`]}
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Static Name"
                      value={item.staticName || ""}
                      onChange={(e) =>
                        handleSpinnerItemChange(
                          index,
                          "staticName",
                          e.target.value
                        )
                      }
                      fullWidth
                      variant="outlined"
                      error={!!errors[`spinner[${index}].staticName`]}
                      helperText={errors[`spinner[${index}].staticName`]}
                    />
                  </Grid>
                </Grid>
              </Box>
            );
          })}

          <Box mt={2}>
            <CustomButton onClick={addSpinnerItem} variant="outlined">
              Add Spinner Item
            </CustomButton>
          </Box>
        </Box>

        <Box mb={3} mt={3}>
          <Typography variant="h6" mb={2}>
            Reward Rows
          </Typography>

          {rewardRows.map((row, index) => (
            <Box
              key={index}
              mb={4}
              p={2}
              border="1px solid #e0e0e0"
              borderRadius={2}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="subtitle1">
                  Reward Row {index + 1}
                </Typography>
                <IconButton
                  color="error"
                  onClick={() => removeRewardRow(index)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Date"
                    type="date"
                    value={row.date}
                    onChange={(e) =>
                      handleRewardRowChange(index, "date", e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors[`selectRewardRow[${index}].date`]}
                    helperText={errors[`selectRewardRow[${index}].date`]}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Slug"
                    value={row.slug}
                    onChange={(e) =>
                      handleRewardRowChange(index, "slug", e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    error={!!errors[`selectRewardRow[${index}].slug`]}
                    helperText={errors[`selectRewardRow[${index}].slug`]}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}

          <Box mt={2}>
            <CustomButton onClick={addRewardRow} variant="outlined">
              Add Reward Row
            </CustomButton>
          </Box>
        </Box>
        <Box mb={3} mt={3}>
          <Typography variant="h6" mb={2}>
            Current Slug
          </Typography>
          <TextField
            select
            label=""
            value={currentSlug}
            onChange={(e) => setCurrentSlug(e.target.value)}
            fullWidth
            variant="outlined"
            SelectProps={{
              native: true,
            }}
          >
            <option value="">Select a slug</option>
            {rewardRows.map((row, index) => (
              <option key={index} value={row.slug}>
                {row.slug}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <CustomButton variant="outlined" onClick={handleClose}>
            Cancel
          </CustomButton>
          <CustomButton
            color="primary"
            variant="contained"
            type="submit"
            style={{ marginLeft: "10px" }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : spinWheel ? (
              "Update"
            ) : (
              "Create"
            )}
          </CustomButton>
        </Box>
      </form>
    </Box>
  );
};

export default SpinWheelModal;
