import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_CAMPAIGN_USERS, GET_CAMPAIGN_USER } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DeleteModel from "src/components/Common/DeleteModel";
import { ClearIcon } from "@mui/x-date-pickers";
import { DELETE_CAMPAIGN_USER } from "src/graphql/mutations";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import CampaignUserModal from "./CampaignUserModal";
interface CampaignUser {
  _id: string;
  name: string;
  phoneNumber: string;
  campaignSlug: string;
  rank: number;
}
const index: React.FC = () => {
  const [list, setList] = useState<CampaignUser[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [selectedCampaignUser, setSelectedCampaignUser] =
    useState<CampaignUser | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [isEditCampaignUser, setIsEditCampaignUser] = useState(false);
  const initialCampaignUserObj = {
    name: "",
    phoneNumber: "",
    campaignSlug: "",
  };
  const [campaignUserObj, setCampaignUserObj] = useState<any>(
    initialCampaignUserObj
  );

  const COLUMNS = [
    {
      headerName: "Date & Time",
      field: "createdAt",
      width: 150,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
        >
          <Typography variant="body2" color="textPrimary">
            {new Date(row?.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {new Date(row?.createdAt).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </Typography>
        </Box>
      ),
    },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Phone Number", field: "phoneNumber", flex: 1 },
    { headerName: "Campaign Slug", field: "campaignSlug", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: CampaignUser }) => (
        <div>
          {hasPermissionPage("CampaignUser", "update") && (
            <Tooltip title="Edit">
              <IconButton
                onClick={() => handleEdit(row)}
                aria-label="edit"
                style={{ marginRight: "8px", color: "#00C5B9" }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {hasPermissionPage("CampaignUser", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => handleDelete(row)}
                aria-label="delete"
                style={{ color: "#00C5B9" }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_CAMPAIGN_USERS, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
    },
  });

  useEffect(() => {
    if (data?.getCampaignUsers?.data) {
      setList(data.getCampaignUsers.data);
      setTotal(data.getCampaignUsers.count);
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term.length > 0 ? term : "");
      refetch({
        search: term,
        page: pagination.page,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  const [fetchCampaignUserDetails] = useLazyQuery(GET_CAMPAIGN_USER);

  const handleEdit = async (rowData: any) => {
    try {
      if (!rowData?._id) {
        toast.error("Invalid row data");
        console.error("Invalid row data");
        return;
      }

      const res = await fetchCampaignUserDetails({
        variables: {
          _id: rowData._id,
        },
      });

      if (res?.data?.getCampaignUser?.data) {
        const { __typename, ...campaignUserDetails } =
          res?.data?.getCampaignUser.data;
        setCampaignUserObj(campaignUserDetails);
        setOpenModal(true);
        setIsEditCampaignUser(true);
      } else {
        toast.error("Failed to fetch campaign user details");
        console.error("Failed to fetch campaign user details");
      }
    } catch (error: any) {
      toast.error(error?.message);
      console.error(
        "An error occurred while fetching campaign user details:",
        error
      );
    }
  };

  const handleDelete = (rowData: CampaignUser) => {
    setSelectedCampaignUser(rowData);
    setOpenConfirmDialog(true);
  };
  const [deleteCampaignUser] = useMutation(DELETE_CAMPAIGN_USER);
  const confirmDelete = async () => {
    if (!selectedCampaignUser) return;
    try {
      const res = await deleteCampaignUser({
        variables: { _id: selectedCampaignUser._id }, // Pass only the _id here
      });

      if (res?.errors) {
        throw new Error(res?.errors as any);
      }

      toast.success(
        res?.data?.deleteCampaignUser?.message ||
          "Campaign User deleted successfully!"
      );

      refetch(); // Refetch the list of users after deletion
    } catch (error: any) {
      toast.error(
        error.message || "An error occurred while deleting the campaign user."
      );
    } finally {
      setOpenConfirmDialog(false);
      setSelectedCampaignUser(null);
    }
  };

  const handleCloseModal = () => {
    setCampaignUserObj({
      name: "",
      phoneNumber: "",
      campaignSlug: "",
    });
    setOpenModal(false);
    setIsEditCampaignUser(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      {!openModal && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            paddingRight={4}
          >
            <Typography variant="h4" style={{ color: "#00C5B9" }}>
              Campaign User
            </Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <Box width={200}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {hasPermissionPage("CampaignUser", "add") && (
                <Box>
                  <CustomButton
                    onClick={() => setOpenModal(true)}
                    variant="contained"
                    className="width: 200px"
                  >
                    Create
                  </CustomButton>
                </Box>
              )}
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <CustomTable
              columns={COLUMNS}
              data={list}
              paginationModel={{
                page: pagination.page - 1,
                pageSize: pagination.pageSize,
              }}
              totalCount={total}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </Box>
        </>
      )}
      {openModal && (
        <CampaignUserModal
          handleClose={handleCloseModal}
          refetchCampaignUsers={refetch}
          initialCampaignUserObj={initialCampaignUserObj}
          campaignUserObj={campaignUserObj}
          setCampaignUserObj={setCampaignUserObj}
          isEditCampaignUser={isEditCampaignUser}
        />
      )}

      <DeleteModel
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={confirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this Campaign User?"
      />
    </Box>
  );
};

export default index;
