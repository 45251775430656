import React from "react";
import { getMimeType, videoExtensions } from "src/constant";

const DisplayVideo = ({item }: any) => {
  return (
    <video
      style={{
        width: "100%",
        height: "auto",
        objectFit: "cover",
        borderRadius: "8px",
      }}
      controls
      controlsList="nodownload"
    >
      {videoExtensions.map((ext, index) => (
        <source key={index} src={item} type={getMimeType(ext)} />
      ))}
    </video>
  );
};

export default DisplayVideo;
