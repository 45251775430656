import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Chip,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import CustomButton from "src/components/CustomButton";
import backButton from "../../Images/backButton.svg";
interface SpinnerItem {
  couponId: string;
  image: string;
  bgColor: string;
  textColor: string;
  probability: number;
  isOpps: boolean;
}

interface SpinWheels {
  _id: string;
  name: string;
  startDate: string;
  endDate: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  spinner: SpinnerItem[];
}

interface SpinWheelDetailsProps {
  wheelData: (SpinWheels | null) & {
    noOfDataAfterWin?: number;
    currentSlug?: string;
    selectRewardRow?: Array<{
      date: string;
      slug: string;
    }>;
  };
  onClose: () => void;
}

const SpinWheelDetails: React.FC<SpinWheelDetailsProps> = ({
  wheelData,
  onClose,
}) => {
  if (!wheelData) {
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          margin: "auto",
          p: 4,
          textAlign: "center",
          bgcolor: "background.paper",
          boxShadow: 3,
        }}
      >
        <Typography variant="h5" color="error">
          No Spin Wheel Data Available
        </Typography>
        <Box mt={3} display="flex" justifyContent="center">
          <CustomButton onClick={onClose} variant="outlined" color="primary">
            Close
          </CustomButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1200px",
        margin: "auto",
        p: 4,
        borderRadius: 2,
        bgcolor: "background.paper",
        boxShadow: 3,
        maxHeight: "80vh",
        overflowY: "auto",
      }}
    >
      <Typography variant="h4" color="primary" textAlign="center" gutterBottom>
        Spin Wheel Details
      </Typography>

      {/* Basic Information */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            <strong>Name:</strong> {wheelData?.name}
          </Typography>
          <Typography variant="body1">
            <strong>Slug:</strong> {wheelData?.slug}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            <strong>Start Date:</strong>{" "}
            {format(new Date(wheelData?.startDate), "PP")}
          </Typography>
          <Typography variant="body1">
            <strong>End Date:</strong>{" "}
            {format(new Date(wheelData?.endDate), "PP")}
          </Typography>
        </Grid>
      </Grid>

      {/* Spinner Items */}
      <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
        Spinner Items
      </Typography>
      <Grid container spacing={2}>
        {wheelData?.spinner?.map((item, index) => (
          <Grid item xs={12} sm={6} key={item.couponId || index}>
            <Card sx={{ display: "flex", height: "100%" }}>
              {item.image && (
                <CardMedia
                  component="img"
                  sx={{ width: 150, height: 150, objectFit: "cover" }}
                  image={item.image}
                  alt={`Spinner Item ${index + 1}`}
                />
              )}
              <CardContent sx={{ flex: 1 }}>
                <Typography variant="subtitle1">
                  Spinner Item {index + 1}
                </Typography>
                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                  <Chip
                    label={`Background: ${item.bgColor}`}
                    size="small"
                    style={{ backgroundColor: item.bgColor }}
                  />
                  <Chip
                    label={`Text: ${item.textColor}`}
                    size="small"
                    style={{ backgroundColor: item.textColor }}
                  />
                </Box>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <strong>Is Opportunity:</strong> {item.isOpps ? "Yes" : "No"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Additional Details */}
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Additional Information</Typography>
          {wheelData.noOfDataAfterWin !== undefined && (
            <Typography variant="body1">
              <strong>Number of Data After Win:</strong>{" "}
              {wheelData.noOfDataAfterWin}
            </Typography>
          )}
          {wheelData.currentSlug && (
            <Typography variant="body1">
              <strong>Current Slug:</strong> {wheelData?.currentSlug}
            </Typography>
          )}
          <Typography variant="body1">
            <strong>Created At:</strong>{" "}
            {format(new Date(wheelData.createdAt), "PP")}
          </Typography>
          <Typography variant="body1">
            <strong>Updated At:</strong>{" "}
            {format(new Date(wheelData.updatedAt), "PP")}
          </Typography>
        </Grid>

        {wheelData.selectRewardRow && wheelData.selectRewardRow.length > 0 && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Reward Rows</Typography>
            {wheelData.selectRewardRow.map((row, index) => (
              <Typography key={index} variant="body1">
                <strong>Date:</strong> {format(new Date(row.date), "PP")}
                {" | "}
                <strong>Slug:</strong> {row.slug}
              </Typography>
            ))}
          </Grid>
        )}
      </Grid>

      <Box mt={3} display="flex" justifyContent="center">
        <CustomButton onClick={onClose} variant="outlined" color="primary">
          Close
        </CustomButton>
      </Box>
    </Box>
  );
};

export default SpinWheelDetails;
