import React from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Chip,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface DealViewPageProps {
  dealData: any;
  businessProfile?: {
    businessName?: string;
    brandLogo?: string;
  } | undefined;
  onClose: () => void;
}

const DealViewPage: React.FC<DealViewPageProps> = ({
  dealData,
  businessProfile,
  onClose,
}) => {
  if (!dealData) {
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "1500px",
          margin: "auto",
          mt: "10%",
          p: 3,
          textAlign: "center",
          borderRadius: 2,
          boxShadow: 3,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="h5" color="primary" fontWeight="bold" mb={2}>
          Deal Details
        </Typography>
        <Typography variant="body1" color="textSecondary">
          No deal data available.
        </Typography>
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
          sx={{ mt: 3 }}
        >
          Go Back
        </Button>
      </Box>
    );
  }

  const {
    title,
    category,
    subCategoryId,
    area,
    offer,
    offerType,
    description,
    termsAndConditions,
    duration,
    discountBracket,
    repeatDuration,
    image,
    slug,
    clickCount,
    activityStatus,
    deleted,
    metaTitle,
    metaDescription,
    isPromoted,
    validFor,
    discountType,
    discountPrice,
    currentPrice,
    discountPercentage,
    startDate,
    endDate,
    startTime,
    endTime,
    days,
    daysNote,
  } = dealData;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1200px",
        margin: "auto",
        mt: 5,
        p: 3,
        borderRadius: 2,
        bgcolor: "background.paper",
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color="primary" fontWeight="bold">
          Deal Detail
        </Typography>
        <IconButton onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </Box>

      <Grid container spacing={3}>
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <CardContent>
          {businessProfile && (
            <Typography variant="h6" fontWeight="bold">
            <strong>Business Name : </strong> {businessProfile.businessName}
          </Typography>
          )}
            <Typography fontWeight="bold" gutterBottom>
            <strong>Deals Title : </strong> {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Slug : </strong> {slug}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Category : </strong> {category}
            </Typography>
            <Chip
              label={`${discountPrice} ₹`}
              color="primary"
              sx={{ mt: 2, fontSize: "0.9rem", fontWeight: "bold" }}
            />
            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              <strong>Offer Type : </strong> {discountType}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              <strong>Current Price : </strong> {discountType === "PERCENTAGE" ? `₹${currentPrice} (${discountPercentage}%)`
                : "-"}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Discount Price : </strong>{discountPrice}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Description : </strong> {description}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              <strong>Area : </strong> {area}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Start Date : </strong>{startDate ? new Date(startDate).toLocaleDateString() : "No Start Date"}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>End Date : </strong> {endDate ? new Date(endDate).toLocaleDateString() : "No End Date"}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Start Time : </strong>{startTime}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>End Time : </strong>{endTime}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Click Count : </strong> {clickCount}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Activity Status : </strong> {activityStatus}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Deleted : </strong> {deleted ? "Yes" : "No"}
            </Typography>
          </CardContent>
        </Grid>

        {/* Right Section */}
        {businessProfile && (
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              image={businessProfile?.brandLogo || "https://via.placeholder.com/500x300"}
              alt="Deal Image"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: 2,
              }}
            />
          </Grid>
        )}
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Box display="flex" justifyContent="flex-end">
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default DealViewPage;
