import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  CircularProgress,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import CustomTable from "src/components/CustomTable";
import { GET_SPIN_REWARD_BY_ID } from "src/graphql/query";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import HowToRegIcon from '@mui/icons-material/HowToReg';

const SpinRewardView = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [userSpinData, setUserSpinData] = useState([]);
  const [userWinningData, setUserWinningData] = useState([]);
  const [giftData, setGiftData] = useState([]);
  const [pagination, setPagination] = React.useState({ page: 0, pageSize: 50 });
  const [couponDetails, setCouponDetails] = useState([]);


  const id = window.location.pathname.split("/")[2];
  const { data, loading, error, refetch } = useQuery(GET_SPIN_REWARD_BY_ID, {
    variables: { _id: id },
  });

  useEffect(() => {
    if (data?.getSpinReward?.data) {
      setUserSpinData(data.getSpinReward.data.userSpinEntry || []);
      setUserWinningData(data.getSpinReward.data.userWinningEntry || []);
      setGiftData(data.getSpinReward.data.gift || []);

      // Compute active and remaining coupons
      const winItems = data.getSpinReward.data.winItems || [];
      const userWinningEntry = data.getSpinReward.data.userWinningEntry || [];

      const activeCouponsCount: Record<string, number> = {};
      userWinningEntry.forEach((entry: any) => {
        activeCouponsCount[entry.couponId] = (activeCouponsCount[entry.couponId] || 0) + 1;
      });

      const processedCoupons = winItems.map((item: any) => {
        const activeCount = activeCouponsCount[item.couponId] || 0;
        return {
          couponName: item.couponName,
          total: item.quantity,
          active: activeCount,
          remaining: Math.max(item.quantity - activeCount, 0),
        };
      });

      setCouponDetails(processedCoupons);
    }
  }, [data]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">Error: {error.message}</Typography>
      </Box>
    );
  }

  const handleTabChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const getRowId = (row: any) => row?.id || `row-${Math.random()}`;
  const columns = {
    userSpin: [
      {
        headerName: "User", field: "firstName", flex: 1,
        renderCell: ({ row }: { row: any }) => {
          return <span>{`${row.firstName} ${row.lastName || ""}`.trim()}</span>;
        }
      },
      { headerName: "Spin Number", field: "no", flex: 1 },
      {
        headerName: "Time", field: "time", flex: 1,
        renderCell: ({ value }: { value: string }) => {
          if (!value) return <span>-</span>; // Handle empty values

          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("en-GB"); // DD/MM/YYYY
          let formattedTime = date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }).replace(/am|pm/i, (match) => match.toUpperCase()); // AM/PM in uppercase

          return <span>
            <Typography variant="body2" color="textPrimary">{formattedDate}</Typography>
            <Typography variant="body2" color="textSecondary">{formattedTime}</Typography>
          </span>;
        },
      },
      { headerName: "Already Won", field: "isAlreadyWin", flex: 1 },
    ],
    userWinning: [
      {
        headerName: "User", field: "firstName", flex: 1,
        renderCell: ({ row }: { row: any }) => {
          return <span>{`${row.firstName}  ${row.lastName || ""}`.trim()}</span>;
        }
      },
      { headerName: "Coupon", field: "couponName", flex: 1 },
      { headerName: "Coupon Code", field: "couponCode", flex: 1 },
      {
        headerName: "Winning Time", field: "winningTime", flex: 1,
        renderCell: ({ value }: { value: string }) => {
          if (!value) return <span>-</span>; // Handle empty values

          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("en-GB"); // DD/MM/YYYY
          let formattedTime = date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }).replace(/am|pm/i, (match) => match.toUpperCase()); // AM/PM in uppercase

          return <span>
            <Typography variant="body2" color="textPrimary">{formattedDate}</Typography>
            <Typography variant="body2" color="textSecondary">{formattedTime}</Typography>
          </span>;
        },
      },
    ],
  };

  return (
    <Box p={2}>
      <Typography variant="h4" sx={{ color: "#00C5B9", mb: 2 }}>
        Spin Reward Details
      </Typography>
      <Typography variant="h5" sx={{ color: "#555555" }}>
        <strong style={{ color: "#000000" }}>Spiner Slug :</strong> {data?.getSpinReward?.data?.slug}
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Box mt={3} p={2} bgcolor="#f9f9f9" borderRadius={2} boxShadow={1}>
          <Typography variant="h6" sx={{ color: "#00C5B9", textAlign: "center", mb: 2 }}>
            Active & Remaining Coupons
          </Typography>
          {couponDetails.length > 0 ? (
            <Box display="flex" gap={2}>
              {couponDetails.map((coupon: any, index: number) => (
                <Box
                  key={index}
                  p={2}
                  border="1px solid #ddd"
                  borderRadius={2}
                  boxShadow={1}
                  textAlign="center"
                  bgcolor="white"
                >
                  <Typography sx={{ color: "#000000", fontSize: "1.1rem", fontWeight: "bold" }}>
                    {coupon.couponName || "N/A"}
                  </Typography>
                  <Typography sx={{ color: "#00C5B9", fontSize: "1rem", mt: 1 }}>
                    Active : <strong>{coupon.active}</strong>
                  </Typography>
                  <Typography sx={{ color: "#FF5733", fontSize: "1rem", mt: 1 }}>
                    Remaining : <strong>{coupon.remaining}</strong>
                  </Typography>
                  <Typography sx={{ color: "#000000", fontSize: "1rem", mt: 1 }}>
                    Total : <strong>{coupon.total}</strong>
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography textAlign="center" sx={{ mt: 2 }}>No Coupons Available</Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" mt={3} sx={{ color: "#555555" }}>
        <Typography><strong style={{ color: "#000000" }}>Assumption User : </strong> {data?.getSpinReward?.data?.assumptionUser || ""}</Typography>
        <Typography><strong style={{ color: "#000000" }}>Win Quantity : </strong> {data?.getSpinReward?.data?.winQuantity || ""}</Typography>
        <Typography><strong style={{ color: "#000000" }}>Shift Value : </strong> {data?.getSpinReward?.data?.shiftValue || ""}</Typography>
        <Typography><strong style={{ color: "#000000" }}>User Spin Entry : </strong> {userSpinData.length || ""}</Typography>
        <Typography><strong style={{ color: "#000000" }}>User Winning Entry : </strong> {userWinningData.length || ""}</Typography>
        <Typography><strong style={{ color: "#000000" }}>Gift : </strong> {giftData.length || ""}</Typography>
      </Box>


      <Divider />
      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mt: 2 }}>
        <Tab label={`User Spin Entry (${userSpinData.length})`} />
        <Tab label={`User Winning Entry (${userWinningData.length})`} />
        <Tab label={`Gift (${giftData.length})`} />
      </Tabs>
      <Box mt={3}>
        {activeTab === 0 &&
          <CustomTable
            columns={columns.userSpin}
            data={userSpinData}
            getRowId={getRowId}
            paginationModel={pagination}
            totalCount={0}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />}
        {activeTab === 1 &&
          <CustomTable
            columns={columns.userWinning}
            data={userWinningData}
            getRowId={getRowId}
            paginationModel={pagination}
            totalCount={0}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />}
        {activeTab === 2 && (
          <Box display="flex" flexWrap="wrap" gap={2} mt={2} justifyContent="center">
            {giftData.length > 0 ? (
              giftData.map((gift: any, index: number) => (
                <Box
                  key={index}
                  p={2}
                  border="1px solid #ddd"
                  borderRadius={2}
                  boxShadow={1}
                  width="400px"
                  minWidth="200px"
                  textAlign="left"
                >
                  {/* <Typography variant="h6">Gift #{index + 1}</Typography> */}
                  <Box display="flex" flexWrap="wrap" gap={2} mt={2} justifyContent="space-between">
                    <Typography variant="h6">
                      Gift #{index + 1}
                    </Typography>
                    <>
                      {gift.couponStatus === "USED" ? (
                        <Tooltip title="Used">
                          <IconButton aria-label="status" style={{ color: "#0ab504", padding:"0px" }}>
                            <HowToRegIcon style={{fontSize:"30px"}} />
                          </IconButton>
                        </Tooltip>
                      ) : gift.couponStatus === "ACTIVE" ? (
                        <Tooltip title="Active">
                          <IconButton aria-label="status" style={{ color: "#ff9966", padding:"0px" }}>
                            <TaskAltIcon style={{fontSize:"30px"}}/>
                          </IconButton>
                        </Tooltip>
                      ) : gift.couponStatus === "ALLOCATE" ? (
                        <Tooltip title="Allocate">
                          <IconButton aria-label="status" style={{ color: "#869986", padding:"0px" }}>
                            <WarningIcon style={{fontSize:"30px"}}/>
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Expired">
                          <IconButton aria-label="status" style={{ color: "#ff0000", padding:"0px" }}>
                            <CloseIcon style={{fontSize:"30px"}}/>
                          </IconButton>
                        </Tooltip>
                      )}
                    </>

                  </Box>
                  <Typography sx={{ color: "#555555" }}><strong style={{ color: "#000000" }}>Gift Number :</strong> {gift.no}</Typography>
                  <Typography sx={{ color: "#555555" }}><strong style={{ color: "#000000" }}>Coupon Name :</strong> {gift.couponName}</Typography>
                  <Typography sx={{ color: "#555555" }}><strong style={{ color: "#000000" }}>Coupon Code :</strong> {gift.couponCode}</Typography>
                  <Typography sx={{ color: "#555555" }}><strong style={{ color: "#000000" }}> User Name   :</strong> {`${gift.firstName || "N/A"} ${gift.lastName || ""}`.trim()}</Typography>
                </Box>
              ))
            ) : (
              <Typography>No gifts available</Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SpinRewardView;
