import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_BUSINESS_PROFILE,
  UPDATE_BUSINESS_PROFILE,
} from "src/graphql/mutations";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { uploadImage, uploadImages } from "src/components/Common/Utils";
import { GET_ALL_SUBCATEGORIES, GET_BUSINESS_PROFILE } from "src/graphql/query";
import {
  // addressInfoValidationSchema,
  BasicInfoValidationSchema,
  businessTimingValidationSchema,
  FormValues,
  Services,
  SpecialServices,
} from "./Business";
import backButton from "../../Images/backButton.svg";
import BasicInfo from "./BasicInfo";
import Loader from "src/components/Loader";
import { images } from "mammoth";
import { videoExtensions } from "src/constant";
import BusinessTiming from "./BusinessTIming";
interface Timing {
  day: string;
  startTime: string;
  endTime: string;
  __typename?: string;
}

interface PinCode {
  _id: string;
  areaName?: string;
  code?: string;
}

const BusinessModal = ({ onClose }: any) => {
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState<any>(null);
  const [categoryId, setCategoryId] = useState<string>("");
  const [timings, setTimings] = useState<Timing[]>([]);
  const [services, setServices] = useState<Services[]>([{ value: "" }]);
  const [specialServices, setSpecialServices] = useState<SpecialServices[]>([{ value: "" }]);
  const [activeStep, setActiveStep] = useState(0);
  const [createBusinessProfile] = useMutation(CREATE_BUSINESS_PROFILE);
  const [updateBusinessProfile] = useMutation(UPDATE_BUSINESS_PROFILE);
  const [gallaryImages, setGallaryImages] = useState<any[]>([]);
  const [documents, setDocuments] = useState<any[]>([]);
  const [draggingIndex, setDraggingIndex] = useState<any>(null);
  // const [showOwnerDetails, setShowOwnerDetails] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [businessImages, setbusinessImages] = useState<any[]>([]);
  const [mainImageFile, setMainImageFile] = useState<File | null>(null);
  const [mainImageUrl, setMainImageUrl] = useState<string | null>(null);
  const [bgImageFile, setBgImageFile] = useState<File | null>(null);
  const [bgImageUrl, setBgImageUrl] = useState<string | null>(null);
  const [slug, setSlug] = useState<string | null>(null);
  const [
    fetchBusinessProfile,
    { data: businessData, loading: businessLoading },
  ] = useLazyQuery(GET_BUSINESS_PROFILE);
  const { data } = useQuery(GET_ALL_SUBCATEGORIES, {
    notifyOnNetworkStatusChange: true,
  });
  const subCategoryData = data?.getAllSubCategories?.data;
  useEffect(() => {
    const fetchData = async () => {
      if (typeof window !== "undefined" && window.location.pathname) {
        const pathname = window.location.pathname;
        const id = pathname.includes("/create")
          ? null
          : pathname.split("/").slice(3, 4)[0];

        if (id) {
          try {
            const { data: fetchedBusinessData } = await fetchBusinessProfile({
              variables: { slug: id },
            });
            const slug =
              fetchedBusinessData?.getBusinessProfilebyslug?.data?.slug;
            setSlug(slug);
            setMainImageUrl(
              fetchedBusinessData?.getBusinessProfilebyslug?.data?.brandLogo
            );
            setBusiness(fetchedBusinessData?.getBusinessProfilebyslug?.data);
            const galleries =
              fetchedBusinessData?.getBusinessProfilebyslug?.data?.image || [];
            setGallaryImages(galleries || []);
            setDocuments(fetchedBusinessData?.getBusinessProfilebyslug?.data?.documents || []);


            setTimings(
              fetchedBusinessData?.getBusinessProfilebyslug?.data?.bussinessTiming || []
            );
            setServices(
              fetchedBusinessData?.getBusinessProfilebyslug?.data?.services || []
            );
            setSpecialServices(
              fetchedBusinessData?.getBusinessProfilebyslug?.data?.specialServices || []
            );
          } catch (error) {
            console.error("Error fetching business profile:", error);
          }
        } else {
          setBusiness(null);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, [fetchBusinessProfile]);
  const [initialValues, setInitialValues] = useState({
    businessName: "",
    primarySubCategory: "",
    additionalSubCategories: [],
    brandName: "",
    brandLogo: "",
    website: "",
    businessEmail: "",
    documents: [],
    managedBy: "",
    mobileNumber: "",
    metaTitle: "",
    metaDescription: "",
    focus: "",
    socialMedia: [],
    owners: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "",
      designation: "",
    },
    businessAddress: "",
    pinCode: "",
    googleMapLink: "",
    about: "",
    gallery: [],
    bussinessTiming: [
      {
        day: "",
        startTime: "",
        endTime: "",
        others: "",
      },
    ],
    services: [
      {
        value: "",
      },
    ],
    specialServices: [
      {
        value: "",
      },
    ],
    isPromoted: false,
  });
  useEffect(() => {
    if (business?.images) {
      setbusinessImages(business?.images);
    }
  }, [business]);
  useEffect(() => {
    if (business) {
      setInitialValues({
        businessName: business?.businessName || "",
        primarySubCategory: business?.primarySubCategory?._id || "",
        additionalSubCategories: business
          ? business?.additionalSubCategories?.map(
            (subCategory: any) => subCategory._id
          ) || []
          : [],
        brandName: business?.brandName || "",
        brandLogo: business?.brandLogo || "",
        website: business?.website || "",
        businessEmail: business?.businessEmail || "",
        documents: business?.documents?.[0]?.image ?? [],
        managedBy: business?.managedBy || "",
        mobileNumber: business?.mobileNumber || "",
        metaTitle: business?.metaTitle || "",
        metaDescription: business?.metaDescription || "",
        focus: business?.focus || "",
        socialMedia: business?.socialMedia || [],
        owners: business?.owners
          ? {
            firstName: business?.owners[0]?.firstName,
            lastName: business?.owners[0]?.lastName,
            mobileNumber: business?.owners[0]?.mobileNumber,
            email: business?.owners[0]?.email,
            designation: business?.owners[0]?.designation,
          }
          : {
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            designation: "",
          },
        // addresses: addressesWithPinCodes,
        businessAddress: business?.businessAddress || "",
        pinCode: business?.pinCode || "",
        googleMapLink: business?.googleMapLink || "",
        about: business?.about || "",
        gallery: business?.galleries?.[0]?.image ?? [],
        bussinessTiming: business?.bussinessTiming?.[0] || [
          {
            day: "",
            startTime: "",
            endTime: "",
            others: "",
          },
        ],
        services: business?.services?.[0] || [
          {
            value: "",
          },
        ],
        specialServices: business?.specialServices?.[0] || [
          {
            value: "",
          },
        ],
        // overview: null,
        isPromoted: business?.isPromoted || false,
      });
    }
  }, [business]);
  // Handle drag start
  const handleDragStart = (index: number) => {
    setDraggingIndex(index);
  };

  // Handle drag over
  const handleDragOver = (event:React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleDrop = (index: number) => {
    if (draggingIndex === null) return;

    const updatedImages = [...gallaryImages];
    const [movedImage] = updatedImages.splice(draggingIndex, 1);
    updatedImages.splice(index, 0, movedImage);

    updatedImages.forEach((image) => {
      if (image.imageUrl) {
        URL.revokeObjectURL(image.imageUrl);
      }
    });
    setGallaryImages(updatedImages);
    setDraggingIndex(null);
  };

  const handleNext = async (values: any) => {

    const formValues = {
      ...values,
      services,
      specialServices
    };

    let validationSchema;

    switch (activeStep) {
      case 0:
        validationSchema = BasicInfoValidationSchema;
        break;
      case 2:
        validationSchema = businessTimingValidationSchema;
        break;
      default:
        break;
    }

    try {
      validationSchema &&
        (await validationSchema.validate(values, { abortEarly: false }));
      setActiveStep((prevStep) => prevStep + 1);
    } catch (error: any) {
      console.error(error);
      error?.inner?.forEach((err: any) => {
        toast.error(err.message);
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleImageChange = async (
    field: string,
    file: File | null,
    setFieldValue: (field: string, value: any) => void,
    title: string
  ) => {
    if (file) {
      try {
        const data = await uploadImage(file, "business", title);
        const imageUrl = data?.uploadImage?.url;
        if (imageUrl) {
          setFieldValue(field, imageUrl);
        }
      } catch (error) {
        toast.error("Error uploading image. Please try again.");
      }
    } else {
      setFieldValue(field, null);
    }
  };

  const cleanUpTimeString = (time: string): string => {
    return time
      .replace(/\s*(AM|PM)\s*/gi, " $1")
      .replace(/(AM|PM)\s*(AM|PM)+/gi, "$1")
      .trim();
  };

  const formatTimeToAmPm = (time: string): string => {
    const cleanedTime = cleanUpTimeString(time);
    const [hourPart, minutePartWithAmPm] = cleanedTime.split(":");

    if (!hourPart || !minutePartWithAmPm) {
      throw new Error(`Invalid time format: ${time}`);
    }

    const hours = parseInt(hourPart, 10);
    const minutePart = minutePartWithAmPm.slice(0, 2);
    const isPm = cleanedTime.toUpperCase().includes("PM");
    const ampm = isPm ? "PM" : "AM";

    const formattedHour = hours % 12 || 12;
    return `${formattedHour}:${minutePart} ${ampm}`;
  };

  const handleSubmit = async (values: FormValues) => {
    try {

      setIsLoading(true);

      const formValues = {
        ...values,
        about: values.about?.trim() || "",
        services: services
          .filter(service => service.value.trim() !== "")
          .map(({ __typename, ...rest }) => rest),  // Remove __typename

        specialServices: specialServices
          .filter(service => service.value.trim() !== "")
          .map(({ __typename, ...rest }) => rest),  // Remove __typename
      };

      const hasOwnerDetails = 
      values.owners?.firstName ||
      values.owners?.lastName ||
      values.owners?.mobileNumber ||
      values.owners?.email ||
      values.owners?.designation;

    if (!hasOwnerDetails) {
      if (formValues && typeof formValues === "object") {
        delete (formValues as Record<string, unknown>).owners;
      }
    }
      const images = await Promise.all(
        gallaryImages.map(async (image) => {
          if (image.startsWith("blob:")) {
            const uploadedImages = await uploadImages(
              [image],
              "business",
              values?.businessName
            );
            return uploadedImages?.uploadMultipleImages?.[0];
          }
          return image;
        })
      );


      // Clean bussinessTiming by removing `__typename`
      if (timings) {
        values.bussinessTiming = timings.map(
          ({ __typename, startTime, endTime, ...rest }) => ({
            ...rest,
            startTime: startTime ? formatTimeToAmPm(startTime) : "",
            endTime: endTime ? formatTimeToAmPm(endTime) : "",
          })
        );
      }

      if (values?.socialMedia) {
        values.socialMedia = values.socialMedia.map(({ __typename, ...rest }) => ({ ...rest }));
      }
      let brandlogoImageUrl = mainImageUrl;
      if (mainImageFile) {
        const res = await uploadImage(
          mainImageFile,
          "businessLogo",
          values.businessName
        );
        brandlogoImageUrl = res?.uploadImage?.url;
      }
      brandlogoImageUrl = brandlogoImageUrl || "";

      // Prepare variables for the mutation
      const variables = {
        input: {
          ...formValues,
          isPromoted: values?.isPromoted,
          brandLogo: brandlogoImageUrl,
          bussinessTiming: values?.bussinessTiming,
          socialMedia: values?.socialMedia,
          documents, // Empty documents for now
          gallery: { image: images }, // Use the uploaded or existing gallery images
        },
      };


      // Destructure primarySubCategory out of variables
      const { ...rest } = variables?.input;

      // Perform the mutation for update or create
      const res = business
        ? await updateBusinessProfile({
          variables: {
            _id: business?._id,
            input: rest,
          },
        })
        : await createBusinessProfile({ variables });
      // Handle success responses
      if (
        res?.data?.createBusinessProfile?.statusCode === 200 ||
        res?.data?.updateBusinessProfileDetails?.statusCode === 200
      ) {
        toast.success(
          res?.data?.createBusinessProfile?.message ||
          res?.data?.updateBusinessProfileDetails?.message ||
          "Business profile added successfully"
        );
        // fetchBusinessProfile();
        const updatedData = await fetchBusinessProfile({
          variables: { _id: business?._id },
        });

        setBusiness(updatedData?.data?.getBusinessProfilebyslug?.data);
        onClose;
      } else {
        throw new Error(
          (res?.data?.updateBusinessProfileDetails?.message) ||
          (res?.data?.createBusinessProfile?.message)
        );
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimingChange = (
    index: number,
    field: keyof any,
    value: string
  ) => {
    const updatedTimings = [...timings];
    updatedTimings[index] = { ...updatedTimings[index], [field]: value };
    setTimings(updatedTimings);
  };

  const addNewTimingField = () => {
    if (timings.length < 7) {
      setTimings([...timings, { day: "", startTime: "", endTime: "" }]);
    }
  };
  const removeTimingField = (index: number) => {
    const updatedTimings = timings.filter((_, i) => i !== index);
    setTimings(updatedTimings);
  };
  const usedDays = timings.map((bussinessTiming) => bussinessTiming.day);

  const handleAddBannerImage = () => {
    const imageCount = gallaryImages.filter(
      (img) =>
        typeof img === "string" &&
        (img.endsWith(".jpg") ||
          img.endsWith(".png") ||
          img.endsWith(".jpeg") ||
          img.endsWith(".webp"))
    ).length;

    const videoCount = gallaryImages.filter(
      (vid) =>
        typeof vid === "string" &&
        videoExtensions.some((ext) => vid.endsWith(ext))
    ).length;

    if (imageCount >= 15 && videoCount >= 2) {
      alert("You can upload a maximum of 15 images and 2 videos.");
      return;
    }
    if (imageCount >= 15) {
      alert("You have already added 15 images. You can only add upto 2 videos now, if you have videos.");
      // return;
    }

    setGallaryImages([...gallaryImages, null]);
  };

  const handleRemoveBannerImage = (index: number) => {
    const updatedBannerImages = [...gallaryImages];
    updatedBannerImages.splice(index, 1);
    setGallaryImages(updatedBannerImages);
  };

  const handleAddDocuments = () => {
    if (!Array.isArray(documents)) {
      console.error("documents is not an array");
      return;
    }

    setDocuments([...documents, null]);
  };

  const handleRemoveDocuments = (index: number) => {
    const updatedDocuments = [...documents];
    updatedDocuments.splice(index, 1);
    setDocuments(updatedDocuments);
  };

  const handleDocumentsChange = async (
    index: number,
    files: FileList | null,
    imageUrl: string | null,
    setFieldValue: (field: string, value: any) => void,
    title: string
  ) => {
    if (files) {
      const newFiles = Array.from(files);

      const uploadedImages = await uploadImages(newFiles, "business", title);
      const uploadedUrls = uploadedImages?.uploadMultipleImages?.map(
        (image: any) => image?.url
      );

      const updatedDocuments = [...documents];
      updatedDocuments[index] = uploadedUrls[0] || updatedDocuments[index];

      setDocuments(updatedDocuments);
    } else if (typeof imageUrl === "string" && imageUrl.startsWith("https")) {
      // If a valid URL is provided, use it directly
      const updatedDocuments = [...documents];
      updatedDocuments[index] = imageUrl;

      setDocuments(updatedDocuments);
    }
  };

  //BrndLogo
  const handleMainImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setMainImageFile(file);
      setMainImageUrl(URL.createObjectURL(file));
    }
  };

  //Banner Image function
  const handleBannerImageChange = async (
    index: number,
    files: FileList | null,
    imageUrl: string | null,
    setFieldValue: (field: string, value: any) => void,
    title: string
  ) => {
    if (files) {
      const newFiles = Array.from(files);

      const imageFiles = newFiles.filter((file) => file.type.startsWith("image/"));
      const videoFiles = newFiles.filter((file) => file.type.startsWith("video/"));

      // Check limits: max 5 images, max 2 videos
      const imageCount = gallaryImages.filter((img) =>
        typeof img === "string" && (img.endsWith(".jpg") || img.endsWith(".png") || img.endsWith(".jpeg") || img.endsWith(".webp"))
      ).length;
      const videoCount = gallaryImages.filter((vid) =>
        typeof vid === "string" && videoExtensions.some((ext) => vid.endsWith(ext))
      ).length;

      if (imageCount + imageFiles.length > 15) {
        alert("You can upload a maximum of 15 images.");
        return;
      }
      const maxVideoSizeMB = 30;

      const totalVideoSize = videoFiles.reduce((acc, file) => acc + file.size, 0) / (1024 * 1024); // Convert bytes to MB

      if (totalVideoSize > maxVideoSizeMB) {
        alert("Each video file must be 30MB or smaller.");
        return;
      }

      if (videoCount + videoFiles.length > 2) {
        alert("You can upload a maximum of 2 videos.");
        return;
      }

      const uploadedImages = await uploadImages(newFiles, "business", title);
      const uploadedUrls = uploadedImages?.uploadMultipleImages?.map(
        (image: any) => image?.url
      );

      const updatedGalleryImages = [...gallaryImages];
      updatedGalleryImages[index] = uploadedUrls[0] || updatedGalleryImages[index];

      setGallaryImages(updatedGalleryImages);
    } else if (typeof imageUrl === "string" && imageUrl.startsWith("https")) {
      // If a valid URL is provided, use it directly
      const updatedGalleryImages = [...gallaryImages];
      updatedGalleryImages[index] = imageUrl;

      setGallaryImages(updatedGalleryImages);
      setFieldValue(`gallery.image[${index}]`, imageUrl);
    }
  };

  const steps = [
    "Basic Information",
    // "Address Details",
    "Business Timings, Gallery & Media",
    // "Gallery & Media",
  ];
  return (
    <Box>
      <Box p={2}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={onClose}
          /> */}
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#00C5B9" }}
            marginTop={2}
          >
            {business ? "Update Business" : "Create Business"}
          </Typography>
        </Box>
        <Divider />
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ marginY: "12px" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // if (index < activeStep) {
                  //   setActiveStep(index);
                  // } else {
                  //   toast.error("You can not move forward on greater page!");
                  // }
                  setActiveStep(index);
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => {
            return (
              <Form>
                {/* Step 1: Business Basic Information */}
                {/* Business Name */}

                {activeStep === 0 && (
                  <BasicInfo
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    touched={touched}
                    onClose={onClose}
                    handleNext={() => handleNext(values)}
                    subCategoryData={subCategoryData}
                    setCategoryId={setCategoryId}
                    slug={slug}
                    isUpdateMode={Boolean(slug)}
                  />
                )}
                {/* Timings Management */}
                {activeStep === 1 && (
                  <BusinessTiming
                    values={values}
                    errors={errors}
                    setTimings={setTimings}
                    addNewTimingField={addNewTimingField}
                    timings={timings}
                    handleTimingChange={handleTimingChange}
                    removeTimingField={removeTimingField}
                    usedDays={usedDays}
                    // handleNext={() => handleNext(values)}
                    services={services}
                    setServices={setServices}
                    specialServices={specialServices}
                    setSpecialServices={setSpecialServices}
                    businessImages={businessImages}
                    handleImageChange={handleImageChange}
                    setFieldValue={setFieldValue}
                    gallaryImages={gallaryImages}
                    handleDragStart={handleDragStart}
                    handleDragOver={handleDragOver}
                    handleBannerImageChange={handleBannerImageChange}
                    handleDrop={handleDrop}
                    handleRemoveBannerImage={handleRemoveBannerImage}
                    handleChange={handleChange}
                    handleAddBannerImage={handleAddBannerImage}
                    handleSubmit={handleSubmit}
                    handleBack={handleBack}
                    loading={isLoading}
                    handleMainImageChange={handleMainImageChange}
                    setMainImageUrl={setMainImageUrl}
                    setMainImageFile={setMainImageFile}
                    mainImageUrl={mainImageUrl}
                    documents={documents}
                    handleAddDocuments={handleAddDocuments}
                    handleRemoveDocuments={handleRemoveDocuments}
                    handleDocumentsChange={handleDocumentsChange}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default BusinessModal;
