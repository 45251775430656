import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_BUSINESS_PROFILE,
  UPDATE_EXTRA_BUSINESS_PROFILE,
} from "src/graphql/mutations";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { uploadImage, uploadImages } from "src/components/Common/Utils";
import {
  GET_ALL_SUBCATEGORIES,
  GET_EXTRA_BUSINESS_PROFILE_SLUG,
} from "src/graphql/query";
import {
  BasicInfoValidationSchema,
  businessTimingValidationSchema,
  FormValues,
} from "./../Business/Business";
import BasicInfo from "./../Business/BasicInfo";
import AddressInfo from "./../Business/AddressInfo";
import BusinessTIming from "./../Business/BusinessTIming";
import BusinessImages from "./../Business/BusinessImages";
interface Timing {
  day: string;
  startTime: string;
  endTime: string;
  __typename?: string;
}
interface PinCode {
  _id: string;
  areaName?: string;
  code?: string;
}

interface Address {
  address: string;
  buildingOrShopNumber: string;
  googleMapLink: string;
  landmark: string;
  neighborhood: string;
  pinCode: string | PinCode;
}

const steps = [
  "Basic Information",
  "Address Details",
  "Business Timings",
  "Gallery & Media",
];

const defaultValues = {
  businessName: "",
  primarySubCategory: "",
  additionalSubCategories: [],
  brandName: "",
  brandLogo: "",
  website: "",
  businessEmail: "",
  documents: [],
  managedBy: "",
  mobileNumber: "",
  metaTitle: "",
  metaDescription: "",
  focus: "",
  socialMedia: [],
  owners: {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    designation: "",
  },
  
  services: [
    {
      value: "",
    },
  ],
  specialServices: [
    {
      value: "",
    },
  ],
    businessAddress: "",
    pinCode: "",
    googleMapLink: "",
  gallery: [],
  timing: [
    {
      day: "",
      startTime: "",
      endTime: "",
      others: "",
    },
  ],
  overview: null,
  isPromoted: false,
};
const ExtraBusinessModal = ({ onClose }: any) => {
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState<any>(null);
  const [overviewJson, setOverviewJson] = useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<string>("");
  const [timings, setTimings] = useState<Timing[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [gallaryImages, setGallaryImages] = useState<any[]>([]);
  const [draggingIndex, setDraggingIndex] = useState<any>(null);
  const [showOwnerDetails, setShowOwnerDetails] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [businessImages, setbusinessImages] = useState<any[]>([]);
  const [mainImageFile, setMainImageFile] = useState<File | null>(null);
  const [mainImageUrl, setMainImageUrl] = useState<string | null>(null);
  const [slug, setSlug] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState(defaultValues);

  const [createBusinessProfile] = useMutation(CREATE_BUSINESS_PROFILE);
  const [updateExtraBusinessProfile] = useMutation(
    UPDATE_EXTRA_BUSINESS_PROFILE
  );
  const [
    fetchBusinessProfile,
    { data: businessData, loading: businessLoading },
  ] = useLazyQuery(GET_EXTRA_BUSINESS_PROFILE_SLUG);
  const { data } = useQuery(GET_ALL_SUBCATEGORIES, {
    notifyOnNetworkStatusChange: true,
  });

  const subCategoryData = data?.getAllSubCategories?.data;
  const fetchData = async () => {
    if (typeof window !== "undefined" && window.location.pathname) {
      const pathname = window.location.pathname;
      const id = pathname.includes("/create")
        ? null
        : pathname.split("/").slice(3, 4)[0];

      if (id) {
        try {
          const { data: fetchedBusinessData } = await fetchBusinessProfile({
            variables: { slug: id, page: 1, limit: 100 },
          });
          const slug =
            fetchedBusinessData?.getExtraBusinessProfilebyslug?.data?.slug;
          setSlug(slug);
          setMainImageUrl(
            fetchedBusinessData?.getExtraBusinessProfilebyslug?.data?.brandLogo
          );
          setBusiness(fetchedBusinessData?.getExtraBusinessProfilebyslug?.data);

          const galleries =
            fetchedBusinessData?.getExtraBusinessProfilebyslug?.data?.image ||
            [];
          setGallaryImages(galleries || []);

          setTimings(
            fetchedBusinessData?.getExtraBusinessProfilebyslug?.data?.timing ||
              []
          );
        } catch (error) {
          console.error("Error fetching business profile:", error);
        }
      } else {
        setBusiness(null);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [fetchBusinessProfile]);

  useEffect(() => {
    if (business?.images) {
      setbusinessImages(business?.images);
    }
  }, [business]);

  useEffect(() => {
    if (business) {

      setInitialValues({
        businessName: business?.businessName || "",
        primarySubCategory: business?.primarySubCategory?._id || "",
        additionalSubCategories: business
          ? business?.additionalSubCategories?.map(
              (subCategory: any) => subCategory._id
            ) || []
          : [],

        brandName: business?.brandName || "",
        brandLogo: business?.brandLogo || "",
        website: business?.website || "",
        businessEmail: business?.businessEmail || "",
        documents: [],
        managedBy: business?.managedBy || "",
        mobileNumber: business?.mobileNumber || "",
        metaTitle: business?.metaTitle || "",
        metaDescription: business?.metaDescription || "",
        focus: business?.focus || "",
        socialMedia: business?.socialMedia || [],
        owners: business?.owners?.[0]
          ? {
              firstName: business.owners[0].firstName,
              lastName: business.owners[0].lastName,
              mobileNumber: business.owners[0].mobileNumber,
              email: business.owners[0].email,
              designation: business.owners[0].designation,
            }
          : {
              firstName: "",
              lastName: "",
              mobileNumber: "",
              email: "",
              designation: "",
            },
        // addresses: addressesWithPinCodes,
        businessAddress: business?.businessAddress || "",
        pinCode: business?.pinCode || "",
        googleMapLink: business?.googleMapLink || "",

        gallery: business?.galleries?.[0]?.image ?? [],
        timing: business?.timing || [
          {
            day: "",
            startTime: "",
            endTime: "",
            others: "",
          },
        ],
        services: business?.services?.[0] || [
          {
            value: "",
          },
        ],
        specialServices: business?.specialServices?.[0] || [
          {
            value: "",
          },
        ],
        overview: null,
        isPromoted: business?.isPromoted || false,
      });
    }
  }, [business]);

  const handleDragStart = (index: any) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (index: number) => {
    if (draggingIndex === null) return;

    const updatedImages = [...gallaryImages];
    const [movedImage] = updatedImages.splice(draggingIndex, 1);
    updatedImages.splice(index, 0, movedImage);

    updatedImages.forEach((image) => {
      if (image.imageUrl) {
        URL.revokeObjectURL(image.imageUrl);
      }
    });
    setGallaryImages(updatedImages);
    setDraggingIndex(null);
  };

  const handleNext = async (values: any) => {
    let validationSchema;

    switch (activeStep) {
      case 0:
        validationSchema = BasicInfoValidationSchema;
        break;
      case 1:
        validationSchema = businessTimingValidationSchema;
        break;
      default:
        break;
    }

    try {
      validationSchema &&
        (await validationSchema.validate(values, { abortEarly: false }));
      setActiveStep((prevStep) => prevStep + 1);
    } catch (error: any) {
      console.error(error);
      error?.inner?.forEach((err: any) => {
        toast.error(err.message);
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleImageChange = async (
    field: string,
    file: File | null,
    setFieldValue: (field: string, value: any) => void,
    title: string
  ) => {
    if (file) {
      try {
        const data = await uploadImage(file, "business", title);
        const imageUrl = data?.uploadImage?.url;
        if (imageUrl) {
          setFieldValue(field, imageUrl);
        }
      } catch (error) {
        toast.error("Error uploading image. Please try again.");
      }
    } else {
      setFieldValue(field, null);
    }
  };

  const cleanUpTimeString = (time: string): string => {
    return time
      .replace(/\s*(AM|PM)\s*/gi, " $1")
      .replace(/(AM|PM)\s*(AM|PM)+/gi, "$1")
      .trim();
  };

  const formatTimeToAmPm = (time: string): string => {
    const cleanedTime = cleanUpTimeString(time);
    const [hourPart, minutePartWithAmPm] = cleanedTime.split(":");

    if (!hourPart || !minutePartWithAmPm) {
      throw new Error(`Invalid time format: ${time}`);
    }

    const hours = parseInt(hourPart, 10);
    const minutePart = minutePartWithAmPm.slice(0, 2);
    const isPm = cleanedTime.toUpperCase().includes("PM");
    const ampm = isPm ? "PM" : "AM";

    const formattedHour = hours % 12 || 12;
    return `${formattedHour}:${minutePart} ${ampm}`;
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const images = await Promise.all(
        gallaryImages.map(async (image) => {
          if (image.startsWith("blob:")) {
            const uploadedImages = await uploadImages(
              [image],
              "business",
              values?.businessName
            );
            return uploadedImages?.uploadMultipleImages?.[0];
          }
          return image;
        })
      );

      if (timings) {
        values.timing = timings.map(
          ({ __typename, startTime, endTime, ...rest }) => ({
            ...rest,
            startTime: startTime ? formatTimeToAmPm(startTime) : "",
            endTime: endTime ? formatTimeToAmPm(endTime) : "",
          })
        );
      }

      if (values?.socialMedia) {
        values.socialMedia = values.socialMedia.map(
          ({ __typename, ...socialMediaWithoutTypename }) =>
            socialMediaWithoutTypename
        );
      }
      let brandlogoImageUrl = mainImageUrl;
      if (mainImageFile) {
        const res = await uploadImage(
          mainImageFile,
          "businessLogo",
          values.businessName
        );
        brandlogoImageUrl = res?.uploadImage?.url;
      }

      const variables: any = {
        input: {
          ...values,
          isPromoted: values?.isPromoted,
          brandLogo: brandlogoImageUrl,
          timing: values?.timing,
          documents: [],
          gallery: { image: images },
        },
      };

      if (showOwnerDetails) {
        variables.input.owners = {
          firstName: values?.owners?.firstName || "",
          lastName: values?.owners?.lastName || "",
          mobileNumber: values?.owners?.mobileNumber || "",
          email: values?.owners?.email || "",
          designation: values?.owners?.designation || "",
        };
      } else {
        const { owners, ...rest } = variables.input;
        variables.input = { ...rest };
      }
      const { ...rest } = variables?.input;
      const res = business
        ? await updateExtraBusinessProfile({
            variables: {
              _id: business?._id,
              input: rest,
            },
          })
        : await createBusinessProfile({ variables });
      if (
        res?.data?.createBusinessProfile?.statusCode === 200 ||
        res?.data?.updateExtraBusinessProfileDetails?.statusCode === 200
      ) {
        toast.success(
          res?.data?.createBusinessProfile?.message ||
            res?.data?.updateExtraBusinessProfileDetails?.message ||
            "Business profile added successfully"
        );
        const updatedData = await fetchBusinessProfile({
          variables: { _id: business?._id },
        });

        setBusiness(updatedData?.data?.getExtraBusinessProfilebyslug?.data);
        onClose;
      } else {
        throw new Error(
          (res?.data?.updateExtraBusinessProfileDetails?.message as any) ||
            (res?.data?.createBusinessProfile?.message as any)
        );
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimingChange = (
    index: number,
    field: keyof any,
    value: string
  ) => {
    const updatedTimings = [...timings];
    updatedTimings[index] = { ...updatedTimings[index], [field]: value };
    setTimings(updatedTimings);
  };

  const addNewTimingField = () => {
    if (timings.length < 7) {
      setTimings([...timings, { day: "", startTime: "", endTime: "" }]);
    }
  };

  const removeTimingField = (index: number) => {
    const updatedTimings = timings.filter((_, i) => i !== index);
    setTimings(updatedTimings);
  };

  const usedDays = timings.map((timing) => timing.day);

  const handleAddBannerImage = () => {
    setGallaryImages([...gallaryImages, null]);
  };

  const handleRemoveBannerImage = (index: number) => {
    const updatedBannerImages = [...gallaryImages];
    updatedBannerImages.splice(index, 1);
    setGallaryImages(updatedBannerImages);
  };

  const handleMainImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setMainImageFile(file);
      setMainImageUrl(URL.createObjectURL(file));
    }
  };

  const handleBannerImageChange = async (
    index: number,
    files: FileList | null,
    imageUrl: string | null,
    setFieldValue: (field: string, value: any) => void,
    title: string
  ) => {
    if (files) {
      const newFiles = Array.from(files);
      const uploadedImages = await uploadImages(newFiles, "business", title);
      const uploadedUrls = uploadedImages?.uploadMultipleImages?.map(
        (image: any) => image?.url
      );
      const updatedGalleryImages = [...gallaryImages];
      updatedGalleryImages[index] =
        uploadedUrls[0] || updatedGalleryImages[index];

      setGallaryImages(updatedGalleryImages);
    } else if (imageUrl?.startsWith("https")) {
      // If a valid URL is provided, use it directly
      const updatedGalleryImages = [...gallaryImages];
      updatedGalleryImages[index] = imageUrl;

      setGallaryImages(updatedGalleryImages);
      setFieldValue(`gallery.image[${index}]`, imageUrl);
    }
  };

  return (
    <Box>
      <Box p={2}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#00C5B9" }}
            marginTop={2}
          >
            {business ? "Update ExtraBusiness" : "Create Business"}
          </Typography>
        </Box>
        <Divider />
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ marginY: "12px" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveStep(index);
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue }: any) => {

            return (
              <Form>
                {activeStep === 0 && (
                  <BasicInfo
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    touched={touched}
                    onClose={onClose}
                    handleNext={() => handleNext(values)}
                    showOwnerDetails={showOwnerDetails}
                    setShowOwnerDetails={setShowOwnerDetails}
                    slug={slug}
                    isUpdateMode={Boolean(slug)}
                    subCategoryData={subCategoryData}
                    setCategoryId={setCategoryId}
                  />
                )}

                {activeStep === 1 && (
                  <AddressInfo
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBack={handleBack}
                    setOverviewJson={setOverviewJson}
                    subCategoryData={subCategoryData}
                    setCategoryId={setCategoryId}
                    handleNext={() => handleNext(values)}
                  />
                )}

                {activeStep === 2 && (
                  <BusinessTIming
                    errors={errors}
                    setTimings={setTimings}
                    addNewTimingField={addNewTimingField}
                    timings={timings}
                    handleTimingChange={handleTimingChange}
                    removeTimingField={removeTimingField}
                    usedDays={usedDays}
                    overviewJson={overviewJson}
                    business={business}
                    categoryId={categoryId}
                    setFieldValue={setFieldValue}
                    handleBack={handleBack}
                    handleNext={() => handleNext(values)}
                  />
                )}

                {activeStep === 3 && (
                  <BusinessImages
                    values={values}
                    businessImages={businessImages}
                    handleImageChange={handleImageChange}
                    setFieldValue={setFieldValue}
                    gallaryImages={gallaryImages}
                    handleDragStart={handleDragStart}
                    handleDragOver={handleDragOver}
                    handleBannerImageChange={handleBannerImageChange}
                    handleDrop={handleDrop}
                    handleRemoveBannerImage={handleRemoveBannerImage}
                    handleChange={handleChange}
                    handleAddBannerImage={handleAddBannerImage}
                    handleSubmit={handleSubmit}
                    handleBack={handleBack}
                    loading={isLoading}
                    handleMainImageChange={handleMainImageChange}
                    setMainImageUrl={setMainImageUrl}
                    setMainImageFile={setMainImageFile}
                    mainImageUrl={mainImageUrl}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default ExtraBusinessModal;
