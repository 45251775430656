import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Checkbox,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
// import UserSpinModal from "./UserSpinModal";
import { GET_USER_SPIN_BY_USER_ID, GET_USER_SPINS } from "src/graphql/query";
// import { DELETE_USER_SPIN, UPDATE_USER_SPIN } from "src/graphql/mutations";
import DeleteModel from "src/components/Common/DeleteModel";
import Select from "react-select";
import UserSpinDetails from "./UserSpinDetails";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import { ClearIcon } from "@mui/x-date-pickers";
// import {
//   DELETE_ALL_USER_SPINS,
//   DELETE_MULTIPLE_USER_SPINS,
// } from "src/graphql/DeleteMutation";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import ActionMenu from "../Business/ActionMenu";
import { DELETE_USER_SPIN } from "src/graphql/mutations";

interface UserSpin {
  _id: string;
  userId: string;
  spinWheelSlug: string;
  deviceId: string;
  userSpinCount: number;
  lastWinDate: string;
  lastSpinDate: string;
  count: number;
  createdAt: string;
  updatedAt: string;
  lastWin: string;
  userFirstName: string;
  userLastName: string;
  prizeWon: {
    couponId: string;
    couponCode: string;
    winDate: string;
    status: string;
    expireDate: string;
  }[];
}

const UserSpins: React.FC = () => {
  const [list, setList] = useState<UserSpin[]>([]);
  const [spinCountUpdates, setSpinCountUpdates] = useState<{
    [key: string]: number | null;
  }>({});
  const [selectedUserSpin, setSelectedUserSpin] = useState<UserSpin | null>(
    null
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteUserSpinId, setDeleteUserSpinId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [isSpinCountEdit, setIsSpinCountEdit] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [deleteUserSpin] = useMutation(DELETE_USER_SPIN);
  // const [updateUserSpinBulk] = useMutation(UPDATE_USER_SPIN);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  // const [DeleteMultipleUserSpins] = useMutation(DELETE_MULTIPLE_USER_SPINS);
  // const [DeleteAllUserSpins] = useMutation(DELETE_ALL_USER_SPINS);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSuperAdmin = getRole() === "SuperAdmin";

  const COLUMNS = [
    {
      headerName: "User Details",
      field: "userId",
      flex: 1,
      renderCell: ({ row }: { row: UserSpin }) => (
        <div onClick={() => handleView(row)}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="start"
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="body2" color="textPrimary">
              {`${row?.userFirstName} ${row?.userLastName}`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {row?.userId}
            </Typography>
          </Box>
        </div>
      ),
    },
    { headerName: "Spin Wheel Slug", field: "spinWheelSlug", flex: 1 },
    { headerName: "Device ID", field: "deviceId", flex: 1 },
    {
      headerName: "Last Win Date",
      field: "lastWinDate",
      flex: 1,
      renderCell: ({ value }: { value: string }) => {
        if (!value) return <span>No date</span>; // Handle empty values

        const date = new Date(value);
        const formattedDate = date.toLocaleDateString("en-GB"); // DD/MM/YYYY
        let formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }).replace(/am|pm/i, (match) => match.toUpperCase()); // AM/PM in uppercase

        return <span>
          <Typography variant="body2" color="textPrimary">{formattedDate}</Typography>
          <Typography variant="body2" color="textSecondary">{formattedTime}</Typography>
        </span>;
      },
    },
    {
      headerName: "Last Spin Date",
      field: "lastSpinDate",
      flex: 1,
      renderCell: ({ row }: { row: UserSpin }) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
        >
          <Typography variant="body2" color="textPrimary">
            {new Date(row?.lastSpinDate).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {new Date(row?.lastSpinDate).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }).replace(/am|pm/i, (match) => match.toUpperCase())}
          </Typography>
        </Box>
      ),
    },

    {
      headerName: "Spin Count",
      field: "userSpinCount",
      flex: 1,
      renderCell: ({ row }: { row: UserSpin }) => {
        const [spinCountValue, setSpinCountValue] = useState(
          row.userSpinCount?.toString() || "0"
        );

        const handleSpinCountChange = (value: string) => {
          setSpinCountValue(value);
          setSpinCountUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };

        return isSpinCountEdit ? (
          <TextField
            value={spinCountValue}
            onChange={(e) => handleSpinCountChange(e.target.value)}
            placeholder="Enter Count"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {spinCountValue}
          </Typography>
        );
      },
    },
    // {
    //   headerName: "Actions",
    //   width: 100,
    //   renderCell: ({ row }: { row: UserSpin }) => (
    //     <Box>
    //       {/* {hasPermissionPage("UserSpins", "update") && ( */}
    //       <Tooltip title="Edit">
    //         <IconButton
    //           onClick={() => handleEdit(row)}
    //           aria-label="edit"
    //           style={{ marginRight: "8px", color: "#00C5B9" }}
    //         >
    //           <EditIcon />
    //         </IconButton>
    //       </Tooltip>
    //       {/* )} */}
    //       {/* {hasPermissionPage("UserSpins", "delete") && ( */}
    //       <Tooltip title="Delete">
    //         <IconButton
    //           onClick={() => {
    //             setDeleteUserSpinId(row._id);
    //             setOpenDeleteModel(true);
    //           }}
    //           aria-label="delete"
    //           style={{ color: "#00C5B9" }}
    //         >
    //           <DeleteIcon />
    //         </IconButton>
    //       </Tooltip>
    //       {/* )} */}
    //     </Box>
    //   ),
    // },
  ];

  const { loading, error, data, refetch } = useQuery(GET_USER_SPINS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
      search: searchTerm || undefined,
    },
  });

  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  const handleMultipleDelete = async () => {
    // try {
    //   const res = await DeleteMultipleUserSpins({
    //     variables: {
    //       _id: selectedRows,
    //     },
    //   });
    //   if (res?.data?.deleteMultipleUserSpins?.statusCode === 200) {
    //     toast.success(res?.data?.deleteMultipleUserSpins?.message);
    //     setSelectedRows((prevSelectedRows) =>
    //       prevSelectedRows?.filter((id) => !selectedRows?.includes(id))
    //     );
    //     refetch();
    //   } else {
    //     throw new Error(res?.data?.deleteMultipleUserSpins?.message);
    //   }
    // } catch (error: any) {
    //   console.error(error);
    //   toast.error(error?.message);
    // } finally {
    //   setOpenDeleteMultipleModel(false);
    // }
  };

  const handleAllDelete = async () => {
    // try {
    //   const res = await DeleteAllUserSpins();
    //   if (res?.data?.deleteAllUserSpins?.statusCode === 200) {
    //     toast.success(res?.data?.deleteAllUserSpins?.message);
    //     refetch();
    //   } else {
    //     throw new Error(res?.data?.deleteAllUserSpins?.message);
    //   }
    // } catch (error: any) {
    //   console.error(error);
    //   toast.error(error?.message);
    // } finally {
    //   setOpenDeleteAllModel(false);
    // }
  };

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data) {
      setList(data.getUserSpins.data);
      setTotal(data.getUserSpins.count);
    }
  }, [data]);

  // const [getUserSpin] = useLazyQuery(GET_USER_SPIN_BY_USER_ID);
  const [getUserSpinById, { loading: spinLoading }] = useLazyQuery(
    GET_USER_SPIN_BY_USER_ID
  );

  const handleView = async (rowData: any) => {
    try {
      const { data } = await getUserSpinById({
        variables: {
          id: rowData?.userId,
        },
      });
      setSelectedUserSpin(data?.getUserSpinByUserId?.data);
      setOpenViewModal(true);
    } catch (error) {
      console.error("Error fetching user spin:", error);
    }
  };

  const handleSpinCountUpdate = async () => {
    // const input = Object.keys(spinCountUpdates).map((id) => ({
    //   spinId: id,
    //   spinCount: spinCountUpdates[id],
    // }));
    // try {
    //   const response = await updateUserSpinBulk({
    //     variables: {
    //       input: {
    //         spins: input,
    //       },
    //     },
    //   });
    //   if (response?.data?.updateUserSpinBulk?.statusCode === 200) {
    //     toast.success(response?.data?.updateUserSpinBulk?.message);
    //     refetch();
    //     setSpinCountUpdates({});
    //   } else {
    //     throw new Error(response?.data?.updateUserSpinBulk?.message as any);
    //   }
    // } catch (error: any) {
    //   toast.error(error?.message);
    //   console.error("Error updating spin counts in bulk:", error?.message);
    // } finally {
    //   setIsSpinCountEdit(false);
    //   refetch();
    // }
  };

  const handleEdit = async (rowData: UserSpin) => {
    // try {
    //   const { data } = await getUserSpin({
    //     variables: {
    //       id: rowData._id,
    //     },
    //     fetchPolicy: "no-cache",
    //   });
    //   setSelectedUserSpin(data?.getUserSpinById?.data);
    //   setOpenModal(true);
    // } catch (error) {
    //   console.error("Error fetching user spin:", error);
    // }
  };

  const handleDelete = async () => {
    if (!deleteUserSpinId) return;
    try {
      await deleteUserSpin({
        variables: { id: deleteUserSpinId },
      });
      refetch();
      setOpenDeleteModel(false);
    } catch (error) {
      console.error("Error deleting user spin:", error);
    }
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteUserSpinId(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUserSpin(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;
  if (openViewModal) {
    return (
      <UserSpinDetails
        spinData={selectedUserSpin}
        onClose={() => setOpenViewModal(false)}
      />
    );
  }
  return (
    <Box p={2}>
      {!openModal && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            paddingRight={4}
          >
            <Typography variant="h4" style={{ color: "#00C5B9" }}>
              User Spins
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <Box width={200}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {/* {hasPermissionPage("UserSpins", "add") && ( */}
              {/* <Box>
                <CustomButton
                  onClick={() => setOpenModal(true)}
                  variant="contained"
                >
                  Create
                </CustomButton>
              </Box> */}
              {/* )} */}
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <CustomTable
              columns={COLUMNS}
              data={list}
              paginationModel={{
                page: pagination.page,
                pageSize: pagination.pageSize,
              }}
              totalCount={total}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </Box>
        </>
      )}

      {/* {openModal && (
        <UserSpinModal
          open={openModal}
          handleClose={handleCloseModal}
          userSpin={selectedUserSpin}
          refetchUserSpins={refetch}
        />
      )} */}

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this user spin?"
        />
      )}

      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } user spins?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all user spins?"
        />
      )}
    </Box>
  );
};

export default UserSpins;
