import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  CircularProgress,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import CustomTable from "src/components/CustomTable";
import { GET_COUPON_BY_ID } from "src/graphql/query";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

const CouponView: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [couponDetails, setCouponDetails] = React.useState<any>(null);
  const [coupons, setCoupons] = React.useState<any>(null);
  const [activeCoupons, setActiveCoupons] = React.useState<any>(null);
  const [usedCoupons, setUsedCoupons] = React.useState<any>(null);
  const [expiredCoupons, setExpiredCoupons] = React.useState<any>(null);
  const [allocateCoupons, setAllocateCoupons] = React.useState<any>(null);
  const [pendingCoupons, setPendingCoupons] = React.useState<any>(null);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [showMoreTerms, setShowMoreTerms] = useState(false);
  const [showMoreDescription, setShowMoreDescription] = useState(false);

  const truncateText = (text: any, maxLength = 100) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const id = window.location.pathname.split("/")[2];
  const { data, loading, error, refetch } = useQuery(GET_COUPON_BY_ID, {
    variables: { _id: id },
  });

  useEffect(() => {
    if (data?.getCoupon?.data) {
      if (data?.getCoupon?.data?.coupons) {
        const allCouponsData = data?.getCoupon?.data?.coupons.map(
          (item: any, index: number) => ({
            id: `${item.code || `code-${index}`}-${index}`,
            code: item?.code,
            couponStatus: item?.couponStatus,
            expireDate: item?.expireDate,
            userId: item?.userId,
            firstName: item?.firstName,
            lastName: item?.lastName,
          })
        );
        setCoupons(allCouponsData);
      }
      if (data?.getCoupon?.data?.activeCoupons) {
        const activeCouponsData = data?.getCoupon?.data?.activeCoupons.map(
          (item: any, index: number) => ({
            id: `${item || `code-${index}`}-${index}`,
            code: item,
          })
        );
        setActiveCoupons(activeCouponsData);
      }
      if (data?.getCoupon?.data?.usedCoupons) {
        const usedCouponsData = data?.getCoupon?.data?.usedCoupons.map(
          (item: any, index: number) => ({
            id: `${item || `code-${index}`}-${index}`,
            code: item,
          })
        );
        setUsedCoupons(usedCouponsData);
      }
      if (data?.getCoupon?.data?.expiredCoupons) {
        const expiredCouponsData = data?.getCoupon?.data?.expiredCoupons.map(
          (item: any, index: number) => ({
            id: `${item || `code-${index}`}-${index}`,
            code: item,
          })
        );
        setExpiredCoupons(expiredCouponsData);
      }
      // Add handling for pending coupons
      if (data?.getCoupon?.data?.pendingCoupons) {
        const pendingCouponsData = data?.getCoupon?.data?.pendingCoupons.map(
          (item: any, index: number) => ({
            id: `${item || `code-${index}`}-${index}`,
            code: item,
          })
        );
        setPendingCoupons(pendingCouponsData);
      } else {
        // If pendingCoupons is not provided from the API, you might want to filter from all coupons
        const pendingFromAll = data?.getCoupon?.data?.coupons
          ?.filter((item: any) => item.couponStatus === "PENDING")
          .map((item: any, index: number) => ({
            id: `${item.code || `code-${index}`}-${index}`,
            code: item.code,
          }));
        setPendingCoupons(pendingFromAll || []);
      }

      if (data?.getCoupon?.data?.allocateCoupons) {
        const allocateCouponsData = data?.getCoupon?.data?.allocateCoupons.map(
          (item: any, index: number) => ({
            id: `${item || `code-${index}`}-${index}`,
            code: item,
          })
        );
        setAllocateCoupons(allocateCouponsData);
      } else {
        // If allocateCoupons is not provided from the API, you might want to filter from all coupons
        const allocateFromAll = data?.getCoupon?.data?.coupons
          ?.filter((item: any) => item.couponStatus === "ALLOCATE")
          .map((item: any, index: number) => ({
            id: `${item.code || `code-${index}`}-${index}`,
            code: item.code,
          }));
        setAllocateCoupons(allocateFromAll || []);
      }

      setCouponDetails(data?.getCoupon?.data);
    }
  }, [data]);

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
    refetch({
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const ALL_COUPONS_COL = [
    {
      headerName: "Code",
      field: "code",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.code || "N/A"}</Typography>
      ),
    },
    {
      headerName: "Status",
      field: "couponStatus",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.couponStatus}</Typography>
      ),
    },
    {
      headerName: "Expired Date",
      field: "expireDate",
      flex: 1,
      renderCell: ({ value }: { value: string | null }) => {
        if (!value) {
          return <span>No Date</span>; // Show "No Date" if no expiry date is available
        }
        const formattedDate = new Date(value).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      headerName: "User Name",
      field: "firstName",
      flex: 1,
      renderCell: ({ row }: any) => {
        return <span>{`${row.firstName || "N/A"}  ${row.lastName || ""}`.trim()}</span>;
      },
    },
  ];
  const PENDING_COUPONS_COL = [
    {
      headerName: "Code",
      field: "code",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.code || "N/A"}</Typography>
      ),
    },
  ];
  const ALLOCATE_COUPONS_COL = [
    {
      headerName: "Code",
      field: "code",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.code || "N/A"}</Typography>
      ),
    },
  ];
  const ACTIVE_COUPONS_COL = [
    {
      headerName: "Code",
      field: "code",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.code || "N/A"}</Typography>
      ),
    },
  ];
  const USED_COUPONS_COL = [
    {
      headerName: "Code",
      field: "code",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.code || "N/A"}</Typography>
      ),
    },
  ];
  const EXPIRED_COUPONS_COL = [
    {
      headerName: "Code",
      field: "code",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row?.code || "N/A"}</Typography>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">Error: {error.message}</Typography>
      </Box>
    );
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" sx={{ color: "#00C5B9" }}>
          {couponDetails?.name || "No Name Available"}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          mt: 3,
          p: 3,
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "left",
          mx: "auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "#333",
            borderBottom: "2px solid #00C5B9",
            display: "inline-block",
            pb: 0.5,
            mb: 2,
          }}
        >
          Coupon Details
        </Typography>

        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Title : </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {couponDetails?.title || "N/A"}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Code : </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {couponDetails?.code || "N/A"}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Quantity : </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {couponDetails?.quantity || 0}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Status : </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {couponDetails?.status || "N/A"}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Slug : </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {couponDetails?.slug || "N/A"}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Type : </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {couponDetails?.type || "N/A"}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Business Name : </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {`${couponDetails?.businessName || "N/A"} (${couponDetails?.pinCodeDetails || "(N/A)"})`}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Event Name : </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {couponDetails?.eventName || "N/A"}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Terms and Conditions: </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {showMoreTerms ? couponDetails?.termsAndConditions || "N/A" : truncateText(couponDetails?.termsAndConditions || "N/A")}
          </span>
          {couponDetails?.termsAndConditions?.length > 100 && (
            <Button
              onClick={() => setShowMoreTerms(!showMoreTerms)}
              size="medium"
              style={{ color: "#000000", textTransform: "none" }}
            >
              {showMoreTerms ? "View Less" : "View More"}
            </Button>
          )}
        </Typography>

        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Description: </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {showMoreDescription ? couponDetails?.description || "N/A" : truncateText(couponDetails?.description || "N/A")}
          </span>
          {couponDetails?.description?.length > 100 && (
            <Button
              onClick={() => setShowMoreDescription(!showMoreDescription)}
              size="medium"
              style={{ color: "#000000", textTransform: "none" }}
            >
              {showMoreDescription ? "View Less" : "View More"}
            </Button>
          )}
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Pass Code : </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {couponDetails?.passCode || "N/A"}
          </span>
        </Typography>
        <Typography variant="body1">
          <strong style={{ color: "#333" }}>Expire Date : </strong>
          <span style={{ fontWeight: "bold", color: "#00C5B9" }}>
            {couponDetails?.expireDate || 0}
          </span>
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
          <Tab label="All Coupons" />
          <Tab label="Pending Coupons" />
          <Tab label="Allocate Coupons" />
          <Tab label="Active Coupons" />
          <Tab label="Used Coupons" />
          <Tab label="Expired Coupons" />
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <CustomTable
          columns={ALL_COUPONS_COL}
          data={coupons}
          getRowId={(row) => row.id}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={0}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <CustomTable
          columns={PENDING_COUPONS_COL}
          data={pendingCoupons}
          getRowId={(row) => row.id}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={0}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <CustomTable
          columns={ALLOCATE_COUPONS_COL}
          data={allocateCoupons}
          getRowId={(row) => row.id}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={0}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <CustomTable
          columns={ACTIVE_COUPONS_COL}
          data={activeCoupons}
          getRowId={(row) => row.id}
          paginationModel={{
            page: pagination.page - 1,  
            pageSize: pagination.pageSize,
          }}
          totalCount={0}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={4}>
        <CustomTable
          columns={USED_COUPONS_COL}
          data={usedCoupons}
          getRowId={(row) => row.id}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={0}
          onPageChange={handlePageChange} 
          onPageSizeChange={handlePageSizeChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={5}>
        <CustomTable
          columns={EXPIRED_COUPONS_COL}
          data={expiredCoupons}
          getRowId={(row) => row.id}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={0}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </TabPanel>
    </Box>
  );
};

export default CouponView;